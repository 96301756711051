import React from 'react'
import time from '../../../m_common/common/time'

export class Timer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            timestr: ''
        }
        this.timer = null;
    }
    settime() {
        let timestr = time.timestr(this.props.timer.get().sec)
        this.setState({ ...this.state, timestr })
    }
    starttime() { if (this.timer) return; this.timer = setInterval(() => { this.settime(); }, 250) }
    stoptime() { clearInterval(this.timer); this.timer = null }

    componentDidMount() {
        this.starttime();
    }
    componentWillUnmount() {
        this.stoptime()
    }
    render() {
        if (this.props.finished) this.stoptime();
        else this.starttime();
        return <span className='timer'>{this.state.timestr}</span>
    }
}
