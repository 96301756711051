import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { votes_get } from '../client_redux/content.actions'
import { votes_type_selector } from '../client_redux/content.selectors'

export function CheckVotes(props) {
    const { type } = props
    let votes = useSelector(store => votes_type_selector(store, type))
    let dispatch = useDispatch()

    if (!votes) {
        votes_get(type, dispatch)
    }

    return <></>
}