import React from 'react'
import { useParams } from 'react-router-dom'
import Refresh from '../../m_common/client_jsx/refresh'
import { IfHasPriv } from '../../m_auth/client/w-haspriv'
import { ShowTask } from './h_show_task'

export default function (props) {
    const { privileg, can_save, exit_path } = props
    const { task_code } = useParams()
    const priv = privileg === undefined ? "loggedin" : privileg
    const to_save = can_save === undefined ? false : can_save

    return <>
        <IfHasPriv priv={priv} login={true}>
            <Refresh code={task_code}>
                <ShowTask task_code={task_code} to_save={to_save} exit_path={exit_path}/>
            </Refresh>
        </IfHasPriv>
    </>
}

