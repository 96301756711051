import React from 'react'
import Chapter from './cs_content_chapter'

export default function Chapters(props) {
    let { parent, level, course_item, course_temp } = props
    let { group, item, groups } = course_temp.cache
    if (!group || !item) return null
    let g_course = group[parent]

    let AG = groups.filter(g => g_course.children.includes(g))

    return <>
        {
            AG.map(g =>
                <Chapter
                    key={g}
                    id={g}
                    level={level || 1}
                    course_item={course_item}
                    course_temp={course_temp}
                />)
        }
    </>
}