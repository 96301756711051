import behave from '../meta/h_behaviour'
import { htmlEntities } from '../../../m_common/common/text'

const errWord = (r, t) => { return `Can't use reserved word '${htmlEntities(r)}' in ${t}!` }

export const h_handle_css = (code, cw, iw, errFx) => {
    if (!code) return
    let t = cw.t || (cw.c + '.' + cw.e)
    let err = ''
    let code2 = code
    let defPrefix = iw.__$CssPre || ''

    const rws = ['<style>', '</style>', '<script>']
    if (behave.danger(cw, false)) {
        rws.forEach(r => { if (code.includes(r)) err = errWord(r, t) })
    }
    const addprefix = (_st) => {
        let _st2 = _st.trim()
        if (_st2 === "html") return "html"
        if (_st2 === "body") return prefix || "body"
        if (_st2.substr(0, 4) === "body") return _st2.replace("body", prefix || "body")
        return prefix + " " + _st2
    }

    let prefix = ((code2.match(/(\{\{.+?\}\})/g) || [])[0] || defPrefix).replace("{{", "").replace("}}", "").trim()
    code2 = code2.replace(/(\{\{.+?\}\})/g, '')

    code2 = code2.split('}')
        .map(c3 => c3.trim())
        .filter(c1 => { return c1.includes("{") || !c1 })
        .map(c2 => {
            if (!c2) return c2
            let c2s = c2.split("{")
            c2s[0] = c2s[0].split(',').map(addprefix).join(',')
            return c2s.join("{")
        })
        .join("}  " + String.fromCharCode(10))

    if (err) { errFx(err); return '' }
    return code2
}