import React from 'react'
import { useSelector } from 'react-redux'
import { auth_selector } from './store.auth.selectors'
import { SetText } from '../../m_common/client_jsx/io_helpers'
import Lan from '../../config/lan'

export default (props) => {
    const authdata = useSelector(auth_selector)

    const no = () => { }
    const dt = (msec) =>{
        return new Date(msec).toLocaleDateString()
    }

    return <>
        <div className={'row accentbox ' + props.class}>
            {props.nohead ? '' :
                <div className='row text-center'>
                    <h4>Felhasználói alapadatok</h4>
                    <div className='mtop10'></div>
                </div>
            }
            <div className='row m20_0'>
                <SetText class='medium' value={authdata.email} name='Email' disabled={true} onChange={no} />
                <SetText class='medium' value={authdata.verified ? 'igen' : 'nem'} name='Megerősítve' disabled={true} onChange={no} />
                <SetText class='medium' value={Lan['role_' + authdata.baserole]?.hu || '...'} name='Szerepkör' disabled={true} onChange={no} />
                <SetText class='medium' value={dt(authdata.regdate)} name='Regisztráció' disabled={true} onChange={no} />
            </div>
        </div>
    </>
}