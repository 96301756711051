export let pages_code = {
    terms: "4dnkh58roh30",
    data: "4dnkhtksmf70",
}

export let skip_logintoken = ["felhasznalo/ujjelszo", "felhasznalo/megerosites"]
export let cookies_must_allow = false

export let redirect_domains = {
    "codeman.hu": "www.codeman.hu",
}


export let min_privileges = {
    course: "", // "loggedin",
    challenge: "", // "loggedin"
}

export let site_messages={
    coursesX: {
        text:"2021. január 2-3. napokon minden kurzus ingyenes",
        style:{color:"red",fontSize:'25px',lineHeight:'30px'}
    }
}



export let demos_course = '4e7dqdela9c0'
export let demos = [
    { id: 94, name: 'HTML - Sitebuild', desc: '', image: '/public/course/d_ui1.jpg', color: '#24969a' },
    { id: 95, name: 'CSS', desc: '', image: '/public/course/d_css5.jpg', color: '#3b2d73' },
    { id: 67, name: 'Javascript', desc: '', image: '/public/course/d_js2.jpg', color: '#dcc610' },
    { id: 68, name: 'React', desc: '', image: '/public/course/d_react1.jpg', color: '#444343' },
]


export let courses_page_config = {
    settings: {},
    groups: [

        {
            name: 'Kezdő kurzusok',
            desc: 'Az alábbi kurzusok segítségével megismerkedhetsz a webfejlesztés és programozás alapjaival.',
            courses: [
                '4dmkqet5fek0', // HTML
                "4dri4bip1gn0", "4efvoui0urd0", // CSS 1, CSS 2
                "4earafanemp0", // Javascript bevezetés 1
                "4f09pbmruqe0", // Javascript bevezetés 2
                "4eu8s713t8f0", // Javascript 2 szám, szöveg
                "4ejm0v0rdln0", // react
                "4f193pf0nur0", // Javascript kihívások 1
                "4f8d1bgbvab0", // Irány a Mars
                "4f5eal43j6q0", // React 2 - todo
                "4ejn0iiaeb70", // vue.js - todo
                "4eui4m1dnbm0", // python
            ]
        },
        /*
        {
            name: 'Tervezett kurzusok',
            desc: 'Szavazz a fejlesztés vagy tervezés alatt lévő kurzusokra!',
            courses: [
                //"4earafanemp0", // JAVASCIPT
                "4ejltui9nrj0", // Bemutatkozó weboldal
                "4ejm34tto4c0", // bootstrap
                "4dri4jk4e400", // HTML5
                "4ejm065ksf60", // jquery
                
                "4ejn0iiaeb70", // vue.js
                "4eui4m1dnbm0", // python
                "4ejmv71led10", // interview kérdések - junior
            ]
        }
        */
    ]
}

export let challenge_groups = {
    aktualis: { code: 'actual', group_id: 19, group_title: "Aktuális kihívások" },
    lejart: { code: 'expired', group_id: 45, group_title: "Korábbi kihívások megoldásokkal" },
}