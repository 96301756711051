import messages from '../../config/messages'
import { message_add_more_action } from "./store.messages.actions"

export function show_message(text, dispatch) { resToMessage({ ok: 0, data: {}, msg: [text] }, dispatch) }

export function resToMessage(res, dispatch) {
    let { msg, ok } = res
    let sec = 10

    let t0 = ok ? "info" : "error"
    let arr = []

    if (typeof msg === 'string') msg = [msg]
    if (!Array.isArray(msg)) return

    msg.forEach(_m => {
        let time = Date.now()
        let message = { type: "", text: "", exp: 0, add: 0 }
        if (typeof _m === 'string') {
            let t1 = _m.split("_")[0]
            if (!['success', 'error', 'info', 'warning'].includes(t1)) t1 = undefined
            if (messages[_m]) {
                message = {
                    type: messages[_m].type || t1 || t0,
                    text: messages[_m]?.hu,
                    exp: time + sec * 1000,
                    add: 1
                }
            } else {
                message = {
                    type: t1 || t0,
                    text: _m,
                    exp: time + 10 * 1000,
                    add: 0
                }
            }
        }

        if (message.add) arr.push(message)

    })

    //if (arr.length) setTimeout(() => {
    dispatch(message_add_more_action(arr))
    //}, 1500)

}