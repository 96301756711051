import React from 'react'
import { UserBillingAddress } from './w_address_billing'

export default function () {

    return <>
        <div className='noselect'>
            <div className='toload2 row m50_0'>
                <Innerpage />
            </div>
        </div>
    </>
}


function Innerpage(props) {
    return <>
        <div className='row text-center'>
            <h4>Számlázási cím</h4>
        </div>
        <div className='row m10_0'>
            <div className='mw700 '>
                <UserBillingAddress />
            </div>
        </div>
    </>
}

