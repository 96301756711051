import { arr_shuffle } from './fx2'

export const e = (j) => {
    let { ee, mm } = getDict();
    let st = JSON.stringify(j)
    let st2 = []
    for (let i = 0; i < st.length; i++) {
        if (!ee[st[i]]) {
            //console.log('cant encode: ', st[i], st[i].charCodeAt(0))
            ee[st[i]] = st[i]
        }
        st2[i] = ee[st[i]]
    }
    return {
        mm: mm,
        ss: st2.join('')
    }
}

export const d = (bb) => {
    let { dd } = getDict(bb.mm);
    let st = bb.ss
    let st2 = []
    for (let i = 0; i < st.length; i++) {
        if (!dd[st[i]]) {
            //console.log('cant decode: ', st[i], st[i].charCodeAt(0))
            dd[st[i]] = st[i]
        }
        st2[i] = dd[st[i]]
    }
    let s = st2.join('')
    return JSON.parse(s)
}


function getDict(mm_ = undefined) {
    const { ee, dd, mm } = CreateDict(mm_);
    return { ee, dd, mm }
}


// \  "

const chs = "íyxcvbnm,.-asdfghjkléáűqwertzuiopőú0123456789öüóÍYXCVBNM?:_ASDFGHJKLÉÁŰQWERTZUIOPŐÚ§'+!%/=()ÖÜÓ#&@{}<;>*äđĐ[]łŁ$ß|Ä€÷×~ˇ^˘°˛`˙´˝¨¸"

function CreateDict(mm_ = undefined) {
    let aa = chs.split('');
    aa.push('"');
    aa.push('\\')
    aa.push(String.fromCharCode(13))
    aa.push(String.fromCharCode(32))
    aa.push(String.fromCharCode(9))


    let bb = [];
    let mm = null;
    if (mm_) {
        bb = mm_.split('')
        mm = mm_
    } else {
        bb = arr_shuffle([...aa])
        mm = bb.join('')
    }
    let ee = {}
    let dd = {}
    for (let i = 0; i < aa.length; i++) {
        ee[aa[i]] = bb[i]
        dd[bb[i]] = aa[i]
    }

    return { ee, dd, mm }
}