import React, { useState } from 'react'


export default function Refresh(props) {
    const { code } = props
    const [state, setS] = useState({ last_code: code, refresh: true })
    const onRefresh = () => { setS({ last_code: code, refresh: false }) }
    const toRefresh = () => { setS({ last_code: code, refresh: true }) }
    if (state.last_code !== code) toRefresh()
    return state.refresh
        ? <SubRefresh onRefresh={onRefresh} />
        : props.children
}

function SubRefresh(props) {
    setTimeout(() => { props.onRefresh(1) }, 1)
    return null
}