import React from "react"
import { UserIcon } from "../../m_auth/client/w_usericon"

export default (props) => {
    return <>
        <li className='right'>
            <UserIcon page={props.page} />
        </li>
    </>
}

