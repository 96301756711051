import { useEffect } from "react";
import { useDispatch } from 'react-redux'
import { useLocation } from "react-router-dom";
import { GoogleAnalyticsPageView } from '../client/3rd.google.analytics'
import { new_page } from '../../m_common/client_redux/common.actions'

export default function NewPage({ title }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch()

  if (title) document.title = title

  useEffect(() => {
    window.scrollTo(0, 0);
    GoogleAnalyticsPageView();
    setTimeout(() => {
      dispatch(new_page())
    }, (10));

  }, [pathname, dispatch]);

  return null;
}