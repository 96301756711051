import { GetData, PostData } from './http'
import { api_url /*, api2_url*/ } from '../../config/api.config'

export async function ApiGet(path, query, jwt) {
    return new Promise((resolve, reject) => {
        let url = api_url(window.location.hostname)
        let mid = path.substr(0, 1) === '/' ? '' : '/'
        let qq = ''
        for (let i in query) qq += "&" + i + "=" + query[i].toString().replace(/&/g, '')
        qq = '?' + qq.substr(1, 9999)
        let url2 = url + mid + path + (qq.length > 1 ? qq : "");
        GetData(url2, jwt).then(back => {
            let json = {}
            try { json = JSON.parse(back) } catch (e) { }
            resolve(json)
        })
    })
}


export async function ApiPost(todo, input, jwt) {
    return new Promise((resolve, reject) => {
        let url = api_url(window.location.hostname)
        PostData(url, { todo, input }, jwt).then(back => {
            let json = {}
            try { json = JSON.parse(back) } catch (e) { }
            resolve(json)
        })
    })
}


/*
export async function ApiPost2(todo, input, jwt) {
    return new Promise((resolve, reject) => {
        let url = api2_url(window.location.hostname)
        PostData(url, { todo, input }, jwt).then(back => {
            let json = {}
            try { json = JSON.parse(back) } catch (e) { }
            resolve(json)
        })
    })
}
*/