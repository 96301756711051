import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import { logout_action } from "./pure.auth.actions"
import ModalLogout from './wm_logout'

export function LogoutFrame(props) {
    const dispatch = useDispatch()
    const [modalOn, setM] = useState(false)

    const onLogoutStart = () => {
        setM(true)
    }

    const onAction = (act) => {
        if (act==='no' || act==='cancel') setM(false)
        if (act==='ok' || act==='yes') {
            logout_action(dispatch)
            setM(false)
        }
    }

    return <>
        <div onClick={onLogoutStart}>
            {props.children}
        </div>
        <ModalLogout on={modalOn} onAction={onAction} />
    </>
}


export function LogoutText(props) {
    return <LogoutFrame>
        <div style={{
            textAlign: "center",
            top: "0px",
            right: "0px",
            position: "absolute",
            lineHeight: "50px",
            padding: "0px 20px",
            cursor: "pointer"
        }}
            className='op7 hoverop1 cl_accent'
            onClick={() => { }}
        >
            <span className='fas fa-sign-out-alt mright8'></span>
            <span className='mright10x'>Kijelentkezés</span>
        </div>
    </LogoutFrame>
}