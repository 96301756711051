import React from 'react'
import PriceItem from './w_priceitem'
import "./w_pricetable.scss"
import { packages } from '../../config/shop.cfg'


export default function PriceTable(props) {

    return <>
        <div className='row'>
            <div className='mw700_350'>
                <div className='pricetable'>
                    {Object.keys(packages).map(p =>
                        <PriceItem
                            key={p}
                            item={packages[p]}
                            buttontext={props.buttontext}
                            onClick={() => { props.onClickItem(p) }}
                        />
                    )}
                </div>
            </div>
        </div>
    </>


}

