import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { content_item_overview, content_solution_course } from '../client_redux/content.selectors'
import Lan from '../../config/lan'
import { CourseOverviewIn } from './h_course_overview'


export function CourseOverview(props) {
    let { code } = props
    const item = useSelector(store => content_item_overview(store, code))
    const solution = useSelector(store => content_solution_course(store, item?.id))

    return !item
        ? null
        : !item.active
            ? null
            : <InnerPage
                code={code}
                item={item}
                solution={solution}
            />
}


function InnerPage(props) {
    const { code, item, solution } = props

    let mode = item.availability?.mode
    let allowed = (mode === 'available');
    let rate = 0;
    if (solution) try { rate = (solution.ok_nr / solution.task_nr) } catch (e) { }

    return <div className={'site_course_overview mode_' + mode}>
        {
            allowed ?
                <Link to={'/kurzus/' + code}>
                    <InnerPage2 item={item} rate={rate} />
                </Link>
                : <>
                    <InnerPage2 to_vote={!allowed} item={item} rate={rate} />
                </>
        }

    </div>

}



function InnerPage2(props) {
    let { to_vote, item, rate } = props
    let { id, course, overview, availability } = item
    let { name, desc, image, color } = overview
    let { mode } = availability
    let { other } = course

    return <div className='row c_in nooverflow'>
        {to_vote
            ? <div className='row c_mode_msg' style={{ color: "white", backgroundColor: Lan['mode_' + mode]?.color }}>
                <span className={'c_mode_msg_icon ' + Lan['mode_' + mode]?.icon}></span>
                <span>{Lan['mode_' + mode]?.hu}</span>
            </div>
            : other.ribbon
                ? <div className='row c_mode_msg' style={{ color: "white", backgroundColor: Lan['ribbon_' + other.ribbon]?.color }}>
                    <span>{Lan['ribbon_' + other.ribbon]?.hu}</span>
                </div>
                : <></>
        }
        <CourseOverviewIn id={id} name={name} desc={desc} image={image} color={color} rate={rate} to_vote={to_vote} />
    </div>
}