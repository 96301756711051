import { google_analytics_cfn, allowed_hosts } from '../../config/google.analytics.cnf'
import Cookie from './cookie'


export let GoogleAnalyticsInit = () => {

    let disable = false

    let hostname = window.location.hostname
    if (!allowed_hosts.includes(hostname)) disable = true
    if (window.location.search.includes('disable_ga')) {
        disable = true;
        Cookie.set('ga_no_cm', 1, 9999)
    }
    if (Cookie.get('ga_no_cm')) disable = true

    window.gtag_disabled = disable
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () { window.dataLayer.push(arguments); }
    window.gtag('js', new Date());
    window.gtag('config', google_analytics_cfn.id, { 'send_page_view': false });

}

export let GoogleAnalyticsPageView = () => {
    if (window.gtag_disabled) return


    let page_location = window.location.pathname
    if (page_location === window.gtag_last_location) return
    window.gtag_last_location = page_location

    window.gtag('event', 'page_view', { page_location })
}





/*

    window.gtag('js', new Date());
    window.gtag('config', google_analytics_cfn.id, { 'send_page_view': false });

    window.gtag('event', 'page_view', {
        page_title: '<Page Title>',         //document.title
        page_location: '<Page Location>',   //location.href
        page_path: '<Page Path>',           //location.pathname
        send_to: '<GA_MEASUREMENT_ID>',     //true
      })

      gtag('event', 'login', {'method': 'Google'});

*/