import React from 'react'
import { useDispatch } from 'react-redux'
import { menu_toggle } from '../../m_common/client_redux/common.actions'

export function MenuToggleButton(props) {
    const dispatch = useDispatch()

    const toggle = () => { dispatch(menu_toggle()) }

    return <>
        <div className='' onClick={toggle} >
            <span className='fas fa-bars right onmobile pointer' style={{ fontSize: '30px', lineHeight: "50px", margin: "0px 10px" }}></span>
        </div>
    </>
}

