import React from 'react'


let blocks = [

    { icon: 'far fa-lightbulb', text: 'A tananyagok, leírások szövegesen foglalják össze az aktuálisan tárgyalt témát, problémát.' },
    { icon: 'fas fa-laptop-code', text: 'Mindig az oldalba integrált fejlesztői eszközt fogod használni. Nincs szükség semmilyen telepítésre.' },
    { icon: 'fas fa-cubes', text: 'Sosem kell elhagynod az oldalt! Itt megtalálható minden, ami a fejlődéshez kell.' },
    { icon: 'fas fa-piggy-bank', text: 'Az oldalon minden anyagot és feladatot elérhetsz egyetlen előfizetési csomagon belül.' },

]


let rowstyle = {
    backgroundColor: "rgb(31 36 48 / 90%)", //"rgb(218 43 43 / 88%)",// "rgb(31 36 48 / 90%)", //"rgb(67 52 135 / 92%)", //;"#046a90",//"#46358c",
    color: "white",
    zIndex: "10"
}
let imgsrc = '/public/background/bg003.jpg'

let imgstyle = {
    width: '100%',
    minHeight: '100%'
}

export default function MainF1(props) {
    return <>
        <div className='row'>
            <div className='absolute tl0 wh100 nooverflow'>
                <img src={imgsrc} style={imgstyle} alt='' />
            </div>

            <div className='pagerow p50_0' style={rowstyle}>
                <div className='row'>

                    <div className='row text-center p0_10 '>
                        <h3>Módszertan</h3>
                    </div>

                    <div className='row text-center p0_10 mtop10 '>
                        <div className='text-center mw900'>
                            <h6>
                                Ha jó fejlesztő szeretnél lenni akkor folyamatosan tanulni kell, problémákat megoldani és rengeteg kódot írni.
                            <br />Ebben segítünk a kurzusokba foglalt tananyagokkal és a helyben elvégzendő kódolási feladatokkal.
                            </h6>
                        </div>
                    </div>

                    <div className='row mtop20'>
                        <div className='mw1000_500_250'>
                            <Blocks />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>
}


function Blocks() {
    return blocks.map((block, i) => <Block key={i} block={block} />)
}

function Block({ block }) {
    return <>
        <div style={{ width: "250px", fontSize: "16px", lineHeight: "1.5em" }} className='p20 left'>
            <div className='row'>
                <div className='row text-center'>
                    <span className={block.icon} style={{ fontSize: "40px" }}></span>
                </div>
                <div className='row mtop20 text-center'>
                    {block.text}
                </div>
            </div>
        </div>
    </>
}