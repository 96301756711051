export const codebox_extensions = {
    html: 'text/html',
    css: 'text/css',
    js: 'javascript',
    jsx: "text/jsx",    // text/jsx, text/typescript-jsx.
    //jsv: "text/x-vue",
    py: "python",
}

export const codebox_styles=["s1x1","s05x1","s05x2","s15x1","s2x1","s1x2","s15x2","s2x2","full","sany"]

export const codebox_behaviour = {
    a: { name: 'normal', a_icon: 'far fa-keyboard' },
    r: { name: 'react jsx', a_icon: 'far fa-file-code' },
    h: { name: 'hidden', a_icon: 'far fa-eye-slash' },
    l: { name: 'locked', a_icon: 'fas fa-lock' },
    n: { name: 'no-run', a_icon: 'fas fa-times' },
    x: { name: 'have solution', a_icon: 'fas fa-exchange-alt' },
    s: { name: 'is solution', a_icon: 'fas fa-crosshairs' },
}


export const codebox_attributes={
    b:{name:'behaviour'},
    t:{name:'text'},
    e:{name:'extension'},
    s:{name:'style'},
    l:{name:'class'},
    v:{name:'value'},
}
