
const default_user_state = () => {
    return {
        data: {},
        address: null,
        pendin: false,
        error: false,
    }
}

export function user_reducer(state = default_user_state(), action) {
    let newstate = { ...state }

    switch (action.type) {
        case 'USER_SENT': newstate.pendin = true; newstate.error = false; break;
        case 'USER_ERROR': newstate.pendin = false; newstate.error = true; break;
        case 'USER_ADDRESS': doit(newstate, action); break;
        case 'LOGOUT': newstate = default_user_state(); break;
        default: break;
    }

    return newstate
}


function doit(newstate, action) {
    const { user_address, del_address } = action.payload

    if (user_address !== undefined) newstate.address = user_address
    if (del_address) newstate.address = null

    newstate.pendin = false;
    newstate.error = false;
    return newstate
}