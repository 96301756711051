import React from 'react'
import ReactDOM from 'react-dom';
//import ModalPortal from './modal_portal'
import "./modal.scss"

const modalRoot = document.getElementById('modal-root');

export default function Modal(props) {
    const action = (what) => {
        if (props.onAction) props.onAction(what)
    }


    return <>
        <ModalPortal>
            <div className='modal'>
                <div className='modal-background' onClick={() => { action('cancel') }}></div>
                <div className='modal-box toload2'>

                    {!props.title ? "" :
                        <div className='modal-head'>
                            {props.title}
                        </div>
                    }

                    <div className='modal-body'>
                        {props.children}

                        {
                            !props.button ? null : <div className='modal-buttons text-center mtop20'>

                                {!props.button.ok ? null :
                                    <button className='button bt_accent m5 med' onClick={() => { action('ok') }}>Ok</button>
                                }

                                {!props.button.yes ? null :
                                    <button className='button bt_accent m5 med' onClick={() => { action('yes') }}>Igen</button>
                                }

                                {!props.button.no ? null :
                                    <button className='button bt_grey m5 med' onClick={() => { action('no') }}>Nem</button>
                                }

                                {!props.button.cancel ? null :
                                    <button className='button bt_grey m5 med' onClick={() => { action('cancel') }}>Mégsem</button>
                                }

                            </div>
                        }

                    </div>
                </div>
            </div>
        </ModalPortal>
    </>
}




class ModalPortal extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el
        );
    }

}