import { ApiPost as ApiPost_H, /*ApiPost2 as ApiPost2_H,*/  ApiGet as ApiGet_H } from '../../m_common/client/api_http'
import { ApiPost as ApiPost_A } from '../../m_common/client/api_auth'
import { resToMessage } from "../../m_common/client_messages/pure.messages.actions"
import Tokens from "../../m_auth/client/tokens"
import { logout_action } from "../../m_auth/client/pure.auth.actions"

export const ApiPost = (todo, input, dispatch, cbSuccess = () => { }, cbErr = () => { }) => {
    return CommonSend(ApiPost_H, todo, input, dispatch, cbSuccess, cbErr)
}
/*
export const ApiPost2 = (todo, input, dispatch, cbSuccess = () => { }, cbErr = () => { }) => {
    return CommonSend(ApiPost2_H, todo, input, dispatch, cbSuccess, cbErr)
}
*/
export const ApiGet = (todo, input, dispatch, cbSuccess = () => { }, cbErr = () => { }) => {
    return CommonSend(ApiGet_H, todo, input, dispatch, cbSuccess, cbErr)
}

export const ApiAuth = (todo, input, dispatch, cbSuccess = () => { }, cbErr = () => { }) => {
    return CommonSend(ApiPost_A, todo, input, dispatch, cbSuccess, cbErr)
}

const CommonSend = (sendFx, todo, input, dispatch, cbSuccess, cbErr, trial = 2) => {

    let jwt = Tokens.get('jwt')

    return new Promise((resolve, reject) => {
        sendFx(todo, input, jwt).then(x => {
            //console.log(x.todo, x)

            resToMessage(x, dispatch)
            if (x.jwt) Tokens.set('jwt', x.jwt, (x.jwt_days || 7) + 1)
            if (x.jwt_err) {
                logout_action(dispatch, true)
                return
            }

            if (x.ok) {
                cbSuccess(x, dispatch)
                resolve(x)
            } else {
                cbErr(dispatch)
                reject(x.msg ? x.msg[0] : 'res.ok=0')
            }

        })
            .catch(e => {
                cbErr(dispatch)
                reject(e)
            })
    })

}