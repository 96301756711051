import { PostData } from './http'
import { api_auth_url } from '../../config/auth.api.config'

export async function ApiPost(todo, input, __placeholder1 = null, __placeholder2 = null) {
    return new Promise((resolve, reject) => {
        let url = api_auth_url(window.location.hostname)
        PostData(url, { tenant: 'CM001_prod', todo, input }).then(back => {
            let res = JSON.parse(back)
            resolve(res)
        })
    })
}





