import React from 'react'
import ItemContent from '../../m_item/client_item/item.content'
import { pages_code } from '../../config/client.config'
import NewPage from '../../m_common/client_jsx/new_page'


export default (props) => {
    return <>
        <NewPage title='ÁSZF - Codeman.hu' />
        <div className='row fullheight page_light font-main noselect'>
            <div className='row site_course_group m50_0 toload2'>
                <div className='row text-center'>
                    <h4 className='ptitle'>Általános szerződési feltételek</h4>
                </div>

                <div className='row row desc m50_0'>
                    <ItemContent code={pages_code.terms} purpose={'task_article_pure'} />
                </div>
            </div>
        </div>
    </>
}

