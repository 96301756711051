import React from 'react'
import { TaskBar } from './p_bar'
import { Buttons } from './p_buttons'
import { Code } from './p_code'
import { Quiz } from './p_quiz'
import { Article, EX, Con } from './p_content'
import { ReadyModal } from './p_ready'
import { F2showAny } from './u-code'
import './a-layout.scss'


export function ItemLayout({
    action, status, item, solution, next_button,
    bar, show,
}) {

    const technology = item?.task?.code?.technology
    const F2noInput = !F2showAny(item?.task?.code?.cw)
    const noText = !(show.article || show.exercise || show.quiz || show.conclusion)
    const noTech = !show.code
    const F2buttonUp = !noTech && !noText && !F2noInput
    const withBar = !!bar

    const classes = (json) => {
        return Object.keys(json).filter(k => json[k]).join(' ')
    }
    const onAction = action
    const buttons = <Buttons {...{ item, status, solution, next_button, onAction }} />

    return <div id="layout1" className={'technology_' + technology + " " + classes({ withBar, F2buttonUp, noText, noTech })}>
        {!withBar
            ? null
            : <div id='layout1_bar'>
                <TaskBar {...{ status, item, solution, buttons }} />
            </div>
        }

        <div id='layout1_main' >
            <div className={'layout1_col layout1_text'}>
                <div className='row'>
                    {!show.article ? '' : <Article item={item} />}
                </div>
                <div className='row'>
                    {!show.exercise ? '' : <EX item={item} />}
                    {!show.quiz ? '' :
                        <Quiz
                            item={item}
                            nohead={true}
                            onAction={action}
                            finished={status.finished}
                        />}
                </div>
                <div className='row' id='item_conclusion'>
                    {!show.conclusion || !status.finished ? '' : <Con item={item} />}
                </div>
                {!(status.finished && !status.showsolution && next_button) ? null :
                    <ReadyModal action={action} />
                }
                <div className='row' id='task_page_end'></div>
            </div>


            {!show.code
                ? null
                : <div className={'layout1_col layout1_code'}>
                    <Code
                        item={item}
                        id={item.id}
                        onAction={action}
                        finished={status.finished}
                        solution={status.showsolution}
                        reset={status.reset}
                    />
                </div>
            }
        </div>
    </div>
}


