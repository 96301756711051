import { t_frame2_exclude } from "./t_f2_exclude"

const toShow = (cws, solution, ordered = false) => {
    let list = Object.keys(cws).filter(c => see2(cws[c], solution)) || []
    if (ordered) {
        try {
            list.sort((a, b) => (decide('edit', cws[a], false) > decide('edit', cws[b], false)) * -1)
        } catch (e) { }
    }
    return list
}

const toEdit = (cws) => {
    return Object.keys(cws).filter(c => decide('edit', cws[c], false)) || []
}

const showAny = (cws, solution, frame2 = false) => {
    return toShow(cws, solution).filter(c => (!frame2 || !t_frame2_exclude.includes(c))).length
}

const see2 = (cw, solution) => {
    const visible = !cw.l.split('').includes('hide')
    let base = decide('see', cw, solution)
    return !!base && visible
}


const decide = (what, cw, solution) => {
    return logic[cw.b][solution * 1][place[what]]
}

const place = {
    see: 0, run: 1, edit: 2
}

const logic = {
    a: [[1, 1, 1], [1, 1, 0]],
    h: [[0, 1, 0], [0, 1, 0]],
    l: [[1, 1, 0], [1, 1, 0]],
    n: [[1, 0, 0], [1, 0, 0]],
    //s: [[0, 0, 0], [1, 1, 0]],
    //x: [[1, 1, 1], [0, 0, 0]],
    s: [[0, 0, 0], [0, 0, 0]],
    x: [[1, 1, 1], [1, 1, 0]],
    r: [[1, 0, 0], [1, 0, 0]],
}

/*
        a - normal
        h - hidden
        l - locked
        n - never run
        s - solution
        x - xsolution
        r - react code
*/

let functions = {
    toShow,
    toEdit,
    showAny,
    see2: see2,
    see: (cw, solution) => { return decide('see', cw, solution) },
    edit: (cw, solution) => { return decide('edit', cw, solution) },
    run: (cw, solution) => { return decide('run', cw, solution) },
    danger: (cw, solution) => { return (decide('run', cw, solution) * decide('edit', cw, solution)) },
}

export default functions