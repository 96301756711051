import { test_regex } from './validations_regex'
import { test_functions } from './validations_fx'

export default function () {

	var range = { integer30000: [1, 30000] }

	this.is = (what, value) => {
		if (what) {
			if (test_regex[what]) return test_regex[what].test(value);
			else if (test_functions[what]) return test_functions[what](value);
		}
		return true;
	}

	this.isbetween = (what, value) => {
		if (range[what] === undefined) return true;
		return (Number(value) >= range[what][0] && Number(value) <= range[what][1])
	}

	this.checkJson = (json, value) => {
		let ok = true;
		let validations = json.validations
		if (json.vs) validations = json.vs
		for (let i in validations) {
			let v = validations[i];
			ok = ok && this.is(v, value);
			ok = ok && this.isbetween(v, value);
		}
		return ok;
	}
	this.checkFile = (file, options) => {
		let ok = true;
		let msg = [];
		try {
			if (file.size >= options.maxfilesize) { ok = false; msg.push("msg_file_toobig") }
			if (options.type === "img" && !["image/jpeg", "image/jpg", "image/png", "image/gif"].includes(file.type)) { ok = false; msg.push("picture_wrongformat") }
			return { ok: ok, msg: msg };
		} catch (e) {
			return { ok: false, msg: "something_wrong" };
		}
	}
	this.checkSame = (obj1, obj2) => { return JSON.stringify(obj1) === JSON.stringify(obj2); }

	this.totext = (str) => {
		str = str.replace(test_regex.change_quote, "˝");
		str = str.replace(test_regex.change_squote, "`");
		str = str.replace(test_regex.change_space, " ");
		str = str.replace(/;+/g, ",");
		str = str.replace(test_regex.change_text, "");
		str = str.replace(/<script>/, "");
		str = str.replace(/<\/script>/, "");
		return str;
	}

}