export default (item, _roles = [], privileges = {}, parent_item = {}) => {
    let roles = _roles.map(r => r.role)

    let { mode, depend_pack, depend_role, depend_priv } = item.availability
    let can_open = false

    if (mode === 'available') can_open = true
    else if (mode === 'pack') { if (depend_pack === 'xxxxxxx') can_open = true }
    else if (mode === 'role') { if (roles.includes(depend_role)) can_open = true }
    else if (mode === 'priv') { if (privileges[depend_priv]) can_open = true }

    try {
        if (parent_item.course.other.ribbon === 'free') can_open = true
    } catch (e) { }

    return can_open
}  