import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { content_item_bycode } from '../client_redux/content.selectors'
import { item_get_bycode } from '../client_redux/content.actions'
import TaskArticle from '../client_task/item.article/item.task_article'
import TaskArticlePure from '../client_task/item.article/item.task_article_pure'
import Loading from '../../m_common/client_jsx/loading_page'

export default function (props) {
    const {code,purpose,bg} = props

    const item = useSelector(store => content_item_bycode(store, code))
    const dispatch = useDispatch()

    if (!item) item_get_bycode(code, dispatch)

    if (!item) return <Loading />
    else if (purpose === 'task_article') return <TaskArticle item={item} bg={bg} />
    else if (purpose === 'task_article_pure') return <TaskArticlePure item={item} bg={bg} />
    else return null

}


