import React from "react";
import Editor from '../client_editor/editor'
import { CreateWS } from "../../m_common/client/ws";
import { d } from "../../m_common/common/encode"
import { ApiPost } from '../../m_common/client/api_common'

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = { inited: false, data: null };
        this.windowid = null;
        this.ws = null
    }

    render() {
        return !this.state.inited ? '' :
            <Editor
                admin={this.props.admin}
                solution={this.props.solution || false}
                finished={this.props.finished || false}
                reset={this.props.reset}
                data={this.state.data}
                onAction={this.onAction.bind(this)}
                onChange={this.onCodeChange.bind(this)}
            />;
    }

    componentDidMount() {
        if (this.props.code)
            this.server_send_http("code_task_set", { code: this.props.code })
        else if (this.props.id)
            this.server_send_http("code_task_get", { id: this.props.id });
    }

    componentWillUnmount() {
        if (this.sub) this.sub.unsubscribe();
        if (this.ws) this.ws.kill();
        this.ws = null;
    }


    onAction(act) {
        if (this.props.onAction)
            this.props.onAction(act)
    }

    onCodeChange(cw) {
        if (this.props.onChange)
            if (this.props.code)
                this.props.onChange({ ...this.props.code, cw })
    }

    server_send_ws(todo, input) {
        if (this.ws) this.ws.send(...arguments);
        else
            CreateWS((ws) => {
                this.ws = ws;
                this.sub = this.ws.obs.subscribe(this.server_back.bind(this));
                this.ws.send(...arguments);
            });
    }

    server_send_http(todo, input) {
        ApiPost(todo, input, this.props.dispatch)
            .then(x => {
                if (x.ok) this.server_back.bind(this)({ ...x.data, req: todo })
            })
            .catch(e => { })
    }

    server_back(data) {
        if (!data) return;
        let data2 = data
        if (data.mm && data.ss)
            data2 = d(data)

        switch (data.req) {
            case "code_task_set":
            case "code_task_get":
                this.setState({ inited: true, data: data2 })
                this.windowid = data2.windowid;
                break;
            default: return;
        }
    }


}
