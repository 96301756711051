import actions from './store.auth.creator'

var default_auth_state = {
    loggedin: false,
    roles: [],
    privileges: {},
    auth: {},
    login_attempted: false,
    pendin: false,
    error: false,
}

export function auth_reducer(state = default_auth_state, action) {
    let newstate
    switch (action.type) {
        case actions.AUTH_PENDING: return { ...state, pending: true, error: false };
        case actions.AUTH_ERR: return { ...state, peding: false, error: true };
        case actions.AUTH_RES:
            newstate = { ...state, login_attempted: true, pending: false, error: false }
            if (action.payload.auth) {
                newstate.auth = action.payload.auth
                newstate.loggedin = true
                newstate.roles = action.payload.roles
                newstate.privileges = action.payload.privileges
            }
            return newstate

        case actions.AUTH_ATTEMPT:
            return { ...state, login_attempted: true }

        case 'LOGOUT':
            return { ...state, pending: false, error: false, auth: {}, loggedin: false, roles: [], privileges: {} }

        default: return state;
    }

}