export const message_add_action = (message) => {
    return async dispatch => { dispatch({ type: 'MESSAGE_ADD', message }); }
}

export const message_add_more_action = (messages) => {
    return async dispatch => { dispatch({ type: 'MESSAGE_ADD_MORE', messages }); }
}

export const message_del_action = (id) => {
    return async dispatch => { dispatch({ type: 'MESSAGE_DEL', id }); }
}

export const message_clear_action = (msec = 1000) => {
    let time = Date.now()
    return async dispatch => {
        setTimeout(() => {
            dispatch({ type: 'MESSAGE_DEL_EXP', time });
        }, msec)
    }
}