import React from 'react'
//import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import OneTask from "../../m_item/client_solve/a_one_task"
import NewPage from '../../m_common/client_jsx/new_page'
import { min_privileges } from '../../config/client.config'

export default (props) => {
    const { challenge_group } = useParams()
    return <>
        <NewPage />
        <OneTask exit_path={"/gyakorlas/" + challenge_group} privileg={min_privileges.challenge} can_save={true} />
    </>
}