import { array_uniq } from '../../../m_common/common/array.methods'

export function handle_js(code, cw = {}, iw) {
    let s2 = "" + code

    if (code.includes("__$test") || (cw.c + "." + cw.e) === "test.js") {
        return s2
    }


    // document.write
    s2 = s2.replace(/document\.write/g, "document.querySelector('.__insert_html1').innerHTML=")

    // ---------- event listeners --------
    if (iw.__$eventlisteners)
        s2 = s2.replace(/addEventListener/g, "addEventListene2")



    // infinite loop prevention   
    const loop_count = (a) => { return (a || []).length }
    const loop_prevention = " ; __$frame.step(); "

    //let d0 = Date.now();
    //let d1 = Date.now();

    let loop_matches = {}
    //console.clear();

    loop_matches.while = s2.match(/while(.)*?\((\s|.)*?\)/g) || []
    //console.log(1, (Date.now() - d1) + "ms"); d1 = Date.now();

    loop_matches.while_f = []; if (loop_count(loop_matches.while))
        loop_matches.while_f = s2.match(/while(.)*?\((\s|.)*?\)(\s|.)*?{/g) || []
    loop_matches.while_f = loop_matches.while_f.filter(m => !!m.match(/while(.)*?\((\s|.)*?\)(\s)*?{/))
    //console.log(2, (Date.now() - d1) + "ms"); d1 = Date.now();

    loop_matches.do = s2.match(/(\s|;|\)|{)do(\s|{)/g) || []
    //console.log(3, (Date.now() - d1) + "ms"); d1 = Date.now();

    loop_matches.do_f = []; if (loop_count(loop_matches.do))
        loop_matches.do_f = s2.match(/(\s|;|\)|{)do(\s*?){/g) || []
    //console.log(4, (Date.now() - d1) + "ms"); d1 = Date.now();

    loop_matches.do_while = []; if (loop_count(loop_matches.do_f))
        loop_matches.do_while = s2.match(/(\s|;|\)|{)do(\s*?){(.|\s)*?}(\s*?)while(.)*?\((\s|.)*?\)/g) || []
    //console.log(5, (Date.now() - d1) + "ms"); d1 = Date.now();


    let loop_nr = {
        diff_do_f: loop_count(loop_matches.do_f) - loop_count(loop_matches.do),
        diff_do_while: loop_count(loop_matches.do_while) - loop_count(loop_matches.do_f),
        diff_while_f: loop_count(loop_matches.while_f) - (loop_count(loop_matches.while) - loop_count(loop_matches.do_while))
    }

    if (loop_nr.diff_do_while !== 0) throw new Error('!!! ne használj "do"-t "while" nélkül')
    if (loop_nr.diff_do_f !== 0) throw new Error('!!! ne használj "do"-t kódblokk nélkül')
    if (loop_nr.diff_while_f !== 0) throw new Error('!!! ne használj "while"-t kódblokk nélkül')

    let loop_fulls = array_uniq([...loop_matches.while_f, ...loop_matches.do_f])
    loop_fulls.forEach(m => {
        s2 = s2.split(m).join(m + loop_prevention)
    })

    //console.log(9, (Date.now() - d0) + "ms"); 

    return s2
}