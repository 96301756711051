import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { valEmail, valPW } from '../../m_common/client/h_validate'
import { login_action } from './pure.auth.actions'


export default (props) => {
    const [email, setem] = useState('')
    const [password, setpw] = useState('')
    const [err1, seterr1] = useState('')
    const [err2, seterr2] = useState('')

    const validateAll = (email, password) => {
        let emailOk = valEmail.bind(this)(email, seterr1)
        let pwOk = valPW.bind(this)(password, seterr2)
        return emailOk && pwOk
    }
    const dispatch = useDispatch()

    let pending = false;
    const submit = (e, email, password) => {
        e.preventDefault();
        if (!pending) {
            pending = true;
            if (validateAll(email, password)) {
                let input = { email: email.toLowerCase(), password: password }
                login_action(input, dispatch)
                    .then(x => { pending = false; })
                    .catch(e => {
                        if (e === 'err_nouser') seterr1("* nincs ilyen felhasználó")
                        if (e === 'err_wrongpass') seterr2("* hibás jelszó")
                        pending = false;
                    })
            } else pending = false
        }
    }

    return <>
        <div className='mw500'>
            <div className='row accentbox'>
                <div className='row'>
                    <h4>Bejelentkezés</h4>
                </div>
                <div className='row mtop10'>
                    <form onSubmit={e => submit(e, email, password)}>
                        <div className='row mtop10 '>
                            <div className='inputrow mw200'>
                                <input className='bt_trans_bo_white mw200 text-center text-ls1' type='email' placeholder='- email -' onChange={e => setem(e.target.value)} autoFocus></input>
                                <div className='inputerror'>{err1}</div>
                            </div>
                        </div>
                        <div className='row mtop10 '>
                            <div className='inputrow mw200'>
                                <input className='bt_trans_bo_white mw200 text-center text-ls1' type='password' placeholder='- jelszó -' onChange={e => setpw(e.target.value)}></input>
                                <div className='inputerror'>{err2}</div>
                            </div>
                        </div>
                        <div className='row mtop10 '>
                            <div className='inputrow mw200'>
                                <button className='button big bt_trans_bo_white'>Belépés</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    </>

}

