import React from 'react'
import { Questions } from './ph_quiz'

export function Quiz(props) {
    return !props.item.task.quiz.length ? '' :
        <EFrameQuiz
            title={props.title || 'Tesztkérdések'}
            nohead={props.nohead || false}
            questions={props.item.task.quiz}
            shuffle={props.item.task.settings.quiz.shuffle}
            onAction={props.onAction}
            finished={props.finished || false}
        />
}

function EFrameQuiz(props) {
    return <>
        <div className={'row' + (props.nohead ? ' pl1' : ' pl1t1')}>
            <div className='row'>
                {props.nohead || !props.title ? '' :
                    <div className='row codebox-header'>
                        <span>{props.title}</span>
                    </div>
                }
                <div className={'row p10 content_light cl_black'}>
                    <div className='mw800 leftx p5_10'>
                        <Questions
                            questions={props.questions}
                            shuffle={props.shuffle}
                            onAction={props.onAction}
                            finished={props.finished || false}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
}