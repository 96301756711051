import React, { useState } from 'react'
import timer from '../../../m_common/client/timer'

import { TaskAction } from './a-action'
import { ItemLayout } from './a-layout'

export default function Task({ solution, next_button, item, onAction }) {
    const { show, set } = item.task.settings
    let bar = true
    const [status, setStatus] = useState({
        timer: timer(true),
        finished: false, showsolution: false, reset: '1', code_ok: false,
        quiz: {},
        stat: { task_nr: 0, ok_nr: 0, touch_nr: 0, rate: 1, timesec: 0, d0: 0, d1: 0, solved: 0 },
        can_finish: set.goal === 'todo'
    })


    const action = (ac) => {
        TaskAction({ ac, status, item, show, setStatus, onAction })
    }

    return <ItemLayout {...{
        action, status, item, solution, next_button,
        bar, show,
    }} />
}

