import React from 'react'
import GetGroupItems from './h_get_data'
import GetSolutions from './h_get_solutions'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { privileges_selector, roles_selector } from '../m_auth/client/store.auth.selectors'
import { content_group_item, content_item_overview, content_solution } from '../m_item/client_redux/content.selectors'
import { show_message } from '../m_common/client_messages/pure.messages.actions'

import can_open_item from '../m_item/meta/can_open'
import { mode as mode_meta } from '../m_item/meta/sets'

export default (props) => {
    const { group_id, group_title, task_path } = props
    const group_item = useSelector(store => content_group_item(store, group_id))

    return <>
        <GetGroupItems group_id={group_id} />
        {
            group_item
                ? <HaveGroupItems group_id={group_id} group_title={group_title} group_item={group_item} task_path={task_path} />
                : null
        }
    </>
}

const HaveGroupItems = (props) => {
    return <>
        <div className='mw600'>
            <div className='row course_section'>
                <div className='course_section_header'>{props.group_title}</div>
                <div className='row m20_0'>
                    <div className='row course_element chapter_data level1' style={{ backgroundColor: "rgb(102, 201, 210)" }}>
                        <span className='element_icon far fa-folder-open'></span>
                        <span className='element_name'>Feladatok</span>
                    </div>
                    <ShowGroupItems1 {...props} />
                </div>
            </div>

        </div>
    </>

}


const ShowGroupItems1 = (props) => {
    const { group_item, task_path } = props
    let item_ids = Object.keys(group_item).map(id => id * 1)
    let items = item_ids.map(id => group_item[id])
    return <>
        <GetSolutions item_ids={item_ids} />
        <ShowGroupItems2 items={items} task_path={task_path} />
    </>
}


const ShowGroupItems2 = (props) => {
    const { items, task_path } = props
    return !items
        ? null
        : items.map(item => <LoadItem key={item.item_id} task_path={task_path} {...item} />)
}

const LoadItem = (props) => {
    const { code, task_path } = props
    const item = useSelector(store => content_item_overview(store, code))
    return !item ? null : <LoadPrivs item={item} task_path={task_path} />
}

const LoadPrivs = (props) => {
    const { item, task_path } = props
    const privileges = useSelector(privileges_selector)
    const roles = useSelector(roles_selector)
    let can_open = can_open_item(item, roles, privileges)
    let nextprops = { item, can_open, task_path }
    return <ShowItem {...nextprops} />
}

const ShowItem = (props) => {
    const { item, can_open, task_path } = props
    const dispatch = useDispatch()
    let solution = useSelector(store => content_solution(store, item.id))
    let done = (solution || {}).solved

    const Inner = () => {
        return <>
            {can_open ?
                <span className={'element_play_icon ' +
                    (done ? 'cl_green fas fa-check' : 'cl_redx op2 far fa-circle')}></span>
                :
                <span className={'element_play_icon ' +
                    mode_meta[item.availability.mode].icon +
                    " " +
                    mode_meta[item.availability.mode].u_color}></span>
            }
            <span className='element_icon fas fa-code' style={{ color: "rgb(102, 201, 210)" }}></span>
            <span className='element_name'>{item.overview.name}</span>
        </>
    }

    const onClick = () => {
        if (!can_open) show_message(mode_meta[item.availability.mode].cant_message, dispatch)
    }

    return !item ? null : <>
        {can_open ?
            <Link to={task_path + "/" + item.code}>
                <div className={'row course_element chapter_item can_open' + (done ? ' done' : '')}>
                    <Inner />
                </div>
            </Link>
            :
            <div className={'row course_element chapter_item cant_open' + (done ? ' done' : '')} onClick={onClick}>
                <Inner />
            </div>
        }
    </>
}