import React from 'react'
import { Link } from 'react-router-dom'
import { NotLoggedIn } from '../../m_auth/client/w-loggedin'


export default function RowFirst(props) {
    return <>
        <div className='pagerow fullrow'>
            <div className='pagerowbg absolute tr0 wh100'>
                <img className='pagerowimg landing_main_image' src='/public/background/3b4.jpg' alt='bg' />
            </div>
            <div className='pagerowtext'>
                <div className='row'>

                    <div className='row typ1'>
                        <div className='text-center'>
                            <div className='toload3 s1'><span>Vigyázz</span></div>
                            <div className='toload3 s2'><span>Kész</span></div>
                            <div className='toload3 s3'><span>Code!</span></div>
                        </div>
                    </div>

                    <div className='row typ2 mtop50'>
                        <div className='text-center'>
                            <div className='toload3 s4'><span>Tanulj programozni</span></div>
                            <div className='toload3 s5'><span>interaktív online feladatokkal</span></div>
                        </div>
                    </div>

                    <div className='row mtop30 text-center toload3 s6'>
                        <Link to='/kurzusok'><button className='button bt_yellow big m10'>Kurzusok</button></Link>
                        <NotLoggedIn>
                            <Link to='/felhasznalo'><button className='button bt_accent big m10'>Bejelentkezés</button></Link>
                        </NotLoggedIn>
                    </div>

                </div>
            </div>
        </div>
    </>
}
