import React from 'react'
import { useState } from 'react'
import { newpass_action } from './pure.auth.actions'
import { useDispatch } from 'react-redux'
import { valPW, valPW2 } from '../../m_common/client/h_validate'

export default function (props) {
    const [password2, setpw2] = useState('')
    const [password3, setpw3] = useState('')
    const [err2, seterr2] = useState('')
    const [err3, seterr3] = useState('')
    const dipatch = useDispatch()


    let pending = false;
    const send = (newpassword) => {
        pending = true;
        let input = { newpassword, newpasstoken: props.newpasstoken }
        newpass_action(input, dipatch)
            .then(x => {
                props.onAction({ what: 'newpass', val: 1 })
                pending = false;
            })
            .catch(e => {
                props.onAction({ what: 'newpass', val: 0 })
                pending = false;
            })
    }

    const validateAll = () => {
        let pw2Ok = valPW.bind(this)(password2, seterr2)
        let pw3Ok = valPW2.bind(this)(password2, password3, seterr3)
        return pw2Ok && pw3Ok
    }
    const submit = (e) => { e.preventDefault(); if (validateAll()) if (!pending) send(password2) }

    return <>
        <div className='mw500x'>
            <div className={'row accentbox text-center ' + props.class}>
                {props.nohead ? '' :
                    <div className='row'>
                        <h4>Új jelszó megadása</h4>
                        <div className='mtop10'></div>
                    </div>
                }
                <div className='row'>
                    <form onSubmit={e => submit(e)}>

                        <div className='row mtop10'>
                            <div className='inputrow mw200'>
                                <input className='bt_trans_bo_white text-center mw200 text-ls1' type='password' placeholder='- új jelszó -' onChange={e => setpw2(e.target.value)}></input>
                                <div className='inputerror'>{err2}</div>
                            </div>
                        </div>
                        <div className='row mtop10 '>
                            <div className='inputrow mw200'>
                                <input className='bt_trans_bo_white text-center mw200 text-ls1' type='password' placeholder='- új jelszó újra -' onChange={e => setpw3(e.target.value)}></input>
                                <div className='inputerror'>{err3}</div>
                            </div>
                        </div>
                        <div className='row mtop10'>
                            <button className='button big bt_trans_bo_white'>Elküld</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}