import { renderCodeMirrors } from "./tech/render-cws";
import { resetOne } from "./tech/events-cw";
import { TECH_ONCHANGE, TECH_INIT } from './tech/events-tech'

const sol_postfix = '_s'

export function INIT(){
    CW_INIT.bind(this)()
    TECH_INIT.bind(this)();
}

export function ON_TEST(ok) {
    if (this.props.onAction) this.props.onAction({ type: 'code', ok })
}

export function ON_RESET() {
    this.resetc = '' + this.props.reset
    this.finished = true && this.props.finished
    for (let i in this.cws) resetOne.bind(this)(i)
    //CW_INIT.bind(this)()
    TECH_INIT.bind(this)()
    //TECH_ONCHANGE.bind(this)()        // CW_ONCHANGE handels it
}

export function ON_FINISH() {
    this.finished = true && this.props.finished
    let show_solution = this.props.solution

    for (let i in this.cws) this.cws[i].setOption('readOnly', 'nocursor')

    if (show_solution) {
        for (let i in this.cw) if (this.cw[i].b === 'x') if (this.cw[i + sol_postfix]) if (this.cw[i + sol_postfix].b === 's') {
            let newv = '' + this.cw[i + sol_postfix].v
            this.cw[i].v = newv
            this.cws[i].setValue(newv)
        }
        //CW_INIT.bind(this)()
        //setTimeout(() => { TECH_ONCHANGE.bind(this)() }, 300)        // CW_ONCHANGE handels it
    }
}



export function CW_ONCHANGE(code) {
    PARENT_ONCHANGE.bind(this)()
    TECH_ONCHANGE.bind(this)(code)
}


export function CW_INIT() {
    renderCodeMirrors.bind(this)()
}


function PARENT_ONCHANGE() {
    this.props.onChange(this.cw);
}