import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { shop_history_selector } from './store.shop.selectors'
import { shop_pending_selector } from './store.shop.selectors'
import { shop_get_history } from "./pure.shop.actions"
import { packages } from '../../config/shop.cfg'
import Loading from '../../m_common/client_jsx/loading'

export default (props) => {
    const history = useSelector(shop_history_selector)
    const pending = useSelector(shop_pending_selector)
    const dispatch = useDispatch()

    if (!history && !pending) {
        shop_get_history(dispatch)
    }

    return (!history) ? <Loading />
        : <>
            <div className={'row accentboxx ' + props.class}>
                {props.nohead ? '' :
                    <div className='row text-center'>
                        <h4>Korábbi vásárlások</h4>
                        <div className='mtop10'></div>
                    </div>
                }
                <div className='row m20_0'>
                    <table className='w100 table_dark'>
                        <tbody>
                            <tr>
                                <th className=''>Megnevezés</th>
                                <th className=' mw100'>Időpont</th>
                                <th className=' mw100'>Összeg</th>
                            </tr>
                            {
                                history.sort((a, b) => { return b.time_paid - a.time_paid }).map((v, i) => <SubView key={i} sub={v} />)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
}


function SubView(props) {
    let { sub } = props
    return <tr>
        <td className=''>{packages[sub.package_name]?.name}</td>
        <td className='text-center'>{sub.time_paid ? new Date(sub.time_paid).toLocaleDateString() : "-"}</td>
        <td className='text-center'>{`${sub.amount_total} ${sub.currency}`}</td>
    </tr>
}