import React from "react"
import { Switch, useRouteMatch, useParams, Route, Redirect, Link } from 'react-router-dom'
import { challenge_groups } from '../../config/client.config'
import Group from '../../m_group/g_group'
import NewPage from '../../m_common/client_jsx/new_page'
import Awards from '../../m_item/client_other/challenge_awards'

export default () => {

    return <>
        <div className='row fullheight page_light font-main noselect'>
            <div className="mw1000_500 toload2 ">
                <Routes />
            </div>
        </div>
    </>
}



const Routes = () => {
    const match = useRouteMatch();

    return <>
        <Switch>
            <Route path={match.path + "/:challenge_group"}>

                <div className='row site_course_group m50_0'>
                    <div className='row text-center'>
                        <h4 className='ptitle'>Gyakorlás</h4>
                    </div>
                    <div className='row desc text-center op6'>
                        <div className="mw800">
                        <p>Gyakorló feladatokkal segítünk neked abban, hogy folyamatosan mérd és fejleszd a tudásodat. </p>   
                        <p>Teljesítsd az aktuális kihívásokat, és gyűjtsd be érte a neked járó pontokat!</p>     
                        <p>A korábbi feladványoknál ellenőrizheted a megoldást is.</p>     
                        </div>
                    </div>
                    <Header2 />
                    <Pages2 />
                </div>
            </Route>

            <Route path={match.path + "/"}>
                <Redirect to={`${match.url}/aktualis`} />
            </Route>
        </Switch>
    </>
}



const Header2 = (props) => {
    const { challenge_group } = useParams();
    return <>
        {
            <div className='row App-header3 mtop50'>
                <div className='mcenter'>
                    <Link to={"/gyakorlas/aktualis"} className={challenge_group === "aktualis" ? "active" : ""}>
                        Aktuális<span className='nomobile'> kihívások</span>
                    </Link>
                    <Link to={"/gyakorlas/lejart"} className={challenge_group === "lejart" ? "active" : ""}>
                        Lejárt<span className='nomobile'> feladatok</span>
                    </Link>
                </div>
            </div>
        }
    </>
}

const Pages2 = () => {
    const { challenge_group } = useParams();
    let cnf = challenge_groups[challenge_group] || { group_id: 0, code: "", group_title: "" }


    return <>
        <NewPage title={cnf.title} />

        {
            challenge_group === 'aktualis'
                ? <div className='row p5 mtop20x' style={{ marginBottom: '-50px' }}>
                    <div className='mw600'>
                        <Awards />
                    </div>
                </div>
                : null
        }

        <div className='row p5 m50_0'>
            <Group {...cnf} task_path={"/gyakorlas/" + challenge_group} />
        </div>
    </>
}

