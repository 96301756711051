import React from 'react'
import { CourseOverview } from './css_overview'

export function CourseGroup(props) {
    const { group } = props

    return !group ? null : <div className='row site_course_group'>
        <div className='row'>
            {!group.img ? null :
                <img className='image' src={group.img} alt={group.name} />
            }
            {!group.icon || group.img ? null :
                <span className='icon' >
                    <span className={group.icon}></span>
                </span>
            }
            <span className='title'>
                <span className='fas fa-code mright10 op3 cl_accentX'></span>
                <span>{group.name}</span>
            </span>
        </div>
        <div className='row'>
            <div className='desc op6'>
                <span>{group.desc}</span>
            </div>
        </div>
        <div className='row'>
            {
                group.courses.map((v, i) => <CourseOverview key={v} code={v} />)
            }
        </div>


    </div>
}