import React from 'react'
import { useDispatch } from 'react-redux'
import CodeSet from '../../../m_code/client_set/code_set'



export function Code(props) {
    return <>
        <CodeSet
            code={props.item.task.code}
            id={props.id || props.item.id}
            admin={false}
            finished={props.finished}
            solution={props.solution}
            reset={props.reset}
            onAction={props.onAction}
            onChange={x => { }}
            dispatch={useDispatch()}
        />
    </>
}