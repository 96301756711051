import { ApiPost } from '../../m_common/client/api_common'

export function shop_buy_send(input, dispatch) { return ApiPost("shop_buy_start", input, dispatch) }


export function shop_buy_package(input, dispatch) {
    return shop_buy_send({
        ...input,
        origin: window.location.origin,
        hostname: window.location.hostname,
        location: window.location.href
    }, dispatch)
    /*.then(x => {
        if (x.ok) {
            if (x.data.payurl)
                //window.open(x.data.payurl)
                window.location.replace(x.data.payurl)
        }
    })
    .catch(e => { })*/
}


export function shop_get_history(dispatch) {
    dispatch({ type: 'SHOP_SENT' })
    ApiPost("shop_get_history", {}, dispatch)
        .then(x => {
            try {
                dispatch({ type: 'SHOP_BACK', payload: { shop_history: x.data.shop_history } })
            } catch (e) {
                dispatch({ type: 'SHOP_ERROR' })
            }
        })
        .catch(e => {
            dispatch({ type: 'SHOP_ERROR' })
        })
}

