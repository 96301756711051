import React from 'react'
import { Link } from 'react-router-dom'
import {img_urls} from '../config/ui.config'
import { common_menu_open_selector } from "../m_common/client_redux/common.selectors"
import { useSelector } from 'react-redux'

export default function () {
    const menu_open = useSelector(common_menu_open_selector)

    return <>
        <div className={'App-footer row'  + (menu_open ? " menu_open" : "") }>

            <div className='row footerrow' >
                <div className='row mtop40'>
                    <div className='mw1000'>
                        <div className='col5_m3 p10'>
                            <div className='row p5 boldx op3'>Codeman</div>
                            {/*<div className='row p5'><Link className='hcl_accent' to='/'>Főoldal</Link></div>*/}
                            <div className='row p5'><Link className='hcl_accent' to='/kurzusok'>Kurzusok</Link></div>
                            {/*<div className='row p5'><Link className='hcl_accent' to='/gyakorlas'>Gyakorlás</Link></div>*/}
                            <div className='row p5'><Link className='hcl_accent' to='/elofizetes'>Előfizetés</Link></div>
                            <div className='row p5'><Link className='hcl_accent' to='/kapcsolat'>Kapcsolat</Link></div>
                        </div>

                        <div className='col5_m3 p10'>
                            <div className='row p5 boldx op3'>Információ</div>
                            {/*<div className='row p5'><Link className='hcl_accent' to='/info/fejlesztesek'>Fejlesztések</Link></div>*/}
                            <div className='row p5'><Link className='hcl_accent' to='/info/rolunk'>Codeman.hu-ról</Link></div>
                            <div className='row p5'><Link className='hcl_accent' to='/info/aszf'>ASZF</Link></div>
                            <div className='row p5'><Link className='hcl_accent' to='/info/adatvedelem'>Adatvédelem</Link></div>
                        </div>

                        <div className='col5_m3 p10'>
                            <div className='row p5 boldx op3'>Aktuális Kurzusok</div>
                            <div className='row p5'><Link className='hcl_accent' to='/kurzus/4dmkqet5fek0'>HTML gyakorlati bevezető</Link></div>
                            <div className='row p5'><Link className='hcl_accent' to='/kurzus/4dri4bip1gn0'>CSS alapok - 1</Link></div>
                            <div className='row p5'><Link className='hcl_accent' to='/kurzus/4efvoui0urd0'>CSS alapok - 2</Link></div>
                            <div className='row p5'><Link className='hcl_accent' to='/kurzus/4earafanemp0'>Javascript alapok - 1</Link></div>
                            <div className='row p5'><Link className='hcl_accent' to='/kurzus/4f193pf0nur0'>Interjú feladatok</Link></div>
                        </div>

                        <div className='col5_m1 right p10'>
                            <div className='row p5 text-center'>
                                <img src={img_urls.brand_logo1} alt='codeman.hu' style={{ maxWidth: "150px", width: "100%" }} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className='row footerrow'>
                <div className='row mtop40x'>
                    <div className='mw1000'>
                        <div className='row text-center'>
                            <img src={img_urls.barion_strip1} alt='codeman.hu' style={{ maxWidth: "300px", width: "100%", margin: "0px auto" }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row footerrow'>
                <div className='row mtop40x'>
                    <div className='mw1000'>
                        <div className='row text-center p10'>
                            <span className='far fa-copyright'></span> 
                            <span> Codeman.hu 2020</span>
                    </div>
                    </div>
                </div>
            </div>

        </div>
    </>
}