import { cookies_must_allow } from '../../config/client.config'

export default {
    set: (item, val, days = 1) => {
        try {
            if (_allowed() || !cookies_must_allow) setCookie(item, val, days)
            else return false
            return true
        } catch (e) { return false; }
    },
    get: (item, def = undefined) => {
        try {
            return getCookie(item)
        } catch (e) { return def }
    },
    del: (item) => {
        try {
            setCookie(item, '-', -1)
        } catch (e) { return false; }
    },
    allow: () => {
        setCookie('_co', '1', 7200)
    },
    allowed: _allowed
}


function _allowed() {
    return !!getCookie('_co')
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}