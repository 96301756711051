import React from 'react'
import './a-iframe2-base.scss'
import './a-iframe2-info1.scss'
import behave from '../meta/h_behaviour'
import { CwHead } from './p-cw-head'
import { CwBody } from './p-cw-body'
import { InfoHead, InfoHeadConsole, InfoHeadTest } from './p-info-head'
import { InfoBody, InfoBodyConsole, InfoBodyTest } from './p-info-body'

export function Iframe2Window({ cw, info }) {
    const _toShow = behave.toShow(cw, 0, false)
    const _toEdit = behave.toEdit(cw)
    const [state, setS] = React.useState({
        toShow: _toShow,
        active: _toEdit[0] || _toShow[0],
        info: info?.init || "closed",
        fixed: info?.fixed || false,
        noInput: !_toShow.length
    })
    const setState = json => setS({ ...state, ...json })
    const activate = active => setState({ active })
    const inform = info => setState({ info })
    const classes = json => Object.keys(json).filter(k => json[k]).join(' ')

    return <>
        <div id='technology_iframe2' className={classes({ noInput: state.noInput })}>

            <div className='iframe2_col iframe2_col_in'>
                <div id='iframe2_cws'>
                    <CwHead {...{ state, activate, cw }} />
                    <CwBody {...{ state }} />
                </div>
            </div>

            <div className={'iframe2_col iframe2_col_out info_' + state.info}>
                <div id='iframe2_iframe'>
                    <div id='iframe2_iframe_head'>
                        <div className='headeritem'>Böngésző</div>
                    </div>
                    <iframe
                        title='iframe2_iframe'
                        id="__iframe" width="100%" height="100%"
                        sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation allow-downloads" >
                    </iframe>
                </div>

                {state.info === 'split'
                    ? <InfoSplit />
                    : <InfoNormal {...{ state, inform }} />
                }

            </div>
        </div>
    </>
}

function InfoNormal({ state, inform }) {
    return <div id='iframe2_info'>
        <InfoHead {...{ info: state.info, inform, fixed: state.fixed }} />
        <InfoBody {...{ info: state.info }} />
    </div>
}

function InfoSplit() {
    return <div id='iframe2_info'>
        <div className='iframe2_info_split border-bottom'>
            <InfoHeadTest />
            <InfoBodyTest />
        </div>

        <div className='iframe2_info_split'>
            <InfoHeadConsole />
            <InfoBodyConsole />
        </div>
    </div>
}