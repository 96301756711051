import React from 'react'

export function CwBody({ state }) {
    const { toShow, active } = state
    return <div id='iframe2_cws_body' className='codeboxes'>
        {
            toShow.map(c =>
                <div key={c} className={'codebox full bodyitem ' + (active === c ? 'active' : 'inactive')}>
                    <div
                        className={'codebox-content'}
                        id={'cw_id_' + c}
                    ></div>
                </div>
            )
        }
    </div>
}