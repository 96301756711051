
var default_message_state = {
    messages: {},
    counter: 1,
    mcounter: 2,
}


export function messages_reducer(state = default_message_state, action) {
    let newstate = { ...state }
    const addOne = (message) => {
        newstate.mcounter++
        newstate.messages["c" + newstate.mcounter] = { ...message }
    }

    switch (action.type) {
        case 'MESSAGE_ADD':
            addOne(action.message)
            newstate.counter++
            break;

        case 'MESSAGE_ADD_MORE':
            action.messages.forEach(message => {
                addOne(message)
            });
            newstate.counter++
            break;

        case 'MESSAGE_DEL':
            delete newstate.messages[action.id]
            newstate.counter++
            break;

        case 'MESSAGE_DEL_EXP':
            let delete_any = false;
            for (let m in newstate.messages) if (newstate.messages[m].exp < action.time) {
                delete newstate.messages[m]
                delete_any = true
            }
            if (delete_any) newstate.counter++
            break;

        default: break;
    }

    return newstate

}



