import React from "react"
import { Switch, Route, Redirect } from 'react-router-dom'
import AppHeader from './app.header'
import AppContent from './app.content'
import AppFooter from './app.footer'

export default function AppRoutes() {
    return <>
        <Switch>
            <Route exact path='/'>
                <AppHeader page='landing' />
                <AppContent page='landing' />
                <AppFooter />
            </Route>

            <Route path='/elofizetes'>
                <AppHeader page='shop' />
                <AppContent page='shop' />
                <AppFooter />
            </Route>

            <Route path='/info'>
                <AppHeader page='info' />
                <AppContent page='info' />
                <AppFooter />
            </Route>
            
            <Route path='/kapcsolat'>
                <AppHeader page='contact' />
                <AppContent page='contact' />
                <AppFooter />
            </Route>


            <Route path='/felhasznalo'>
                <AppHeader page='user' />
                <AppContent page='user' />
                <AppFooter />
            </Route>

            <Route path='/kurzus/:course_code/:task_id'>
                <AppContent page='coursetask' />
            </Route>
            <Route path='/kurzus/:course_code'>
                <AppHeader page='course' />
                <AppContent page='course' />
                <AppFooter />
            </Route>
            <Route path='/kurzus'>
                <Redirect to='/kurzusok' />
            </Route>

            <Route path='/kurzusok'>
                <AppHeader page='courses' />
                <AppContent page='courses' />
                <AppFooter />
            </Route>


            {/*
            <Route path='/gyakorlas/:challenge_group/:task_code'>
                <AppContent page='challenge' />
            </Route>
            <Route path='/gyakorlas'>
                <AppHeader page='challenges' />
                <AppContent page='challenges' />
                <AppFooter />
            </Route>
            */}

            <Route path='/demo'>
                <AppContent page='demo' />
            </Route>

            <Route path='/feladat'>
                <AppContent page='exercise' />
            </Route>

            <Route path='/'>
                <Redirect to="/" />
            </Route>


        </Switch>
    </>
}

