import React, { Fragment } from 'react'
import { CodeWindow } from './frame-cw'

const iterate = (cw, callback) => {
    return Object.keys(cw).map(x => { return { c: x, ...cw[x] } }).map((e, i) => { return callback(e, i) })
}

export function CodeWindows() {
    return iterate(this.cw, (e, i) => {
        return <Fragment key={e.c}>
            {CodeWindow.bind(this)(e, this.cm[e.c] || 0)}
        </Fragment>
    })
}
