import React from "react"
import HeaderDesktop from './app.header.desktop'
import HeaderMobile from './app.header.mobile'

export default (props) => {


    return props.noheader ? null : <>
        <HeaderDesktop page={props.page} />
        <HeaderMobile page={props.page} />
    </>
}

