import React from 'react'
import TaskRow from './cs_content_taskrow'

export default function Tasks(props) {
    let { parent, course_item, course_temp } = props
    let data = course_temp?.cache
    let itemsA = data.items.filter(i => { return data.item[i].group_id === parent })
    let AI = itemsA
    return AI.map(i =>
        <TaskRow
            key={i}
            id={i}
            course_item={course_item}
            course_temp={course_temp}
        />)
}

