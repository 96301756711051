import React, { useState } from 'react'
import { arr_shuffle } from '../../../m_common/common/fx2'


const ABC = 'ABCDEFGHIJKLMNOPQRSTUXYZ'
const gsum = (quiz) => quiz.reduce((e, v, i, a) => { return e + v.g }, 0)


export function Questions(props) {
    let _questions = [...props.questions]
    if (props.shuffle) _questions = arr_shuffle(_questions)
    const [questions,] = useState(_questions)
    const [multis,] = useState(questions.map(q => gsum(q.options) !== 1))
    return <>
        {
            questions.map((q, i) => {
                return <div key={q.c} className='row mbottom10'>
                    <div className='row m5_0 p5'>
                        <div className='p5 cl_accent bold miw30 absolute tl0 text-center'>{i + 1}</div>
                        <div className='left pl30'>
                            <span className={'bold cl_black'}>{q.title}</span>
                            {multis[i] ? <span className='cl_accentx op5'> - Több válasz lehetséges!</span> : ''}
                        </div>
                    </div>
                    <Options
                        options={q.options}
                        code={q.c || 0}
                        multi={multis[i]}
                        onAction={props.onAction}
                        finished={props.finished || false}
                    />

                </div>
            })
        }
    </>
}


export function Options(props) {
    let finished = props.finished
    let multi = props.multi || false
    const [options,] = useState(arr_shuffle(props.options))
    const [selected, setSelected] = useState(new Array(props.options.length).fill(0))
    const select = (i) => {
        if (finished) return;
        let nselected = [...selected]
        if (!multi) nselected = new Array(props.options.length).fill(0)
        nselected[i] = 1 - nselected[i]
        setSelected(nselected)
        send(nselected)
    }
    const send = (nselected = undefined) => {
        if (finished) return;
        let ok = evalquiz(nselected);
        props.onAction({ type: 'quiz', code: props.code, ok })
    }
    const evalquiz = (nselected = selected) => {
        let diff = 0;
        options.forEach((v, i) => { diff += Math.abs(v.g - nselected[i]) })
        let ok = (diff === 0) ? 1 : 0
        return ok
    }


    return <>
        {options.map((o, i) => {
            return <div className={'row m3'} key={o.c}>
                <div className={
                    'exquizpoint p5 pointer row' +
                    (finished ? '' : '') +
                    (selected[i] ? ' bg_yellow_02' : '')
                } onClick={() => select(i)}>
                    <div className='absolute tl0 p5'>
                        <span className='exquizpoint_sol left miw30 text-center'>
                            {finished ?
                                !(o.g || selected[i]) ?
                                    <span className='fas fa-circle op0'></span>
                                    :
                                    o.g ?
                                        (selected[i] ?
                                            <span className='fas fa-check cl_green'></span>
                                            :
                                            <span className='fas fa-check cl_accent2'></span>
                                        )
                                        :
                                        <span className='fas fa-times cl_red'></span>
                                :
                                selected[i] ?
                                    <span className='fas fa-caret-right cl_yellow'></span>
                                    :
                                    <span className='fas fa-circle op0'></span>
                            }
                        </span>
                        <span className='mright10 boldx op7 cl_accent'>{ABC[i]}</span>
                    </div>
                    <div className='left pl50'>
                        <span>{o.t}</span>
                    </div>
                </div>

            </div>
        })
        }
    </>
}


