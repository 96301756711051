export function iframe_del_objects(iw, del_prefix = "", keep_prefix = "") {
    if (!this.iw_objects.length) return
    const start = (s1, s2) => {
        return s1.substr(0, s2.toString().length) === s2.toString()
    }

    let new_iw_objects = [...Object.keys(iw)]
    let diff_iw_objects = new_iw_objects.filter(x => { return !this.iw_objects.includes(x) })
    let objectsToKeep = []
    try { objectsToKeep = iw.__$frame.objectsToKeep() } catch (e) { }

    let todel_iw_objects = diff_iw_objects.filter(x => (
        start(x, del_prefix) &&
        !start(x, keep_prefix || "xXxXxXxX") &&
        !objectsToKeep.includes(x)
    ))

    todel_iw_objects.forEach(y => {
        try {
            delete iw[y]
        } catch (e) {
            try {
                if (iw[y]) iw[y] = undefined
            } catch (e) {
                //console.log("cant delete: ", y)
            }
        }
    })
}
