export let address_meta = {
    scope: { text: 'Cím típusa', type: 'select2', options: [{ v: 'billing', t: 'Számlázási cím' }], value: 'billing' },
    company: { text: 'Személy', type: 'select2', options: [{ v: 0, t: 'Magánszemély' }, { v: 1, t: 'Cég, Szervezet' }], value: 0, valToClass: true },
    name: { text: 'Név', maxlength: '200', validations: ['name'] },
    tax: { text: 'Adószám', maxlength: '100', validations: ['tax'] },
    tax_eu: { text: 'EU-s adószám', maxlength: '100', validations: ['tax'], errorhint: "000-000-000" },
    //country: { type: 'select2', text: 'Ország', maxlength: '100', options: [{ v: 'hun', t: 'Magyarország' }] },
    country: { text: 'Ország', maxlength: '100', value : 'Magyarország', validations: ['name'] },
    zip: { text: 'Irányítószám', maxlength: '20', validations: ['zip'] },
    city: { text: 'Város', maxlength: '100', validations: ['city'] },
    address1: { text: 'Cím 1.sor', maxlength: '200', validations: ['address'] },
    address2: { text: 'Cím 2.sor', maxlength: '200', validations: [] }
}