import React from 'react'
import Lan from '../../config/lan'

export default function Attributes(props) {
    return <>
        <div className='row course_attributes_container'>
            <table className='w100'>
                <tbody>
                    {Object.keys(props.attributes).map(_a =>
                        <Attribute
                            key={_a}
                            name={Lan["attr_" + _a].hu}
                            value={props.attributes[_a]}
                        />
                    )}
                </tbody>
            </table>
        </div>
    </>
}

function Attribute(props) {
    return !props.value ? null : <tr style={{ borderBottom: "solid 1px #ddd" }}>
        <td className="p5 op5">{props.name}</td>
        <td className="p5 bold">{props.value}</td>
    </tr>
}