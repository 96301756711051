import React from 'react'


export default function PriceItem(props) {
    const { item, buttontext } = props

    return <>

        <div
            className={"priceitem" + (item.canbuy ? "" : " locked")}
            onClick={props.onClick}
        >
            <div className="pricelock"><span className="fas fa-lock"></span><span className="text lan">
                <span>Nem elérhető</span>
            </span></div>
            <div className="priceitemin bg_white">

                <div className="pricehead bg_accent">
                    <div className="pricename">
                        <span>{item.name}</span>
                    </div>
                    <div className="pricetag lan">
                        <span className="pricevalue">{item.gprice}</span>
                        <span className="pricecur">Ft</span>
                    </div>
                    <div className="pricetag2">
                        <span>{item.price} Ft+Áfa</span>
                    </div>
                </div>

                <div className="priceinclude">
                    <ul className="lan">
                        {item.pros.map((p, i) =>
                            <li key={i} className={i % 2 ? "even" : ""}>
                                <span className="fas fa-check"></span>
                                <span className="text">{p}</span>
                            </li>
                        )}
                    </ul>
                </div>

                <div className="pricebutton bg_accent">
                    <span className="text">{buttontext || 'Megveszem'}</span>
                </div>

            </div>
        </div>

    </>
}