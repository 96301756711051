import { ApiAuth } from '../../m_common/client/api_common'
import Tokens from './tokens'
import { resToMessage } from "../../m_common/client_messages/pure.messages.actions"
import { auth_back, auth_attempt } from './store.auth.actions'


export function logout_action(dispatch, silent = false) {
    Tokens.del('jwt')
    ApiAuth("auth_logout", { }, dispatch).then(x => {         }).catch(e => { })
    dispatch({ type: 'LOGOUT' })
    if (!silent) resToMessage({ ok: 1, msg: ["info_logout"] }, dispatch)
}

export async function login_token_action(dispatch) {
    let token = Tokens.get('jwt')
    if (token) ApiAuth("auth_logintoken", { token }, dispatch, auth_data_check).then(x => { }).catch(e => { })
    else dispatch(auth_attempt())
}

export function login_action(input, dispatch) { return ApiAuth("auth_login", input, dispatch, auth_data_check) }
export function register_action(input, dispatch) { return ApiAuth("auth_register", input, dispatch) }
export function forget_action(input, dispatch) { return ApiAuth("auth_forget", input, dispatch) }
export function newpass_action(input, dispatch) { return ApiAuth("auth_newpass", input, dispatch) }
export function changepass_action(input, dispatch) { return ApiAuth("auth_changepass", input, dispatch) }
export function verify_action(input, dispatch) { return ApiAuth("auth_verify", input, dispatch) }



export const auth_data_check = (res, dispatch) => {
    dispatch(auth_back(res.data))
}