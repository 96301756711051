import { BehaviorSubject } from 'rxjs'


export function CreateWS(callback = (ws) => { }, onerror = (error) => { }) {
    WS_base()
        .then(callback)
        .catch(onerror);
}

function WS_base() {
    return new Promise((Resolve, Reject) => {
        let hostname = window.location.hostname;
        let origin = window.location.origin;
        let port = 9090

        let url = 'ws://' + hostname + ':' + port;
        let wstoken = '';
        let conn = new WebSocket(url);

        let obs = new BehaviorSubject(null);
        let kill = () => {
            obs = null;
            conn.close();
        }
        let send = (todo, input, token = '1111111') => {
            return new Promise((res2, rej2) => {
                let json = { todo, input, token, wstoken, hostname, origin }
                if (conn.readyState === 1) {
                    conn.send(JSON.stringify(json))
                    Resolve(true);
                } else {
                    console.log('!!! WS ERR: connection is lost. Message was NOT sent !!!')
                    Reject(false);
                }
            })
        }

        conn.onopen = () => {
            //Resolve({ obs, kill, send })
        };

        conn.onerror = (err) => {
            Reject(err);
            console.log("*WS got error", err);
        };

        conn.onmessage = (msg) => {
            let back = JSON.parse(msg.data)
            wstoken = back.wstoken;
            if (back.$handshake) {
                Resolve({ obs, kill, send })
            } else {
                //console.log('*WS returned:', back)
                obs.next({ ...back.data, req: back.todo })
            }
        }

    })
}

