import behave from '../meta/h_behaviour'
import { htmlEntities } from '../../../m_common/common/text'
import { handle_js } from './handle_js'
import { h_handle_css } from './handle_css'

export function h_handle_code(c, iw, run) {
    if (!c) return
    if (this.cw[c] === undefined) return
    if (!run && this.cw[c].e !== 'html') { this.cv[c] = ''; return }
    if (this.cv[c]) return

    const errFx = (e) => { try { iw.__$test.addError(e) } catch (ee) { } }
    let cw = { ...this.cw[c], c }
    let code0 = cw.v

    try {
        switch (this.cw[c].e) {
            case 'jsx': this.cv[c] = h_handle_jsx(code0, cw, iw, errFx); break;
            case 'jsv': this.cv[c] = h_handle_jsv(code0, cw, iw, errFx); break;
            case 'js': this.cv[c] = h_handle_js(code0, cw, iw, errFx); break;
            case 'html': this.cv[c] = h_handle_html(code0, cw, errFx); break;
            case 'css': this.cv[c] = h_handle_css(code0, cw, iw, errFx); break;
            default: this.cv[c] = code0; break;
        }
    } catch (e) { errFx(e) }

}


export function h_merge_code(inputs) {
    const nl = String.fromCharCode(10);
    let outputs = []
    let jscode = ''
    let pycode = ''
    inputs.forEach(i => {
        if (isJs(i.e)) { jscode += this.cv[i.c] + ";"; }
        else if (isPy(i.e)) { pycode += this.cv[i.c] + nl }
        else outputs.push({ ...i, v: this.cv[i.c] })
    })
    if (pycode) outputs.push({ e: 'py', v: pycode, c: 'pybundle-from-cw-s' })
    outputs.push({ e: 'js', v: jscode, c: 'jsbundle-from-cw-s' })
    return outputs
}

export function isJs(v) { return ['js', 'jsx', 'jsv'].includes(v) }
export function isPy(v) { return ['py'].includes(v) }


const errWord = (r, t) => { return `Can't use reserved word '${htmlEntities(r)}' in ${t}!` }

const h_handle_jsx = (code, cw, iw, errFx) => {
    return h_handle_js(iw.Babel.transform(code, { presets: ['react'] }).code, cw, errFx)
}
const h_handle_jsv = (code, cw, iw, errFx) => {
    return h_handle_js(code, cw, iw, errFx)
}

const h_handle_js = (code, cw, iw, errFx) => {
    let t = cw.t || (cw.c + '.' + cw.e)
    let err = ''

    const rws = ['eval', 'window XX', '.append XX', '.appendChild', '.cloneNode', '.insertBefore XX', '.nodeValue', 'XMLHttpRequest']
    if (behave.danger(cw, false)) rws.forEach(r => { if (code.includes(r)) err = errWord(r, t) })

    let c2 = handle_js(code, cw, iw)

    if (err) { errFx(err); return '' }
    return c2 + " ; \n"
}


const h_handle_html = (code, cw, errFx) => {
    let t = cw.t || (cw.c + '.' + cw.e)
    let err = ''

    const rws = ['<script XXX>', '<style XXX >']
    if (behave.danger(cw, false)) rws.forEach(r => { if (code.includes(r)) err = errWord(r, t) })

    if (err) { errFx(err); return '' }
    return code
}





