import React from 'react'
import Course from "../../m_item/client_course_sum/a_course_sum"
import NewPage from '../../m_common/client_jsx/new_page'

export default (props) => {
    return <>
        <NewPage />
        <Course />
    </>
}
