import React from 'react'
import Chapters from './cs_content_chapters'
import Tasks from './cs_content_tasks'

export default function Chapter(props) {
    let { id, level, course_item, course_temp } = props
    return <>
        <ChapterData
            id={id}
            level={level}
            course_item={course_item}
            course_temp={course_temp}
        />
        <Chapters
            parent={id}
            level={level + 1}
            course_item={course_item}
            course_temp={course_temp}
        />
        <Tasks
            parent={id}
            course_item={course_item}
            course_temp={course_temp}
        />
    </>
}

function ChapterData(props) {
    let { id, level, course_item, course_temp } = props
    let chapter = course_temp?.cache?.group[id]
    return <>
        <div className={'row course_element chapter_data level' + level} style={{ backgroundColor: course_item.overview.color }}>
            <span className='element_icon far fa-folder-open'></span>
            <span className='element_name'>{chapter.name}</span>
        </div>
    </>
}