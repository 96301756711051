export let packages={
	
    subscription1m:    {
		role:'study1',
		object:0,
		duration:31,durationm:1,
		canbuy:true,
		price:3929,vat:0.27,gprice:4990,
		//price:4717,vat:0.27,gprice:5990,
		//price:2000,vat:0.0,gprice:2000,
		currency:"HUF",
		name:'Havi előfizetés',
		name2:'Codeman.hu havi elofizetes',
		desc:'Havi előfizetés',
		desc2:'Codeman.hu havi elofizetes',
		pros:[
			"31 nap",
			"minden online tananyag",
			"azonnal, bárhonnan",
		],
		cons:[],
	},


	subscription3m:{
		role:'study1',
		object:0,
		duration:91,durationm:3,
		canbuy:true,
		price:10228,vat:0.27,gprice:12990,
		//price:11803,vat:0.27,gprice:14990,
		//price:2100,vat:0.0,gprice:2100,
		currency:"HUF",
		name:'Negyedéves előfizetés',
		name2:'Codeman.hu negyedeves elofizetes',
		desc:'Negyedéves előfizetés',
		desc2:'Codeman.hu negyedeves elofizetes',
		pros:[
			"3 hónap",
			"minden online tananyag",
			"azonnal, bárhonnan",
		],
		cons:[],

	},
		
    /*subscription6m:{
		role:'study1',
		object:0,
		duration:91,durationm:3,
		canbuy:true,
		price:10228,vat:0.27,gprice:12990,
		//price:300,vat:0.0,gprice:300,
		currency:"HUF",
		name:'Féléves előfizetés',
		name2:'Codeman.hu feleves elofizetes',
		desc:'Féléves előfizetés',
		desc2:'Codeman.hu feleves elofizetes',
		pros:[
			"6 hónap",
			"minden online tananyag",
			"azonnal, bárhonnan",
		],
		cons:[],

	},*/

};