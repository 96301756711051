import React from "react"
import Messages from '../m_common/client_messages/messages.component'
import AllowCookies from '../m_common/client_jsx/cookie.allow'
import { useSelector } from 'react-redux'

import Landing from '../c_pages'

import Contact from '../c_pages/kapcsolat'
import Info from '../c_pages/info'
import Shop from '../c_pages/elofizetes'
import Demo from '../c_pages/demo'

import UserPage from '../m_user/client'

import CoursesPage from '../c_pages/kurzusok/courses_main'
import CoursePage from '../c_pages/kurzusok/course_main'
import CourseTaskPage from '../c_pages/kurzusok/course_task'

import ChallengesPage from '../c_pages/kihivas/challenges_main'
import ChallengeTaskPage from '../c_pages/kihivas/challenge_task'

import { common_menu_open_selector } from "../m_common/client_redux/common.selectors"


export default (props) => {
    const menu_open = useSelector(common_menu_open_selector)
    return <>
        <div className={'App-content' + (menu_open ? " menu_open" : "")}>
            <AppContentOne page={props.page} />
        </div>
        <Messages />
        <AllowCookies />
    </>
}


const AppContentOne = (props) => {
    switch (props.page) {
        case 'landing': return <Landing />
        case 'info': return <Info />
        case 'contact': return <Contact />
        case 'shop': return <Shop />
        case 'user': return <UserPage />

        case 'courses': return <CoursesPage />
        case 'course': return <CoursePage />
        case 'coursetask': return <CourseTaskPage />

        case 'challenges': return <ChallengesPage />
        case 'challenge': return <ChallengeTaskPage />

        case 'demo': return <Demo />
        //case 'exercise': return <OneTaskPage />        
        //case 'test': return null
        default: return null
    }
}