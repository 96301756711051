import React from 'react'
import parse from 'html-react-parser';
import { SetText, SetSelect } from '../../../m_common/client_jsx/io_helpers'
import { cw_del, cw_move, fullW, refresh, reload, change_to_parent, resetOne } from '../tech/events-cw'

import { codebox_extensions, codebox_styles, codebox_behaviour } from '../meta/h_metadata'
import behave from '../meta/h_behaviour'


export function CodeWindow(e, mode) {
    let show_value = (e.b === 'r')
    let show_admin = (!!this.admin)
    let show_code = (e.b !== 'r')
    let size = !this.state.fullW ? e.s : (this.state.fullW === e.c ? 'full ' : 'hide ')
    let see = behave.see(e, this.props.solution)
    let edit = behave.edit(e, this.props.solution)

    return (!this.admin && !see) ? '' :
        <div
            className={'codebox ' + size + " " + e.l + (mode ? ' edit' : '')}
        >

            <div className='codebox-header'>
                <div className='left'>
                    <div className='left'>
                        <span className={'pointer p5 cl_red' + (e.b === 'l' ? ' op7' : ' nodisplay')}><span className='fas fa-lock'></span></span>
                        <span className={'pointer p5 cl_green' + (e.b === 'x' ? ' op7x' : ' nodisplay')}><span className='fas fa-terminal'></span></span>
                        <span className={'pointer p5 cl_green' + (e.b === 'a' ? ' op7x' : ' nodisplay')}><span className='fas fa-terminal'></span></span>
                        <span>{parse(e.t || (e.c + '.' + e.e))}</span>
                        {this.admin && e.t ? '*' : ''}
                    </div>
                </div>

                <div className='right'>
                    {show_admin ?
                        <>
                            <span className='pointer p5 cl_redx op7 ophover1 hoverop1' onClick={() => {
                                this.cm[e.c] = 1 - mode;
                                refresh.bind(this)()
                            }}>
                                <span className={codebox_behaviour[e.b].a_icon}></span></span>

                            <span className='pointer p5 op7 ophover1 hoverop1' onClick={() => cw_move.bind(this)(e.c, -1)}>
                                <span className='fas fa-chevron-up'></span></span>
                            <span className='pointer p5 op7 ophover1 hoverop1' onClick={() => cw_move.bind(this)(e.c, 1)}>
                                <span className='fas fa-chevron-down'></span></span>

                            <span className='pointer p5 cl_red op7 ophover1 hoverop1' onClick={() => cw_del.bind(this)(e.c)}>
                                <span className='fas fa-trash'></span></span>
                        </>
                        :
                        <>
                            {!edit ? '' :
                                <span className='pointer p5 op7 ophover1 hoverop1' onClick={() => resetOne.bind(this)(e.c)}>
                                    <span className='fas fa-step-backward'></span></span>
                            }
                            {e.b !== "r" ? '' :
                                <span className='pointer p5 op7 ophover1 hoverop1' onClick={() => reload.bind(this)()}>
                                    <span className='fas fa-sync-alt'></span></span>
                            }

                            {this.state.fullW === e.c ?
                                <span className='pointer p5 op7 ophover1 hoverop1 nomobile' onClick={() => fullW.bind(this)(null)}>
                                    <span className='far fa-window-minimize'></span></span>
                                :
                                <span className='pointer p5 op7 ophover1 hoverop1 nomobile' onClick={() => fullW.bind(this)(e.c)}>
                                    <span className='far fa-window-maximize'></span></span>
                            }

                        </>
                    }
                </div>


            </div>



            {!show_code ? "" : <div className={'codebox-content' + (!mode ? '' : ' nodisplay')} id={'cw_id_' + e.c}></div>}
            {!show_value ? "" : <div className={'codebox-content' + (!mode ? '' : ' nodisplay')}>{parse(e.v)}</div>}

            {!show_admin ? '' : <>
                <div className={'codebox-content bg_code' + (mode ? '' : ' nodisplay')}>
                    <div className='flexcontainer'>
                        <div className=''>
                            <div className='row text-center'>
                                {['a', 'h', 'l', 'n', 'x', 's', 'r'].map(v => {
                                    return <span key={v} className={'pointer p5' + (e.b === v ? ' cl_accent2' : ' op2')} onClick={() => { this.cw[e.c].b = v; change_to_parent.bind(this)() }}>
                                        <span className={codebox_behaviour[v].a_icon}></span></span>
                                })}
                            </div>
                            <div className='row mtop20 text-center'>

                                <SetText value={e.c} class='small flexcontainer' name='code' disabled onChange={v => { }} />
                                <SetSelect name='extension' class='small flexcontainer'
                                    value={e.e}
                                    options={Object.keys(codebox_extensions)}
                                    onChange={v => { this.cw[e.c].e = v; change_to_parent.bind(this)() }}
                                />
                                <SetText value={e.t} class='small flexcontainer' name='text' onChange={v => { this.cw[e.c].t = v; change_to_parent.bind(this)() }} />
                                <SetText value={e.l} class='small flexcontainer' name='class' onChange={v => { this.cw[e.c].l = v; change_to_parent.bind(this)() }} />
                                <SetSelect name='style' class='small flexcontainer'
                                    value={e.s}
                                    options={codebox_styles}
                                    onChange={v => { this.cw[e.c].s = v; change_to_parent.bind(this)() }}
                                />

                            </div>
                            <div className='row mtop20 text-center'>
                                <button className='button bt_accent' onClick={() => {
                                    this.cm[e.c] = 1 - mode;
                                    refresh.bind(this)()
                                }}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>}

        </div>
}
