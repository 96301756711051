import React from 'react'
import ContentRep from '../../m_common/client_text/content.rep'

export default function SecDesc(props) {
    let { course_item } = props
    let { overview, course } = course_item
    return <>
        <div className='row course_section'>
            <div className='course_section_header' style={{ color: overview.color }}>Tartalom</div>
            <div className='row m20_0'>
                <ContentRep content={course.longdesc} refresh={1} />
            </div>
        </div>
    </>
}

