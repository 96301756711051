import React from 'react'
import { useSelector } from 'react-redux'
import { content_item_temp, content_solution_lists } from '../client_redux/content.selectors'
import Progress from './cs_content_progress'


export default function CourseContent(props) {
    let { course_item } = props
    const course_temp = useSelector(store => content_item_temp(store, course_item?.id))

    return !course_temp
        ? null
        : <InnerPage course_item={course_item} course_temp={course_temp} />
}

function InnerPage(props) {
    let { course_item, course_temp } = props
    const solutions_list = useSelector(store => content_solution_lists(store, course_item?.id))
    
    return <>
        <div className='row course_section'>
            <div className='course_section_header' style={{ color: course_item.overview.color }}>Előrehaladás</div>
            <div className='row m20_0'>
                <Progress
                    solutions_list={solutions_list}
                    course_item={course_item}
                    course_temp={course_temp}
                />
            </div>
        </div>
    </>

}