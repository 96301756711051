import React from 'react'
import { useState } from 'react'
import { changepass_action } from './pure.auth.actions'
import { valPW, valPW2 } from '../../m_common/client/h_validate'
import { useSelector, useDispatch } from 'react-redux'
import { auth_selector } from './store.auth.selectors'

export default (props) => {
    const [password, setpw] = useState('')
    const [password2, setpw2] = useState('')
    const [password3, setpw3] = useState('')
    const [err1, seterr1] = useState('')
    const [err2, seterr2] = useState('')
    const [err3, seterr3] = useState('')
    const authdata = useSelector(auth_selector)
    const dispatch = useDispatch()

    let pending = false;
    const send = (password, newpassword) => {
        pending = true;
        let input = { password, newpassword, email: authdata.email }
        changepass_action(input, dispatch)
            .then(x => {
                props.onAction({ what: 'changepass', val: 1 })
                pending = false;
            })
            .catch(e => {
                if (e === "err_wrongpass") seterr1("* hibás jelszó")
                pending = false;
            })
    }

    const validateAll = () => {
        let pw1Ok = valPW.bind(this)(password, seterr1)
        let pw2Ok = valPW.bind(this)(password2, seterr2)
        let pw3Ok = valPW2.bind(this)(password2, password3, seterr3)
        if (password === password2) {
            pw2Ok = false;
            seterr2("Nem változott!")
        }
        return pw1Ok && pw2Ok && pw3Ok
    }
    const submit = (e) => { e.preventDefault(); if (validateAll()) if (!pending) send(password, password2) }

    return <>
        <div className='mw500x'>
            <div className={'row accentbox text-center ' + props.class}>
                {props.nohead ? '' :
                    <div className='row'>
                        <h4>Jelszó megváltoztatása</h4>
                        <div className='mtop10'></div>
                    </div>
                }
                <div className='row'>
                    <form onSubmit={e => submit(e)}>

                        <div className='row mtop10 nodisplay'>
                            <div className='inputrow mw200'>
                                <input className='bt_trans_bo_white text-center mw200 text-ls1' type='email' placeholder='- email -' value={authdata.email} onChange={() => { }} disabled></input>
                                <div className='inputerror'>{null}</div>
                            </div>
                        </div>
                        <div className='row mtop10 '>
                            <div className='inputrow mw200'>
                                <input className='bt_trans_bo_white text-center mw200 text-ls1' type='password' placeholder='- aktuális jelszó -' onChange={e => setpw(e.target.value)}></input>
                                <div className='inputerror'>{err1}</div>
                            </div>
                        </div>

                        <div className='row mtop30'>
                            <div className='inputrow mw200'>
                                <input className='bt_trans_bo_white text-center mw200 text-ls1' type='password' placeholder='- új jelszó -' onChange={e => setpw2(e.target.value)}></input>
                                <div className='inputerror'>{err2}</div>
                            </div>
                        </div>
                        <div className='row mtop10 '>
                            <div className='inputrow mw200'>
                                <input className='bt_trans_bo_white text-center mw200 text-ls1' type='password' placeholder='- új jelszó újra -' onChange={e => setpw3(e.target.value)}></input>
                                <div className='inputerror'>{err3}</div>
                            </div>
                        </div>
                        <div className='row mtop10'>
                            <button className='button big bt_trans_bo_white'>Elküld</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}