export let role = {
    task: { icon: 'far fa-file-code' },
    article: { icon: 'far fa-newspaper' },
    course: { icon: 'fas fa-chalkboard-teacher' },
    certificate: { icon: 'fas fa-graduation-cap' },
}

export let mode = {
    available: { active: 1, a_color: 'cl_green', u_color: "cl_green", icon: 'fas fa-play', cant_message: '' },
    draft: { active: 0, a_color: 'cl_grey_l', u_color: "cl_grey_l", icon: '', cant_message: '' },
    invisible: { active: 0, a_color: 'cl_grey_l', u_color: "cl_grey_l", icon: '', cant_message: '' },
    developing: { active: 1, a_color: 'cl_red', u_color: "cl_grey_l", icon: 'fas fa-wrench', cant_message: 'info_ui_course_developing' },
    planned: { active: 1, a_color: 'cl_red', u_color: "cl_grey_l", icon: 'fas fa-ruler-combined', cant_message: 'info_ui_course_developing' },
    locked: { active: 1, a_color: 'cl_red', u_color: "cl_grey_l", icon: 'fas fa-lock', cant_message: 'info_ui_course_locked' },
    pack: { active: 1, a_color: 'cl_gold', u_color: "cl_orange", icon: 'fas fa-lock', cant_message: 'warning_ui_course_no_subscription' },
    role: { active: 1, a_color: 'cl_gold', u_color: "cl_orange", icon: 'fas fa-lock', cant_message: 'warning_ui_course_no_subscription' },
    priv: { active: 1, a_color: 'cl_gold', u_color: "cl_orange", icon: 'fas fa-lock', cant_message: 'warning_ui_course_no_subscription' },
    fixed: { active: 0, a_color: '', u_color: "", icon: '' }
}

export let goal = {
    todo: { bar_text: 'Végezd el a leckét!' },
    quiz: { bar_text: 'Válaszold meg a tesztkérdéseket!' },
    code: { bar_text: 'Írj hibátlan kódot!' }
}

export let purpose = {
    lesson: { point: 0, type: 1, group: 'lesson' },
    lessonex: { point: 0, type: 2, group: 'lesson' },
    exercise: { point: 0, type: 3 , group: 'lesson' },
    //practice: { point: 0, type: 4 },
    //repeat: { point: 0, type: 5 },
    //test: { point: 0, type: 6 },
    //exam: { point: 0, type: 7 },
    challenge: { point: 10, type: 10, group: 'challenge' },
    challenge1w: { point: 0, type: 11, group: 'challenge' },
    challenge2w: { point: 0, type: 12, group: 'challenge' },
    challenge9w: { point: 0, type: 13, group: 'challenge' },
    //interview: { point: 0, type: 1 },
}

export let technology = {
    iframe: {},
    iframe2: {},
}

export let info = [
    "closed",
    "test1",
    "test2",
    "console1",
    "console2",
    "split"
]

export let depend_pack = {
    no: {},
    publicprofile: {},

}

export let depend_role = {
    no: {},
    study1: {},
    study2: {},
    study3: {},
}

export let depend_priv = {
    no: {},
    course1: {},
    course2: {},
    course3: {},
}