import React, { useEffect, useState } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { content_item_bycode } from '../client_redux/content.selectors'
import { item_get_bycode } from '../client_redux/content.actions'

import can_open from '../meta/can_open'
import Loading from '../../m_common/client_jsx/loading_page'
import './a_course_sum.scss'

import SecAttributes from './sec_attributes'
import SecDesc from './sec_desc'
import SecProgress from './sec_progress'
import SecItems from './sec_items'
import GetData from './sec_get_data'

export default () => {
    const { course_code } = useParams()
    const dispatch = useDispatch()
    const course_item = useSelector(store => content_item_bycode(store, course_code))

    useEffect(() => {
        document.title = course_item?.overview?.name + " - kurzus - Codeman.hu"
    }, [course_item])

    if (!course_item) item_get_bycode(course_code, dispatch)

    return !course_item
        ? <Loading />
        : <InnerPart course_item={course_item} />
}

function InnerPart(props) {
    const { course_item } = props
    const [redirect, setR] = useState('')
    const courses_page = '/kurzusok'

    if (!can_open(course_item)) if (!redirect) setR(courses_page)

    return redirect
        ? <Redirect to={redirect} />
        : <div className='row fullheight page_light font-main'>
            <Head course_item={course_item} />
            <Body course_item={course_item} />
        </div>
}


function Head(props) {
    const { course_item } = props
    return <>
        <div className='course_head'>
            <img src={course_item.overview.image} alt={course_item.overview.name} />
            <div className='course_image'>
            </div>
            <div className='course_title_container'>
                <div className='mw1000 p30_10'>
                    <div className='course_title'>
                        <span className='course_title_in'>{course_item.overview.name}</span>
                    </div>
                </div>
            </div>
        </div>
    </>
}



function Body(props) {
    const { course_item } = props
    return <>
        <div className='mw1000'>
            <GetData course_item={course_item} />
            <div className={'right p10 col3_1_m1'}><SecProgress course_item={course_item} /></div>
            <div className={'left p10 col3_2_m1 nomobile'}><SecDesc course_item={course_item} /></div>
            <div className={'right p10 col3_1_m1'}><SecAttributes course_item={course_item} /></div>
            <div className={'left p10 col3_2_m1 onmobile'}><SecDesc course_item={course_item} /></div>
            <div className={'left p10 col3_2_m1'}><SecItems course_item={course_item} /></div>
            <div className='row mtop50'></div>
        </div>
    </>
}