export const FacebookChecker = (selector) => {

    const checkDom = (s) => {
        let e = document.querySelector(s)
        return !!e
    }

    const fbRender = (nr = 0, maxnr = 3) => {
        if (nr >= maxnr) return
        try { window.FB.XFBML.parse(); } catch (e) { }
        setTimeout(() => {
            if (!checkDom(selector)) fbRender(nr + 1, maxnr)
        }, 500)
    }

    setTimeout(() => { fbRender(0, 3) }, 500)
}