import React from 'react'
import CourseTask from "../../m_item/client_solve/a_course_task"
import NewPage from '../../m_common/client_jsx/new_page'
import { min_privileges } from '../../config/client.config'

export default () => {
    return <>
        <NewPage />
        <CourseTask privileg={min_privileges.course} />
    </>
}

