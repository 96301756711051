import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import cookie from '../client/cookie'


export default function () {
    let [off, setO] = useState(cookie.allowed())

    let style = {
        position: 'fixed',
        bottom: '0px',
        left: '0px',
        width: '100%',
        fontSize: "16px",
        lineHeight: '1.5em',
        color: "#a25e01",
        backgroundColor: "#f5d2a2",
        zIndex:'20',
        display: (off ? 'none' : 'block')
    }

    const allow = () => {
        cookie.allow()
        setO(true)
    }

    return <>
        <div id='AllowCookiesBox' style={style}>
            <div className='row p20'>
                <div className='row __text text-center'>
                    Honlapunk sütiket (cookie) használ a jobb felhasználói élmény biztosítása érdekében. A böngészés folytatásával hozzájárulsz a sütik használatához.
                </div>

                <div className='row __button mtop10 text-center'>
                    <button className='button bt_red med' onClick={allow}>Elfogadom a sütik használatát!</button>
                </div>

                <div className='row __button mtop10 text-center'>
                    <Link to='/info/adatvedelem'>
                        <button className='op7 hoverop1 pointer'>Adatvédelmi tájékoztató</button>
                    </Link>
                </div>

            </div>
        </div>
    </>
}