import React from 'react'
import { NewsLetter } from '../../m_user/client/w_newsletter'

export default function (props) {

    return <>
        <div className='pagerowx bg_whiteX'>

            <div className='row p50_0'>

                <div className='row text-center p0_10 '>
                    <h3>Hírlevél</h3>
                </div>
                <div className='row text-center op5 p0_10'>
                    <div className='mw600'>
                        <h5>Ha még nem regisztráltál, és szeretnél értesülni az aktualitásokról, iratkozz fel hírlevelünkre!</h5>
                    </div>
                </div>

                <div className='row mtop20'>

                    <div className='mw450_300 user_address_form text-center'>
                        <NewsLetter />
                    </div>
                </div>

            </div>
        </div>
    </>
}

