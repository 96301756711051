import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { user_newsletter_save } from './pure.user.actions'
import ModalGDPR from './wm_gdpr'

export const NewsLetter = () => {
    const [sent, Send] = useState(false)

    return sent
        ? <>
            <div className="text-center cl_accent">
                <span className={"op5"} style={{ fontSize: '30px', lineHeight: "50px" }}>Köszönjük, hogy feliratkoztál a hírlevelünkre!</span>
            </div>
        </>
        : <NotSent send={Send} />


}

const NotSent = (props) => {
    const [email, setE] = useState("")
    const [modalOn, setM] = useState(false)

    const inputStyle = {
        padding: "0px 10px",
        margin: "5px",
        borderRadius: "5px",
        height: "30px",
        lineHeight: "30px",
        minWidth: "250px"
    }
    const dispatch = useDispatch()

    const onSubmit = (event) => {
        event.preventDefault()
        setM(true)
    }
    const onChange = (x) => {
        setE(x.target.value)
    }

    const onModalAction = (act) => {
        if (act === 'no' || act === 'cancel') setM(false)
        if (act === 'ok' || act === 'yes') {
            dispatch(user_newsletter_save(email))
            props.send(true)
        }
    }

    return <>
        <form onSubmit={onSubmit}>
            <input type='email' className='font-main' placeholder='...email' style={inputStyle} value={email} onChange={onChange} />
            <button type='submit' className='button bt_accent med' style={{ margin: "5px" }}>Feliratkozás</button>
            <ModalGDPR on={modalOn} onAction={onModalAction} />
        </form>
    </>
}