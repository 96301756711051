import React from 'react'
import EFrameContent from './ph_content'


export function Article(props) {
    return !props.item.task.article.length
        ? null
        : <EFrameContent title={'Tananyag'} item={props.item} field={'article'} bg={'bg_green_vlx'} />
}

export function EX(props) {
    return !props.item.task.exercise.length ? '' :
        <EFrameContent title={'Feladat'} item={props.item} field={'exercise'} bg={'bg_green_vlx'} />
}


export function Con(props) {
    return !props.item.task.conclusion.length ? '' :
        <EFrameContent title={'Megoldás'} item={props.item} field={'conclusion'} bg={'bg_green_vlx'} />
}

