export const TaskAction = ({ ac, status, item, show, setStatus, onAction }) => {
    let quiz = status.quiz
    let stat = status.stat
    let can_finish = status.can_finish
    let finished = status.finished

    const toPageEnd = () => {
        setTimeout(() => {
            let newlink = document.createElement('a');
            let to = show.conclusion ? 'item_conclusion' : 'task_page_end'
            newlink.setAttribute('href', '#' + to);
            newlink.click();
            newlink.remove();
            //window.scrollTo(0, 3000) 
        }, 500)
    }

    const Action = (act) => {
        if (onAction) onAction(act)
    }

    switch (ac.type) {
        case 'quiz':
            quiz[ac.code] = ac.ok
            stat.task_nr = Object.keys(item.task.quiz).length
            stat.ok_nr = Object.keys(quiz).reduce((r, v, i, a) => { return r + quiz[v] }, 0)
            stat.touch_nr = Object.keys(quiz).length
            try { stat.rate = stat.ok_nr / stat.task_nr } catch (e) { }
            can_finish = (stat.task_nr === stat.touch_nr)
            if (ac.finished) finished = true
            setStatus({ ...status, finished, quiz, can_finish, stat });
            break;
        case 'code':
            if (item.task.settings.set.goal === 'code') can_finish = true && ac.ok
            stat = { ...stat, task_nr: 1, touch_nr: 1, ok_nr: ac.ok ? 1 : 0 }
            try { stat.rate = stat.ok_nr / stat.task_nr } catch (e) { }
            setStatus({ ...status, code_ok: ac.ok, can_finish, stat });
            break;
        case 'solution':
            setStatus({ ...status, finished: true, can_finish: false, showsolution: true, timesec: status.timer.get().sec })
            toPageEnd()
            break;

        case 'exit':
            Action({ type: 'exit', data: null })
            break;
        case 'next':
            Action({ type: 'next', data: null })
            break;

        case 'reset':
            status.timer.continue()
            setStatus({ ...status, reset: status.reset + '1', finished: false, can_finish: false, showsolution: false })
            break;

        case 'finish':
            status.timer.stop();
            let _time = status.timer.get()
            if (!status.can_finish) break;
            if (status.finished) break;
            let newstatus = { ...status, finished: true, can_finish: false, stat: { ...status.stat, timesec: _time.sec, d0: _time.d0, d1: _time.d1 } }
            if (newstatus.stat.task_nr === 0 && newstatus.stat.ok_nr === 0) { newstatus.stat.task_nr = 1; newstatus.stat.ok_nr = 1 }
            setStatus(newstatus);
            toPageEnd()
            Action({ type: 'finish', data: { stat: newstatus.stat } })
            break;
        default: console.log(ac); break;
    }
}