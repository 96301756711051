import VAL from '../common/validations'

export function valEmail(email, fx) {
    let ok = false; let msg = '';
    if (email === '' || email === undefined) msg = '* a mező nem lehet üres'
    else if (!new VAL().is('email', email)) msg = '* az email cím nem megfelelő'
    else ok = true
    fx(msg); return ok;
}
export function valEmail2(email, email2, fx) {
    let ok = false; let msg = '';
    if (email2 === '' || email2 === undefined) msg = '* a mező nem lehet üres'
    else if (email !== email2) msg = '* a két email cím nem egyezik'
    else ok = true
    fx(msg); return ok;
}


export function valPW(pw, fx) {
    let ok = false; let msg = '';
    if (pw === '' || pw === undefined) msg = '* a mező nem lehet üres'
    else if (pw.length < 4) msg = '* túl rövid'
    else if (pw.length > 30) msg = '* túl hosszú'
    else if (!new VAL().is('password', pw)) msg = '* a jelszó nem megfelelő'
    else ok = true
    fx(msg); return ok;
}

export function valPW2(pw, pw2, fx) {
    let ok = false; let msg = '';
    if (pw2 === '' || pw2 === undefined) msg = '* a mező nem lehet üres'
    else if (pw !== pw2) msg = '* a két jelszó nem egyezik'
    else ok = true
    fx(msg); return ok;
}

export function valGen(data, _typ, typhint = "", fx) {
    let ok = true; let msg = '';
    let typ = _typ
    if (!Array.isArray(typ)) typ = [typ]

    typ.forEach(t => {
        if (!new VAL().is(t, data)) ok = false
    })
    if (!ok) msg = '* nem megfelelő kitöltés' + (typhint ? (" ( " + typhint+ " ) " || "") : "")
    fx(msg); return ok;
}