import React from 'react'
import { Link } from 'react-router-dom'
import ActRoles from '../../m_auth/client/rep.auth.roles.act'
import ShopHistory from '../../m_shop/client/w_shop_history'

export default function () {

    return <>
        <div className='noselect'>
            <div className='toload2 row m50_0'>
                <ActRolesOut />
            </div>
        </div>
    </>
}


function ActRolesOut(props) {
    return <>
        <div className='row text-center'>
            <h4>Aktív csomagok</h4>
        </div>
        <div className='row m10_0'>
            <div className='mw600 '>
                <ActRoles nohead={true} class={'p0'} />
            </div>
        </div>

        <div className='row text-center mtop30'>
            <Link to='/arak'>
                <button className='button bt_accent med'>
                    <span className='fas fa-shopping-cart mright8'></span>
                    <span>Új előfizetés vásárlása</span>
                </button>
            </Link>
        </div>

        <div className='row text-center mtop50'>
            <h4>Korábbi vásárlások</h4>
        </div>
        <div className='row m10_0'>
            <div className='mw600 '>
                <ShopHistory nohead={true} />
            </div>
        </div>

    </>
}

