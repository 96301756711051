import React from 'react'

export function CwHead({ state, activate, cw }) {
    const { toShow, active } = state
    return <div id='iframe2_cws_head'>
        {
            toShow.map(c =>
                <div
                    key={c}
                    className={'headeritem ' + (active === c ? 'active' : 'inactive')}
                    onClick={() => { activate(c) }}
                >
                    <span className={'hicon cl_red' + (cw[c].b === 'l' ? ' op7' : ' nodisplay')}><span className='fas fa-lock'></span></span>
                    <span className={'hicon cl_green' + (cw[c].b === 'x' ? ' op7x' : ' nodisplay')}><span className='fas fa-code'></span></span>
                    <span className={'hicon cl_green' + (cw[c].b === 'a' ? ' op7x' : ' nodisplay')}><span className='fas fa-code'></span></span>
                    <span>{cw[c].t || (c + '.' + cw[c].e)}</span>
                </div>
            )
        }
    </div>
}