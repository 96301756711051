import React from 'react'
import { verify_action } from './pure.auth.actions'
import { useDispatch } from 'react-redux'

export default function (props) {
    const dispatch = useDispatch()

    let { veriftoken } = props

    const send = () => {
        let input = { veriftoken }
        verify_action(input, dispatch)
            .then(x => {
                props.onAction({ what: 'verif', val: 1 })
            })
            .catch(e => {
                props.onAction({ what: 'verif', val: 0 })
            })
    }

    send()

    return <>
    </>
}