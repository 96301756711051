import React from 'react'
import { useSelector } from 'react-redux'
import { content_item_temp } from '../client_redux/content.selectors'

import Chapters from './cs_content_chapters'

export default function CourseContent(props) {
    let { course_item } = props
    const course_temp = useSelector(store => content_item_temp(store, course_item?.id))

    return !course_temp
        ? null
        : <InnerPage course_item={course_item} course_temp={course_temp} />
}


function InnerPage(props) {
    let { course_item, course_temp } = props

    return <>
        <div className='row course_section'>
            <div className='course_section_header' style={{ color: course_item.overview.color }}>Leckék</div>
            <div className='row m20_0'>
                <Chapters
                    parent={course_item.group_oid}
                    course_item={course_item}
                    course_temp={course_temp}
                ></Chapters>
            </div>
        </div>
    </>

}