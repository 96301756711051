import React from "react";

import { INIT, ON_RESET, ON_FINISH } from './events'
import { TechnologyWindow } from './tech/tech-window'
import { filter_cws, choose_tech } from './tech/tech-fxs'
import Loading from '../../m_common/client_jsx/loading_page'
import "./editor.sboxes.scss"
import "./editor.styles.scss"

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = { inited: false, count: 1, fullw: null, loaded: false };
        this.cwconfig = { numbers: true };

        this.technology = choose_tech(this.props)
        this.cw = filter_cws({ technology: this.technology, cw: this.props.data.cw })

        this.cw_orig = JSON.parse(JSON.stringify(this.cw))
        this.iframe = {
            ...this.props.data.iframe,
            ...this.props.data.iframedata
        }
        this.cv = {}    // codewindow value2
        this.cm = {}    // codewindow mode

        this.info = this.iframe.info || {}

        this.timer = null;
        this.lastcw = null;
        this.cws = {}
        this.admin = this.props.admin || false
        this.finished = true && this.props.finished
        this.resetc = '' + this.props.reset

        this.iw_objects = [];
        this.iw_py_objects = null;
    }

    setS(obj) {
        this.setState({ ...this.state, ...obj })
    }

    render() {
        if (this.resetc !== '' + this.props.reset) ON_RESET.bind(this)()
        else if (this.finished !== this.props.finished) ON_FINISH.bind(this)()

        return <>
            {TechnologyWindow.bind(this)(this.technology, this.cw, this.info)}
            {!this.state.loaded
                ? <LoadingWindow />
                : null
            }
        </>
    }

    componentDidMount() {
        INIT.bind(this)()
    }

}

function LoadingWindow() {
    return <div id="editor_loading">
        <Loading />
    </div>
}