import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { content_item_temp, content_solution_lists } from '../client_redux/content.selectors'
import { item_temp_get, solution_get_more } from '../client_redux/content.actions'
import { privileges_selector } from '../../m_auth/client/store.auth.selectors'


export default function CourseContent(props) {
    let { course_item } = props
    const dispatch = useDispatch()
    const course_temp = useSelector(store => content_item_temp(store, course_item?.id))

    if (!course_temp) item_temp_get(course_item?.id, dispatch)

    return !course_temp
        ? null
        : <InnerPage course_item={course_item} course_temp={course_temp}/>
}


function InnerPage(props) {
    let { course_item, course_temp } = props
    let item_ids = course_temp?.cache?.items || []

    const dispatch = useDispatch()
    const privileges = useSelector(privileges_selector)
    const solutions_list = useSelector(store => content_solution_lists(store, course_item?.id))

    const isUser = () => { return privileges ? !!privileges.loggedin : false }

    if (!solutions_list) {
        if (item_ids.length) if (isUser()) solution_get_more({ item_ids, item_id: course_item?.id }, dispatch)
    }

    return <></>

}