export function json_move(json, code, direction) {
    let keys = Object.keys(json)
    let n = keys.indexOf(code)
    let m = keys.length;
    let nn = n + direction
    if (nn < 0 || nn >= m) return json
    let pair = direction === -1 ? [keys[n - 1], code] : [code, keys[n + 1]]

    let json2 = {}
    for (let i in json) {
        if (i === pair[0]) { }
        else {
            json2[i] = json[i]
            if (i === pair[1]) json2[pair[0]] = json[pair[0]]
        }
    }
    return json2
}