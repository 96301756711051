import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { privileges_selector, attempt_selector } from './store.auth.selectors'
import AuthBoxes from './auth.boxes'

export function IfHasPriv({ redirect, login, priv, subject, object, children }) {

    const privileges = useSelector(privileges_selector) || {}
    const attempt = useSelector(attempt_selector) || false

    const no = () => {
        return (redirect && attempt) ? <Redirect to={redirect}></Redirect>
            :
            (!privileges['loggedin'] && login) ?
                <>
                    <div className='fullheight_flex noselect'>
                        <div className='toload2'>
                            <AuthBoxes />
                        </div>
                    </div>
                </>
                :
                null
    }

    let ok = true
    if (priv) {
        if (!privileges[priv]) ok = false;
        else {
            if (subject && !privileges[priv][subject]) ok = false;
            else if (object && privileges[priv][subject].includes(object)) ok = false;
        }
    }

    if (ok) return children
    else return no()

}