import React, { useEffect } from 'react'
import { privileges_selector, roles_selector } from '../../../m_auth/client/store.auth.selectors'
import { useSelector, useDispatch } from 'react-redux'
import { content_item_bycode, content_solution } from '../../client_redux/content.selectors'
import { item_get_bycode, solution_save, solution_get } from '../../client_redux/content.actions'
import Task from '../item.task'
import can_open_item from '../../meta/can_open'

export default (props) => {
    const { task_code, parent_item, next_button, to_save } = props
    const dispatch = useDispatch()

    const privileges = useSelector(privileges_selector)
    const roles = useSelector(roles_selector)
    const task_item = useSelector(store => content_item_bycode(store, task_code))
    const task_solution = useSelector(store => content_solution(store, task_item?.id))

    const isUser = () => { return privileges ? !!privileges.loggedin : false }
    const canRender = () => { return !!task_item }
    const Action = (act) => { if (props.onAction) props.onAction(act) }
    const onAction = (act) => {
        switch (act.type) {
            case "exit":
                Action({ type: "exit", data: { item_id: task_item.id } })
                break;
            case "next":
                Action({ type: "next", data: { item_id: task_item.id } })
                break;

            case "finish":
                let can_save = to_save && isUser()
                let purpose = ""
                //try { can_save = can_save && task_item.task.settings.proc.save_can } catch (e) { }
                try { purpose = task_item.task.settings.set.purpose || "" } catch (e) { }

                if (can_save)
                    solution_save({
                        item_id: task_item.id,
                        data: { ...act.data.stat, role: "task", solved: 1, purpose, d0: act.data.stat.d0.getTime() }
                    }, dispatch)
                        .then(x => {
                            Action({ type: "finish", data: { solved: 1, task_id: task_item.id } })
                        })
                        .catch(e => {
                            Action({ type: "finish", data: { solved: 0, task_id: task_item.id } })
                        })
                else {
                    Action({ type: "finish", data: { solved: 0, task_id: task_item.id } })
                }
                break;

            default: break;
        }
    }

    let can_open = false
    if (task_code) {
        if (!task_item) {
            item_get_bycode(task_code, dispatch);
        } else {
            can_open = can_open_item(task_item, roles, privileges, parent_item)
            if (!can_open) {
                Action({ type: 'error', msg: "cant open" })
            } else {
                if (!task_solution) if (isUser()) {
                    solution_get(task_item?.id, dispatch)
                }
            }
        }
    } else {
        Action({ type: 'error', msg: "no task_code" })
    }

    useEffect(() => {
        document.title = task_item?.overview?.name + (parent_item ? ` (${parent_item?.overview?.name})` : "")
    })




    return !canRender() ?
        null
        :
        !can_open ?
            <>
                <div className='fullheight_flex'>
                    <div>
                        <div className='row'>
                            <h4>Ez a feladat jelenleg nem elérhető számodra!</h4>
                        </div>
                        <div className='row'></div>
                    </div>
                </div>
            </>
            :
            <>

                <div className='editor_panel_open miw1000x'>
                    <Task
                        item={task_item}
                        solution={task_solution}
                        onAction={onAction}
                        next_button={next_button}
                    />
                </div>
            </>
}