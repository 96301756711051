import React from 'react'
import { SetText, SetSelect } from '../../../m_common/client_jsx/io_helpers'

import { renderCodeMirrors } from "../tech/render-cws";
import { codebox_styles, codebox_extensions } from '../meta/h_metadata'


export function NewCodeWindow() {

    var values = {
        b: 'a',
        c: 'code99',
        t: '',
        e: 'js',
        s: '',
        v: ''
    }

    const ch = (val, what) => {
        values[what] = '' + val
    }

    const sub = () => {
        if (this.cw['' + values.c]) {
            values.c += '_2';
            sub()
        } else {
            this.cw['' + values.c] = { ...values }
            this.change_to_parent.bind(this)()
            renderCodeMirrors.bind(this)()
        }
    }

    return !this.admin ? '' :
        <div className='codebox s1x1 '>
            <div className='codebox-header'>
                Új CodeWindow
            </div>
            <div className='codebox-content bg_code'>
                <div className='row flexcontainer'>
                    <div className='row'>

                        <div className='mw300'>

                            <div className='row mtop20'>
                                <SetText value={values.c} class='small flexcontainerX' name='code' onChange={v => ch(v, 'c')} />
                                <SetSelect name='extension' class='small flexcontainerX'
                                    value={values.e}
                                    options={Object.keys(codebox_extensions)}
                                    onChange={v => ch(v, 'e')}
                                />

                                <SetText value={values.t} class='small flexcontainerX' name='text' onChange={v => ch(v, 't')} />
                                <SetText value={values.l} class='small flexcontainerX' name='class' onChange={v => ch(v, 'l')} />

                                <SetSelect name='style' class='small flexcontainerX'
                                    value={values.s}
                                    options={codebox_styles}
                                    onChange={v => ch(v, 's')}
                                />
                            </div>
                            <div className='row mtop20 text-center'>
                                <button className='button bt_accent' onClick={sub}>Hozzáad</button>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div >
}

