import React from 'react'

export default function () {
    return <>
        <div className='loading' style={{
            "width": "100%",
            "height": "100%",
            "display": "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100px",
            opacity:0.5
        }}>
            <i className='fas fa-spinner fa-spin fa-2x'></i>
        </div>
    </>
}