import { t_frame2_exclude } from "../meta/t_f2_exclude"

export function choose_tech({ data, admin }) {
    const { technology } = data
    if (technology === "iframe2") if (admin) return "iframe"
    return technology
}


export function filter_cws({ technology, cw }) {
    let c2 = { ...cw }
    if (technology === 'iframe2') {
        t_frame2_exclude.forEach(key=>{
            delete c2[key]
        })
    }
    return c2
}