import { json_move } from '../../../m_common/common/json.order'
import { CW_ONCHANGE } from '../events'

import behave from '../meta/h_behaviour'

export function cw_del(code) {
    delete this.cw[code]
    change_to_parent.bind(this)()
}

export function cw_move(code, direction) {
    this.cw = json_move(this.cw, code, direction)
    change_to_parent.bind(this)()
}

export function fullW(c) {
    this.setState({ fullW: c })
}
export function refresh() {
    this.setState({ count: 1 + this.state.count })
}
export function reload() {
    this.init_iframe.bind(this)()
}

export function change_to_parent() {
    CW_ONCHANGE.bind(this)()
}

export function resetOne(i) {
    this.cw[i] = JSON.parse(JSON.stringify(this.cw_orig[i]))
    delete this.cv[i]
    this.cws[i].doc.setValue(this.cw[i].v)
    if (behave.edit(this.cw[i], this.props.solution)) this.cws[i].setOption('readOnly', null)
}