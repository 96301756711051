export default function (startnow = false) {

    var d0 = 0
    var d1 = 0
    var sec = 0
    var timer = null

    const _settime = () => {
        d1 = new Date()
        sec = parseInt((d1.getTime() - d0.getTime()) / 1000);
    }
    const _start = (restart = false) => {
        if (restart) _stop(true)
        if (!d0) d0 = new Date()
        if (timer) return;
        timer = setInterval(() => { _settime(); }, 250)
    }
    const _stop = (toreset = false) => {
        clearInterval(timer); timer = null
        if (toreset) {
            d0 = 0
            d1 = 0
            sec = 0
        }
    }

    const _continue = () => {
        d0 = new Date(new Date().getTime() - sec * 1000)
        _start()
    }

    if (startnow) _start()

    return {
        start() { _start() },
        stop() { _stop() },
        reset() { _stop(true) },
        restart() { _start(true) },
        continue() { _continue() },
        get() {
            return {
                d0,
                d1,
                sec,
                run: !!timer
            }
        }
    }

}