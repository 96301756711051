import React from "react"
import { Link, useParams } from 'react-router-dom'

export default (props) => {
    const params = useParams();
    const { page } = params
    return <>
        <Link to='/info/rolunk'>
            <li className={page === 'rolunk' ? 'active' : ''}>
                <span>Codeman</span>
            </li>
        </Link>
        {/*
        <Link to='/info/fejlesztesek'>
            <li className={page === 'fejlesztesek' ? 'active' : ''}>
                <span>Fejlesztések</span>
            </li>
        </Link>
        */}
        <Link to='/info/aszf'>
            <li className={page === 'aszf' ? 'active' : ''}>
                <span>ASZF</span>
            </li>
        </Link>
        <Link to='/info/adatvedelem'>
            <li className={page === 'adatvedelem' ? 'active' : ''}>
                <span>Adatvédelem</span>
            </li>
        </Link>
    </>
}



