import React, { useState } from "react"
import { Redirect } from "react-router-dom"

import './a_landing.scss'
import NewPage from '../../m_common/client_jsx/new_page'

import RowDemo from './demo'
import RowFirst from './first'
import RowPrices from './prices'
import RowMainF1 from './mainf1'
import RowCourses from './courses'
//import RowPractice from './practice'
import RowNewsLetter from './newsletter'

export default function PageLanding(props) {
    const [redirect, setRed] = useState("")

    return redirect ?
        <>
            <Redirect to={redirect}></Redirect>
        </>
        :
        <>
            <NewPage title="Interaktív programozási tananyagok - Codeman.hu" />
            <div id='landing_page' className='row fullheight page_light font-main noselect  toload2'>
                <RowFirst />
                <RowDemo />
                <RowMainF1 />
                <RowCourses />
                {/*<RowPractice />*/}
                <RowNewsLetter />
                <RowPrices setRed={setRed} />
            </div>
        </>

}


