export const content_item_bycode = (store, code) => store.content.item[code]
export const content_item_overviews = store => store.content.item_overview
export const content_item_overview = (store, id) => store.content.item_overview[id]

export const content_course_list = store => store.content.course_list
export const content_task_list = store => store.content.task_list

export const content_item_temps = (store) => store.content.item_temp
export const content_item_temp = (store, id) => store.content.item_temp[id]

export const content_groups = store => store.content.group
export const content_group = (store, id) => store.content.group?.[id]
export const content_group_items = store => store.content.group_item
export const content_group_item = (store, id) => store.content.group_item?.[id]

export const content_solutions = store => store.content.solution
export const content_solution = (store, id) => store.content.solution[id]
export const content_solution_lists = (store, id) => store.content.solution_lists?.[id]

export const content_solution_courses = store => store.content.solution_course
export const content_solution_course = (store, id) => store.content.solution_course?.[id]
export const content_solution_course_list = store => store.content.solution_course_list
export const content_solution_user = store => store.content.solution_user

export const votes_type_selector = (store, type) => store.content.votes[type]
export const votes_selector = (store, type, id) => store.content.votes[type]?.[id]

