import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import TaskSolve from '../client_task/item.solve'

export function ShowTask(props) {
    const { task_code, to_save, exit_path } = props
    const [redirect, setS] = useState(null)
    const setR = (redirectTo) => { if (!redirect) setS(redirectTo) }

    const onAction = (act) => {
        switch (act.type) {
            case "error":
                setR('/');
                break;
            case "finish":
                setTimeout(() => {
                    setR(exit_path || '/');
                }, 1000)
                break;
            case "exit":
                setR(exit_path || '/');
                break;
            case "next":
            default: break;
        }
    }

    return redirect
        ? <Redirect to={redirect} />
        : <TaskSolve
            task_code={task_code}
            parent_item={null}
            next_button={false}
            to_save={to_save}
            onAction={onAction}
        />

}