import React from 'react'
import ContentRep from '../../../m_common/client_text/content.rep'

export default function TaskArticle(props) {
    const { item } = props
    let field = 'article'

    return <>
        <div className='row content_trans'>
            <div className='mw1000 p10'>
                {!item ? null :
                    <ContentRep content={item.task[field]} refresh={field} />
                }
            </div>
        </div>
    </>
}

