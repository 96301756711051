import React from 'react'
import { privileges_selector } from '../../m_auth/client/store.auth.selectors'
import { useSelector } from 'react-redux'
import Loading from '../../m_common/client_jsx/loading'

import { content_solution_user } from '../client_redux/content.selectors'
import { solution_user_get } from '../client_redux/content.actions'
import { useDispatch } from 'react-redux'

export default () => {

    return <>
        <div className='row course_section flexcontainer'>
            <div className='course_section_header'>
                <InnerPart />
            </div>
        </div>
    </>
}

const InnerPart = () => {
    const privileges = useSelector(privileges_selector)
    const isUser = privileges ? !!privileges.loggedin : false

    return !isUser
        ? <span className='op4'>Jelentkezz be és gyűjts pontokat!</span>
        : <ForUsers />
}

const ForUsers = () => {
    const solutions = useSelector(content_solution_user)
    const dispatch = useDispatch()

    if (!solutions) solution_user_get(dispatch)

    let loaded = !!solutions

    return loaded
        ? <Loaded points={solutions?.point_challenge || 0} />
        : <Loading />
}

const Loaded = (props) => {
    const { points } = props
    return points
        ? <HasPoint points={points} />
        : <NoPoint />
}

const HasPoint = (props) => {
    const { points } = props
    return <>
        <span className='op5'>Kihívásokból szerzett pontjaid:</span>
        <br />
        <span style={{ fontSize: "45px", color: "goldenrod" }}>
            {points}
        </span>

    </>
}

const NoPoint = (props) => {
    return <>
        <span className='op4'>Még nem gyűjtöttél pontokat</span>
    </>
}