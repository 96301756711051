import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'
import { packages } from '../../config/shop.cfg'
import { roles_selector } from '../../m_auth/client/store.auth.selectors'
import time from '../../m_common/common/time'
import { UserBillingAddress } from '../../m_user/client/w_address_billing'
import { shop_buy_package } from './pure.shop.actions'
import Loading from '../../m_common/client_jsx/loading_page'

export default function () {
    const { package_name } = useParams();
    let pack = packages[package_name]
    const [redirect, setre] = useState(pack ? "" : "/elofizetes")
    const [state, setSt] = useState({ page: 2, package_name, address: null })
    const setState = (json) => { setSt({ ...state, ...json }) }
    const nextp = (data = {}) => { let json = { ...data, page: state.page + 1 }; setState(json) }
    const setp = (page) => { let json = { ...state, page }; setState(json) }

    const onAction = (act) => {
        let { address } = act
        if (act.action === 'cancel') setre('/elofizetes')
        else if (act.action === 'err') setp(5)
        else if (act.page === 2 && act.action === 'next') nextp({ address })
        else if (act.page === 3 && act.action === 'next') nextp({ address })
    }

    return redirect ? <Redirect to={redirect}></Redirect> : <>
        <SProcess page={state.page} />
        {
            state.page === 2 ? <P2package pack={pack} onAction={onAction} />
                :
                state.page === 3 ? <P3billing pack={pack} onAction={onAction} />
                    :
                    state.page === 4 ? <P4pay package_name={state.package_name} billing_address={state.address} onAction={onAction} />
                        :
                        state.page === 5 ? <P5Error />
                            :
                            null
        }
    </>
}

function P5Error(props) {
    return <>
        <div className='row mtop100 text-center'>
            <h4>A fizetés jelenleg nem lehetséges!</h4>
        </div>
    </>
}


function P4pay(props) {
    const { package_name, billing_address } = props;
    const [redirect, setRe] = useState('')
    const [sent, setS] = useState(false)
    const dispatch = useDispatch()

    if (!sent) {
        shop_buy_package({ package_name, billing_address }, dispatch)
            .then(x => {
                if (x.data.status === 'ERR') props.onAction({ action: "err" })
                else if (x.data.payurl) window.location.replace(x.data.payurl)
                else setRe("/felhasznalo/elofizetes")
            })
            .catch(e => {

            })
        setS(true)
    }

    window.scrollTo(0, 0);
    return redirect ? <Redirect to={redirect}></Redirect> :
        !sent ?
            <div className='m50_0x'>
                <Loading></Loading>
            </div>
            :
            <div className='m50_0x'>
                <Loading></Loading>
            </div>
}

function P3billing(props) {
    const onCancel = () => { props.onAction({ page: 3, action: "cancel" }) }
    const onSubmit = (address) => {
        props.onAction({ page: 3, action: "next", address })
    }
    
    window.scrollTo(0, 0);
    return <>

        <div className='row mtop50 text-center'>
            <h4>Számlázási cím</h4>
        </div>

        <div className='row mtop30'>
            <UserBillingAddress
                onSubmit={onSubmit}
                onCancel={onCancel}
                button_text={'Tovább'}
                cancel_button={true}
            />
        </div>

    </>
}


function P2package(props) {
    let actroles = useSelector(roles_selector) || []
    let { pack } = props
    let actrole = (actroles.filter(a => { return a.role === pack.role && a.object === pack.object }) || [])[0] || null
    
    window.scrollTo(0, 0);
    return <>
        <div className='row mtop50 text-center'>
            <h4>Kiválasztott csomag</h4>
        </div>

        <div className='row mtop30'>
            <table className='table_trans mw600'>
                <tbody>
                    <tr><th>Jellemző</th><th>Tartalma</th></tr>
                    <tr><td>Csomag neve</td><td>{pack.name}</td></tr>
                    <tr><td>Devizanem</td><td>{pack.currency}</td></tr>
                    <tr><td>Bruttó ár</td><td>{pack.gprice}</td></tr>
                    <tr><td>Nettó ár</td><td>{pack.price}</td></tr>
                    <tr><td>Periódus (hónap)</td><td>{pack.durationm}</td></tr>
                    <tr><td>Periódus (nap)</td><td>{pack.duration}</td></tr>
                    <tr><td>Hosszabbítás</td><td>{!!actrole ? 'Igen' : 'Nem'}</td></tr>
                    {actrole ?
                        <>
                            <tr><td>Érvényes csomag kezdete</td><td>{time.dbDate(actrole.valid_from).toLocaleDateString()}</td></tr>
                            <tr><td>Érvényes csomag lejárata</td><td>{time.dbDate(actrole.valid_to).toLocaleDateString()}</td></tr>
                            <tr><td>Lejárat hosszabbítás után</td><td>{time.dbDate(actrole.valid_to, pack.duration).toLocaleDateString()}</td></tr>
                        </>
                        :
                        <>
                            <tr><td>Időpont</td><td>{new Date().toLocaleDateString()}</td></tr>
                            <tr><td>Lejárat</td><td>{time.dbDate(undefined, pack.duration).toLocaleDateString()}</td></tr>
                        </>
                    }
                </tbody>
            </table>
        </div>
        <div className='row mtop30 text-center'>
            <button className='button bt_accent med m5' onClick={() => props.onAction({ page: 2, action: "next" })}>Tovább</button>
            <button className='button bt_grey med m5' onClick={() => props.onAction({ page: 2, action: "cancel" })}>Mégse</button>
        </div>
    </>
}


function SProcess(props) {
    const ficon = (nr) => {
        return nr < props.page ? 'far fa-circle' :
            nr === props.page ? 'fas fa-dot-circle' :
                'far fa-circle'
    }

    return <div className='row cl_white' style={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        backgroundColor: "#2baad9",
        height: "50px",
        lineHeight: "50px",
        fontSize: "16px",
    }}>
        <ul className='' style={{
        }}>
            <li className='left p0_10'>
                <span className={ficon(2)}></span>
                <span className='m5'>Tétel</span>
            </li>
            <HeadDivider />
            <li className='left p0_10'>
                <span className={ficon(3)}></span>
                <span className='m5'>Cím</span>
            </li>
            <HeadDivider />
            <li className='left p0_10'>
                <span className={ficon(4)}></span>
                <span className='m5'>Fizetés</span>
            </li>
        </ul>
    </div >
}


function HeadDivider() {
    return <li className='left nomobile'>
        <div style={{
            borderBottom: "1px solid white",
            float: "left",
            width: "60px",
            height: "25px",
            opacity: " 0.5",
        }}>
        </div>
    </li>
}