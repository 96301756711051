import React, { useState } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { content_item_bycode, content_item_temp } from '../client_redux/content.selectors'
import { item_get_bycode, item_temp_get } from '../client_redux/content.actions'

import Loading from '../../m_common/client_jsx/loading_page'
import can_open from '../meta/can_open'
import TaskSolve from '../client_task/item.solve'


export default function (props) {
    const { course_code } = props
    const { task_id } = useParams()
    const dispatch = useDispatch()
    const course_item = useSelector(store => content_item_bycode(store, course_code))

    if (!course_item) item_get_bycode(course_code, dispatch);

    return !course_item
        ? <Loading />
        : <Innerpage2
            course_item={course_item}
            task_id={task_id}
        />
}


function Innerpage2(props) {
    const { course_item, task_id } = props
    const [state, setState] = useState({ redirect: '', last_task: task_id })
    const dispatch = useDispatch()
    const setS = (json) => { setState({ ...state, ...json }) }
    const setR = (address) => { if (!state.redirect) setS({ redirect: address }) }
    if (task_id !== state.last_task) if (state.redirect) setState({ redirect: '', last_task: task_id })

    const course_temp = useSelector(store => content_item_temp(store, course_item?.id))
    let task = course_temp?.cache?.item[task_id]

    if (!can_open(course_item)) setR('/')

    else if (!course_temp) item_temp_get(course_item?.id, dispatch)


    const onAction = (act) => {
        switch (act.type) {
            case "exit":
            case "finish":
            case "next":
            case "error":
            default: setR('/'); break;
        }
    }


    return state.redirect
        ? <Redirect to={state.redirect} />
        : !course_temp
            ? null
            : <TaskSolve
                task_code={task?.code}
                parent_item={course_item}
                next_button={false}
                to_save={false}
                onAction={onAction}
            />

}