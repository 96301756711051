import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { content_group_item, content_item_overviews } from '../m_item/client_redux/content.selectors'
import { item_get_bygroup, item_get_more } from '../m_item/client_redux/content.actions'

export default (props) => {
    const { group_id } = props
    const dispatch = useDispatch()
    const group_item = useSelector(store => content_group_item(store, group_id))

    if (!group_item) item_get_bygroup(group_id, dispatch)

    return !group_item
        ? null
        : <GetItems group_item={group_item} />
}


const GetItems = (props) => {
    const { group_item } = props
    return !group_item
        ? null
        : <CheckItems group_item={group_item} />
}

const CheckItems = (props) => {
    const { group_item } = props
    const all_items = useSelector(content_item_overviews)
    return !all_items
        ? null
        : <CheckItems2 group_item={group_item} all_items={all_items} />
}


const CheckItems2 = (props) => {
    const { group_item, all_items } = props
    const dispatch = useDispatch()


    const items_ids = Object.keys(group_item).map(icode => icode * 1) || []
    const all_ids = Object.keys(all_items).map(acode => all_items[acode].id * 1) || []

    let missing = items_ids.filter(id => !all_ids.includes(id))

    if (missing.length) {
        item_get_more(missing, dispatch)
    }

    return null
}