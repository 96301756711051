import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { content_course_list, content_solution_course_list } from '../client_redux/content.selectors'
import { privileges_selector } from '../../m_auth/client/store.auth.selectors'
import { item_get_courses, solution_course_get_all } from '../client_redux/content.actions'
import { vote_type_to_launch } from '../../config/ui.config'

import Loading from '../../m_common/client_jsx/loading_page'
import "./a_course_list.scss"

import { CourseGroups } from './css_groups'
import { CheckVotes } from '../client_vote/h_votes_get'
//import { CourseAdminMissing } from './css_missing_admin' // <CourseAdminMissing course_list={course_list} />

export default () => {
    const dispatch = useDispatch()
    const course_list = useSelector(content_course_list)

    if (!course_list) item_get_courses(dispatch)

    return !course_list
        ? <Loading />
        : <>
            <CheckVotes type={vote_type_to_launch} />
            <InnerPage course_list={course_list} />
        </>
}


function InnerPage(props) {
    //const { course_list } = props
    const dispatch = useDispatch()
    const course_solutions = useSelector(content_solution_course_list)
    const privileges = useSelector(privileges_selector)
    const isUser = () => { return privileges ? !!privileges.loggedin : false }

    if (!course_solutions) {
        if (isUser()) solution_course_get_all(dispatch)
    }
    else { }

    return <CourseGroups />
}



