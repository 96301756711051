export let test_regex = {
	//password:	/([A-Za-z0123456789<>#&@{}$§3€]{4,30})/,
	//number:			/^\-{0,1}([1-9][0-9]*|[0])([.][0-9]*[1-9])?$/,
	//change_script:	/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi

	username: /^[A-Za-z0-9]{4,30}$/,
	password: /^[A-Za-z0-9íéáűőúöüóÍÉÁŰŐÚÖÜÓ()[\]<>@&!#|$€÷×ß§*%+_]{4,30}$/,
	city: /^[A-Za-zíéáűőúöüóÍÉÁŰŐÚÖÜÓ\-\s]{2,70}$/,
	name: /^[A-Za-z0-9íéáűőúöüóÍÉÁŰŐÚÖÜÓ\-\s]{3,50}$/,
	address: /^[A-Za-z0-9íéáűőúöüóÍÉÁŰŐÚÖÜÓ.,;\-\s]{3,100}$/,
	ziphu: /^[1-9][0-9]{3,3}$/,
	zip: /^[1-9][0-9]{3,5}$/,
	tax: /^[A-Za-z0-9-]{8,20}$/,

	email: /^[_A-Za-z0-9]+(\.[_A-Za-z0-9]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,7})$/,
	number: /^([1-9][0-9]*|[0])([.][0-9]*[1-9])?$/,
	integer: /^([1-9][0-9]*|[0])$/,
	integer30000: /^([1-9][0-9]*|[0])$/,
	required: /^[A-Za-z0-9íéáűőúöüóÍÉÁŰŐÚÖÜÓ.,˝`()[\]:/<>@&?!#|$€÷×ß§*%+_-]+$/,
	text: /^[A-Za-z0-9íéáűőúöüóÍÉÁŰŐÚÖÜÓ.,˝`()[\]:/<>@&?!#|$€÷×ß§*%+_-\s]{1,}$/,
	words: /^[A-Za-z0-9íéáűőúöüóÍÉÁŰŐÚÖÜÓ-\s]{1,}$/,
	wordsEng: /^[A-Za-z0-9-\s]{1,}$/,
	word: /^[A-Za-z0-9íéáűőúöüóÍÉÁŰŐÚÖÜÓ]{1,}$/,
	wordEng: /^[A-Za-z0-9]{1,}$/,
	change_text: /[^A-Za-z0-9íéáűőúöüóÍÉÁŰŐÚÖÜÓ.,˝`():/<>@&!?#|$€÷×ß§*%+_-\s]/g,
	change_space: /[\n\s\t]+/g,
	change_quote: /"/g,
	change_squote: /'/g,
	bookingid: /^[A-Z]{0,1}[0-9]{1,10}$/,
}
