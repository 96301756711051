import { CW_ONCHANGE } from '../events'


export function handlechange(code, cm) {
    let newval = cm.doc.getValue()
    let newcw = { ...this.cw, [code]: { ...this.cw[code], v: newval } }
    this.cw = newcw;

    if (code !== this.lastcw) {
        setTimeout(() => {
            CW_ONCHANGE.bind(this)(code)
        }, 300)
    } else {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            CW_ONCHANGE.bind(this)(code)
        }, 1000)
    }
    this.lastcw = '' + code;
}
