import { codebox_extensions } from '../meta/h_metadata'
import { handlechange } from './handler-cw'

export function renderCodeMirror(element, config, delay = 50, nr = 10) {
    if (nr <= 0) return
    let htmlelement = document.querySelector('#cw_id_' + element.c)
    if (!htmlelement) {
        setTimeout(() => { renderCodeMirror.bind(this)(element, config, delay, nr - 1) }, delay)
        return;
    }
    if ([...htmlelement.classList].includes('rendered')) return
    htmlelement.classList.add('rendered')

    let cnf = {
        value: element.v,
        mode: codebox_extensions[element.e] || element.e,
        theme: "ayu-mirage",
        lineWrapping: true,
        scrollbarStyle: "simple", //null, // 
        indentWithTabs: true
    }
    if (config.numbers) Object.assign(cnf, { lineNumbers: true })
    if (config.locked) Object.assign(cnf, { readOnly: 'nocursor' })
    if (config.focus) Object.assign(cnf, { autofocus: true })
    if (config.theme) Object.assign(cnf, { theme: config.theme })

    let cm = window.CodeMirror(htmlelement, cnf);
    cm.on('change', (instance, changeObject) => handlechange.bind(this)(element.c, instance))
    this.cws[element.c] = cm
}
