import React from "react"
import { Link } from 'react-router-dom'
import UserHeader from '../m_user/client/user-header_desktop'
import { img_urls } from '../config/ui.config'

export default (props) => {
    return <>
        <header className='App-header noselect nomobile'>
            <div className='row header_in'>
                <ul className='mw1000x m0a'>

                    <li className='nohover bg_code' style={{ padding: "0px" }}>
                        <Link id='linkHome' to='/'>
                            <div className='left p5 mright20' style={{ height: "100%" }}>
                                <img className='App-logo2' src={img_urls.brand_logo1} alt='CodeMan' />
                            </div>
                        </Link>
                    </li>

                    <Header page={props.page} />

                </ul>
            </div>
        </header>
    </>
}


const Header = (props) => {
    return <>
        <Link to='/kurzusok'>
            <li className={props.page === 'courses' ? 'active' : ''}>
                <span className=''>Kurzusok</span>
            </li>
        </Link>

        {/*<Link to='/gyakorlas'>
            <li className={props.page === 'challenges' ? 'active' : ''}>
                <span className=''>Gyakorlás</span>
            </li>
        </Link>*/}

        <Link to='/elofizetes'>
            <li className={props.page === 'shop' ? 'active' : ''}>
                <span className=''>Előfizetés</span>
            </li>
        </Link>


        <Link to='/info'>
            <li className={props.page === 'info' ? 'active' : ''}>
                <span className=''>Info</span>
            </li>
        </Link>

        <Link to='/kapcsolat'>
            <li className={props.page === 'contact' ? 'active' : ''}>
                <span className=''>Kapcsolat</span>
            </li>
        </Link>

        <UserHeader {...props} />

    </>
}


