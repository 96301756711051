export let mapnormal = {
    closed: {
        console: "console1",
        test: "test1",
        open: "test1"
    },
    console1: {
        console: "console2",
        test: "test1",
        close: "closed",
        open: "console2"
    },
    console2: {
        console: "console1",
        test: "test2",
        close: "console1"
    },
    test1: {
        console: "console1",
        test: "test2",
        close: "closed",
        open: "test2"
    },
    test2: {
        console: "console2",
        test: "test1",
        close: "test1"
    },
}

export let mapfixed = {
    closed: {
        console: "console1",
        test: "test1",
    },
    console1: {
        test: "test1",
    },
    console2: {
        test: "test2",
    },
    test1: {
        console: "console1",
    },
    test2: {
        console: "console2",
    },
}