import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { votes_selector } from '../client_redux/content.selectors'
import { votes_add2 } from '../client_redux/content.actions'
import LS from '../../m_common/client/local_storage'
import { show_message } from '../../m_common/client_messages/pure.messages.actions'

export function VoteItem(props) {
    const { type, item_id, only_once } = props
    const dispatch = useDispatch()
    let votes = useSelector(store => votes_selector(store, type, item_id))
    let currentnr = votes || 0
    let ls_label = 'voted_' + type + '_' + item_id

    const clicked = () => {
        let voted = only_once ? LS.get(ls_label) : false
        if (voted)
            show_message('warning_vote_voted_once', dispatch)
        else
            votes_add2(type, item_id, currentnr, dispatch)
        if (only_once) LS.set(ls_label,1)
    }

    return <>
        <div className='row text-center pointer vote noselect' onClick={clicked}>
            <span className='bold m5'>{currentnr}</span>
            <span className='m5 fas fa-thumbs-up'></span>
        </div>
    </>
}