import React from 'react'
import { useState } from 'react'
import { register_action } from './pure.auth.actions'
import { valEmail, valEmail2, valPW, valPW2 } from '../../m_common/client/h_validate'
import { generate_code } from '../../m_common/common/gen'
import { useDispatch } from 'react-redux'

export default (props) => {
    const [email, setem] = useState(' ')
    const [email2, setem2] = useState(' ')
    const [password, setpw] = useState('')
    const [password2, setpw2] = useState('')
    const [capcha, setCap] = useState('')
    const [checkb, setchb] = useState(false)
    const [ccode] = useState(generate_code(5).toString().toLowerCase())
    const [err1, seterr1] = useState('')
    const [err2, seterr2] = useState('')
    const [err3, seterr3] = useState('')
    const [err4, seterr4] = useState('')
    const [err5, seterr5] = useState('')
    const [err6, seterr6] = useState('')

    const dispatch = useDispatch()

    let pending = false;
    const send = (email, password) => {
        pending = true;
        let input = { email: email.toLowerCase(), password: password }
        register_action(input, dispatch)
            .then(x => {
                props.onaction({ what: 'registered', val: 1 })
                pending = false;
            })
            .catch(e => {
                if (e === 'err_duplicate_user') seterr1("* már létezik ilyen felhasználó")
                pending = false;
            })
    }

    const valCap = () => {
        let okC = (ccode === (capcha || "").toString().toLowerCase())
        seterr5(okC ? '' : '* a kód nem egyezik')
        return okC
    }
    const valCheckbox = () => {
        let okC = (!!checkb)
        seterr6(okC ? '' : '* nem fogadtad el a szabályzatot')
        return okC
    }

    const validateAll = () => {
        let emailOk = valEmail.bind(this)(email, seterr1)
        let email2Ok = valEmail2.bind(this)(email, email2, seterr2)
        let pwOk = valPW.bind(this)(password, seterr3)
        let pw2Ok = valPW2.bind(this)(password, password2, seterr4)
        let capOk = valCap()
        let checkbOk = valCheckbox()
        let textsOK = emailOk && email2Ok && pwOk && pw2Ok && capOk && checkbOk
        return textsOK
    }
    const submit = (e) => { e.preventDefault(); if (validateAll()) if (!pending) send(email, password) }

    return <>
        <div className='mw500 form_register'>
            <div className='row accentbox'>
                <div className='row'>
                    <h4>Regisztráció</h4>
                </div>
                <div className='row mtop10'>
                    <form onSubmit={e => submit(e)}>
                        <div className='row mtop10 '>
                            <div className='inputrow mw200'>
                                <input className='bt_trans_bo_white text-center mw200 text-ls1' type='text' placeholder='- email -' onChange={e => setem(e.target.value)} autoFocus />
                                <div className='inputerror'>{err1}</div>
                            </div>
                        </div>
                        <div className='row mtop10 '>
                            <div className='inputrow mw200'>
                                <input className='bt_trans_bo_white text-center mw200 text-ls1' type='text' placeholder='- email újra -' onChange={e => setem2(e.target.value)} />
                                <div className='inputerror'>{err2}</div>
                            </div>
                        </div>

                        <div className='row mtop10 '>
                            <div className='inputrow mw200'>
                                <input className='bt_trans_bo_white text-center mw200 text-ls1' type='password' placeholder='- jelszó -' onChange={e => setpw(e.target.value)} />
                                <div className='inputerror'>{err3}</div>
                            </div>
                        </div>
                        <div className='row mtop10 '>
                            <div className='inputrow mw200'>
                                <input className='bt_trans_bo_white text-center mw200 text-ls1' type='password' placeholder='- jelszó újra -' onChange={e => setpw2(e.target.value)} />
                                <div className='inputerror'>{err4}</div>
                            </div>
                        </div>

                        <div className='row mtop10'>
                            <Capcha code={ccode} />
                        </div>

                        <div className='row mtop10x '>
                            <div className='inputrow mw200'>
                                <input className='bt_trans_bo_white text-center mw200 text-ls1' type='password' placeholder='- kód -' onChange={e => setCap(e.target.value)} />
                                <div className='inputerror'>{err5}</div>
                            </div>
                        </div>

                        <div className='row mtop10 '>
                            <div className='inputrow mw200'>
                                <label>
                                    <input type='checkbox' checked={checkb} onChange={e => setchb(e.target.checked)} />
                                    <span className='link_texts'>Elfogadom az <a href='/info/aszf' target="_blank">ASZF</a>-et és az <a href='/info/adatvedelem' target="_blank">Adatvédelmi</a> szabályzatot!</span>
                                </label>
                            </div>
                            <div className='inputrow mw200'>
                                <div className='inputerror'>{err6}</div>
                            </div>
                        </div>

                        <div className='row mtop10'>
                            <button className='button big bt_trans_bo_white'>Elküld</button>
                        </div>
                    </form>
                </div>
                <div className='row'></div>
                <div className='row'></div>
                <div className='row'></div>
            </div>
        </div>
    </>
}


const Capcha = (props) => {
    const { code } = props

    const fillCapcha = () => {
        let c = document.getElementById("canvas_capcha");
        if (c) {
            var ctx = c.getContext("2d");
            ctx.clearRect(0, 0, c.width, c.height)
            ctx.font = "19px Georgia";
            ctx.fillStyle = "white";
            ctx.textAlign = "center";
            ctx.fillText(code, c.width / 2, c.height / 2 + 5);
        }
    }

    setTimeout(() => {
        fillCapcha()
    }, 500)

    return <>
        <canvas height="30" width="200" id='canvas_capcha' style={{ backgroundColor: "rgba(0,0,0,0.3)" }}></canvas>
    </>
}