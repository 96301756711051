import React from "react"

import HeaderUser2 from "../../m_user/client/_header"
import { UserIcon } from "../../m_auth/client/w_usericon_m"


export default (props) => {
    return <>

        <UserIcon page={props.page} >
            <section className='header_level2'>
                <HeaderUser2 />
            </section>
        </UserIcon>

    </>
}

