import React from 'react'
import { Switch, useRouteMatch, Route } from 'react-router-dom'
import { IfHasPriv } from '../../m_auth/client/w-haspriv'

import ShopPackages from '../../m_shop/client/shop.packages'
import ShopBuy from '../../m_shop/client/shop.buy'
import NewPage from '../../m_common/client_jsx/new_page'
import { img_urls } from '../../config/ui.config'


export default (props) => {
    const match = useRouteMatch();
    return <>
        <Switch>

            <Route path={match.path + "/:package_name"}>
                <NewPage title="Új előfizetés - Codeman.hu" />
                <IfHasPriv priv={'loggedin'} login={true}>
                    <div className='row fullheight page_light font-main noselect'>
                        <div className='toload2'>
                            <ShopBuy />
                        </div>
                    </div>
                </IfHasPriv>
            </Route>

            <Route path={match.path + "/"}>
                <NewPage title="Előfizetés - Codeman.hu" />
                <div className='row fullheight page_light font-main noselect'>
                    <div className='mw900 toload2'>
                        <Packages />
                    </div>
                </div>
            </Route>



        </Switch>
    </>
}


function Packages() {
    return <>
        <div className='row site_course_group m50_0'>
            <div className='row text-center'>
                <h4 className='ptitle'>
                    Előfizetés
                </h4>
            </div>
            <div className='row desc text-center op6'>
                <div className="mw800">
                    <p>
                        A weboldalon egyetlen előfizetés áráért minden kurzust, tananyagot, korábbi feladványt elérhetsz. Válogass a különbőző magyar nyelvű tartalmak között! Bármikor, bárhonnan tanulhatsz. Haladj a saját ütemedben!
                </p>
                    <p className='mtop10'>
                        Aktuális előfizetési áraink a következők!
                </p>
                </div>
            </div>

            <div className='row m50_0'>
                <div className='row'>
                    <ShopPackages />
                </div>

                <div className='row text-center mtop50 p5'>
                    <img src={img_urls.barion_strip1} alt='codeman.hu' style={{ maxWidth: "400px", width: "100%", margin: "0px auto" }} />
                </div>
            </div>
        </div>
    </>
}

