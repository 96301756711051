import React from 'react'
import PriceTable from '../../m_shop/client/w_pricetable'

export default function RowPrices(props) {
    return <>
        <div className='pagerow p50_0 bg_white'>
            <div className='row'>

                <div className='row text-center p0_10'>
                    <h3>Aktuális előfizetési díjaink</h3>
                </div>
                <div className='row text-center op5 p0_10'>
                    <h5>melyért cserébe bármely kurzushoz hozzáférhetsz</h5>
                </div>
                <div className='row mtop20'>
                    <PriceTable
                        onClickItem={(p) => { props.setRed("/elofizetes") }}
                        buttontext="Részletek"
                    />
                </div>

            </div>
        </div>
    </>
}
