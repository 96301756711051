export default {
    set: (item, val, __days = 1) => {
        try { window.localStorage[item] = val; return true; } catch (e) { return false; }
    },
    get: (item, def = undefined) => {
        try { return window.localStorage[item]; } catch (e) { return def }
    },
    del: (item) => {
        try { window.localStorage.removeItem(item); return true; } catch (e) { return false; }
    }
}