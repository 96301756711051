import React from 'react'

export function Buttons({ item, status, solution, next_button, onAction }) {
    const { proc } = item.task.settings
    const { showsolution } = status

    const clSolution = () => { if (!status.finished) onAction({ type: 'solution' }) }
    const clReset = () => { onAction({ type: 'reset' }) }
    const clFinish = () => { if (status.can_finish) onAction({ type: 'finish' }) }
    const clExit = () => { onAction({ type: 'exit' }) }
    const clNext = () => { onAction({ type: 'next' }) }

    let is_solved = !!(solution?.solved)
    let is_finished = status.finished
    let is_ready = status.can_finish
    let is_solution = showsolution
    let is_real = is_finished && !is_solution

    let show_next = next_button && (is_solved || is_real)
    let can_next = true

    let show_finish = !is_finished && !is_solved // ?
    let can_finish = is_ready && !is_solved // ?

    let show_reset = proc.reset_can
    let show_solution = proc.solution_can && !is_finished
    let can_solution = !is_finished

    return <>
        {!show_next ? null :
            <li className='disabled text-center'>
                <button
                    className={'button bt_accent'}
                    disabled={!can_next}
                    onClick={clNext}>Következő</button>
            </li>
        }

        {!show_finish ? null :
            <li className='disabled text-center'>
                <button
                    className={'button bt_accent'}
                    disabled={!can_finish}
                    onClick={clFinish}>Kész</button>
            </li>
        }

        {
            <li className='disabled text-center right'>
                <button
                    className={'button bt_grey'}
                    onClick={clExit}>Kilépés</button>
            </li>
        }

        {!show_solution ? null :
            <li className='disabled text-center'>
                <button
                    className={'button bt_grey'}
                    disabled={!can_solution}
                    onClick={clSolution}>Megoldás</button>
            </li>
        }

        {!show_reset ? null :
            <li className='disabled text-center'>
                <button
                    className={'button bt_grey'}
                    onClick={clReset}>Újrakezd</button>
            </li>
        }


    </>
}

