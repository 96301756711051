import React from 'react'
import { VoteItem } from '../client_vote/h_vote'
import { vote_type_to_launch, vote_type_only_once } from '../../config/ui.config'

export function CourseOverviewIn(props) {
    let { id, name, desc, image, color, rate, button_text, to_vote } = props

    let rate_shift = "0%";
    let rate2 = "100%";
    if (rate) {
        rate_shift = parseInt((1 - rate) * 100) + "%"
        rate2 = parseInt(rate * 100) + "%"
    }

    return <>
        <div className={'row'}>
            <div className='row c_image'>
                <img src={image} alt={name} />
                {!rate ? null :
                    <div className='c_rate'>
                        <div className='c_rate_bar' style={{ transform: "translate(-" + rate_shift + ",0%)" }}></div>
                        <div className='c_rate_val'>{rate2}</div>
                    </div>
                }
            </div>
            <div className='row c_body'>
                <div className='c_name'>{name}</div>
                <div className='c_desc'>{desc}</div>

            </div>
            <div className='row'>
                <div style={{ backgroundColor: color, color: "white" }} className='c_next row'>
                    {
                        to_vote
                            ? <VoteItem
                                item_id={id || 0}
                                type={vote_type_to_launch}
                                only_once={vote_type_only_once?.[vote_type_to_launch]}
                            />
                            : button_text || 'Részletek'
                    }
                </div>
            </div>
        </div>
    </>
}