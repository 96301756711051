import React, { useState } from 'react'
import Login from './auth.login.box'
import Register from './auth.register.box'
import Forget from './auth.forget.box'

export default () => {
    const [autPage, setAutPage] = useState('login');
    const action = (x) => {
        if (x.what === 'registered') setAutPage('login')
    }
    return (
        <>
            <div className='m3'>
                <div className='row text-center'>
                    <h4>Kérjük, hogy jelentkezz be az oldalra!</h4>
                </div>
                <div className='row text-center m20_0'>
                    <ShowAuthPage page={autPage} onaction={x => action(x)} />
                </div>
                <div className='row text-center'>
                    <ButtonToOtherPages page={autPage} choose={(p) => setAutPage(p)} />
                </div>
            </div>
        </>
    )
}

const ShowAuthPage = (props) => {
    switch (props.page) {
        case 'login': return <Login />
        case 'register': return <Register onaction={props.onaction} />
        case 'forget': return <Forget />
        default: return ''
    }
}

const ButtonToOtherPages = (props) => {
    return <>
        {props.page !== 'login' ? <div className='button big bt_grey m5' onClick={() => props.choose('login')}>Bejelentkezés</div> : ''}
        {props.page !== 'register' ? <div className='button big bt_grey m5' onClick={() => props.choose('register')}>Regisztráció</div> : ''}
        {props.page !== 'forget' ? <div className='button big bt_grey m5' onClick={() => props.choose('forget')}>Elfelejtett</div> : ''}
    </>
}