import React from 'react'
import Modal from '../../../m_common/client_jsx/modal'

export function ReadyModal({ action }) {
    const [on, setO] = React.useState(true)
    const onAction = (a) => {
        if (a === 'cancel') setO(false)
    }
    return !on ? null
        : <Modal title={"Feladat teljesítve"} button={{ yes: 0, cancel: 0 }} onAction={onAction}>
            <div className='row text-center flexcontainer p50_10'>
                <div className='row'>
                    <div className='row'>
                        <h4>A feladat kész!</h4>
                        <h6>Hogyan szeretnél továbbhaladni?</h6>
                    </div>
                    <div className='row mtop20'>
                        <button className='button bt_accent med m10' onClick={() => { action({ type: 'next' }) }}>Következő</button>
                        <button className='button bt_grey med m10' onClick={() => { action({ type: 'exit' }) }}>Kilépés</button>
                    </div>
                </div>
            </div>
        </Modal>
}