import React from 'react'
import { Iframe2Window } from '../tech_iframe2/a-iframe2-window'
import { Iframe1Window } from '../tech_iframe1/a-iframe1-window'

export function TechnologyWindow(technology, cw, info) {
    switch (technology) {
        case 'iframe': return <>{Iframe1Window.bind(this)()}</>
        case 'iframe2': return <Iframe2Window {...{ cw, info }} />
        default: return <></>;
    }
}
