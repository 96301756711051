import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { content_solutions, content_solution_course } from '../client_redux/content.selectors'
import { solution_course_get, solution_course_save } from '../client_redux/content.actions'

export default function Progress(props) {
    let { course_item, course_temp, solutions_list } = props
    const dispatch = useDispatch()
    const solutions = useSelector(content_solutions)
    const solutions_course = useSelector(store => content_solution_course(store, course_item?.id))
    let item_ids = course_temp?.cache?.items || []

    let r_rate = 0;
    let r_shift = 100;
    let r_ok = 0;
    let r_total = item_ids.length;

    if (solutions_list) {
        r_ok = 0
        solutions_list.item_ids.forEach(id => {
            r_ok += solutions[id]?.solved || 0
        })

        try { r_rate = Math.max(0, Math.min(100, parseInt(r_ok / r_total * 100))) } catch (e) { }
        r_shift = 100 - r_rate


        if (!solutions_course) solution_course_get(course_item?.id, dispatch)
        else {
            if (solutions_course.ok_nr !== r_ok || solutions_course.task_nr !== r_total) {
                if (!(solutions_course.ok_nr === 0 && r_ok === 0))
                    solution_course_save({ item_id: course_item.id, item_ids }, dispatch)
            }
        }
    }

    return !solutions_list
        ? <>
            <div className='row p20 text-center'>
                <div className='row'>
                    <span style={{ fontSize: "20px" }} className='op5'>
                        Még nem kezdted el a kurzust.
                    </span>
                </div>
                <div style={{ fontSize: "25px" }} className='row p20 bold'>{r_total} lecke</div>
                <div style={{ fontSize: "20px" }} className='op5 row mtop5'>vár rád!</div>
            </div>
        </>
        : <>
            <div className='row text-center' style={{ fontSize: "20px" }}>
                <span className='op5'>Elvégezve </span>
                <span className='bold'>{r_ok}/{r_total}</span>
                <span className='op5'> lecke</span>
            </div>
            <div className='row mtop20'>
                <div className='course_rate_container'>
                    <div className='course_rate'>
                        <div className='c_rate_bar' style={{ transform: "translate(-" + r_shift + "%,0%)" }}></div>
                        <div className='c_rate_val'>{r_rate}%</div>
                    </div>
                </div>
            </div>
        </>
}