import React from "react"
import { useParams, Link } from 'react-router-dom'

export default (props) => {
    const params = useParams();
    const { page } = params
    return <>

        <Link to='/felhasznalo/alapadatok'>
            <li className={page === 'alapadatok' ? 'active' : ''}>
                <span>Alapadatok</span>
            </li>
        </Link>

        <Link to='/felhasznalo/elofizetes'>
            <li className={page === 'elofizetes' ? 'active' : ''}>
                <span>Előfizetés</span>
            </li>
        </Link>

        <Link to='/felhasznalo/cimek'>
            <li className={page === 'cimek' ? 'active' : ''}>
                <span>Címek</span>
            </li>
        </Link>
    </>
}

/*
<Link to='/felhasznalo/ujjelszo'>
    <li className={page === 'ujjelszo' ? 'active' : ''}>
        <span>Új jelszó</span>
    </li>
</Link>

<Link to='/felhasznalo/megerosites'>
    <li className={page === 'megerosites' ? 'active' : ''}>
        <span>Megerősítés</span>
    </li>
</Link>
*/