import { iframe_document } from './a_iframe_common'
import { iframe_send } from './a_iframe_send'

export function iframe_init() {
    if (!this.iframe.embedhtml) {
        this.setS({ loaded: true })
        return
    }
    let id = iframe_document.bind(this)();
    try {
        id.open()
        id.write(this.iframe.embedhtml)
        id.close()
    } catch (e) { }
    this.setS({ loaded: false })    // because of reload
    iframe_init2.bind(this)()
}

function iframe_init2(delay = 51, nr = 10) {
    if (nr <= 0) return
    try { iframe_send.bind(this)('init_onload') }
    catch (e) {
        setTimeout(() => { iframe_init2.bind(this)(delay, nr - 1) }, delay);
    }
}
