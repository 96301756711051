import React from 'react'
import Attributes from './cs_desc_attributes'

export default function SecAttibutes(props) {
    let { course_item } = props
    let { overview, course } = course_item
    return <>

        <div className='row course_section'>
            <div className='course_section_header' style={{ color: overview.color }}>Jellemzők</div>
            <div className='row m20_0'>
                <Attributes attributes={course.attributes} />
            </div>
        </div>

    </>
}

