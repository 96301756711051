import React from 'react';
import { useDispatch } from 'react-redux'
import { login_token_action, logout_action } from '../m_auth/client/pure.auth.actions'
import { skip_logintoken, redirect_domains } from '../config/client.config'
import { resToMessage } from "../m_common/client_messages/pure.messages.actions"

import '../c_css/a_app.scss';
import AppRoutes from './app_routes'
import {GoogleAnalyticsInit} from '../m_common/client/3rd.google.analytics'

export default function () {
  const dispatch = useDispatch();

  // ---------- redirect url ------
  let { origin, hostname } = window.location
  let newhostname = redirect_domains[hostname] || ""
  if (redirect_domains[hostname]) {
    let neworigin = origin.split(':').slice(0, 2).join(':').replace(hostname, newhostname)
    window.location.href = neworigin
  }


  // ---------- if there is any message ------
  try {
    let qp = window.location.search
    let qparams = {}
    if (qp) {
      qp.substr(1, 9999).split("&").forEach(qs => {
        let qss = qs.split('=')
        qparams[qss[0]] = qss[1]
      })
      if (qparams.msg) {
        resToMessage({ ok: 1, msg: qparams.msg.split('.') }, dispatch)
      }
    }
  } catch (e) { }


  // ---------- skip auto login on some pages ------
  let path = window.location.pathname.substr(1, 9999);
  if (skip_logintoken.some(v => {
    return path.substr(0, v.length) === v
  }))
    logout_action(dispatch, true)
  else
  login_token_action(dispatch)
  
  
  // ---------- Google Analytics Init ------
  GoogleAnalyticsInit()



  return <AppRoutes />

}


