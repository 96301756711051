// --------- client ---------
export let api_path = "/api/v1"


export let api_url = (hostname) => {
    let url = window.location.origin + api_path

    switch (hostname) {
        case "localhost":
            url = "http://localhost:8080" + api_path
            url = "" + api_path
            break;

        case "www.codeman.hu":
        case "codeman.hu":
            url = "https://api.codeman.hu"
            //url = "https://api2.codeman.hu/api"
            break;

        default:
            break;
    }
    return url
}

/*
export let api2_url = (hostname) => {
    let url = window.location.origin + api_path

    switch (hostname) {
        case "localhost":
            url = "http://localhost:8080" + api_path
            url = "" + api_path
            break;

        case "www.codeman.hu":
        case "codeman.hu":
            url = "https://api2.codeman.hu"
            break;

        default:
            break;
    }
    return url
}
*/