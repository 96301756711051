import React from 'react'
import { mapnormal, mapfixed } from './u-act-maps'


export function InfoHead({ info, inform, fixed }) {

    const toInform = (what) => {
        let to = fixed ? mapfixed[info]?.[what] : mapnormal[info]?.[what]
        if (to) inform(to)
    }

    return <div className='iframe2_info_head'>
        <div
            className={'headeritem ' + (["test1", "test2"].includes(info) ? 'active' : 'inactive')}
            onClick={() => { toInform('test') }}
        >
            <span id='__test_head'>
                <span>Tesztek</span>
                <span id='__test_nr'></span>
            </span>
        </div>

        <div
            className={'headeritem ' + (["console1", "console2"].includes(info) ? 'active' : 'inactive')}
            onClick={() => { toInform('console') }}
        >
            <span id='__console_head'>
                <span>Konzol</span>
                <span id='__console_nr'></span>
            </span>
        </div>

        <div
            id='iframe2_info_close'
            style={{ display: ((info === 'closed' || fixed) ? 'none' : 'inline-block'), float: "right" }}
            onClick={() => { toInform('close') }}
        >
            <span className='fas fa-chevron-down'></span>
        </div>

        <div
            id='iframe2_info_open'
            style={{ display: ((["console2", "test2"].includes(info) || fixed) ? 'none' : 'inline-block'), float: "right" }}
            onClick={() => { toInform('open') }}
        >
            <span className='fas fa-chevron-up'></span>
        </div>


    </div>
}



export function InfoHeadTest() {
    return <div className='iframe2_info_head'>
        <div className={'headeritem active'}>
            <span id='__test_head'>
                <span>Tesztek</span>
                <span id='__test_nr'></span>
            </span>
        </div>
    </div>
}


export function InfoHeadConsole() {
    return <div className='iframe2_info_head'>
        <div className={'headeritem active'}>
            <span id='__console_head'>
                <span>Konzol</span>
                <span id='__console_nr'></span>
            </span>
        </div>
    </div>
}