import React from 'react'
import { useSelector } from 'react-redux'
import { roles_selector } from './store.auth.selectors'
import Lan from '../../config/lan'

export default (props) => {
    const roles = useSelector(roles_selector)

    return <>
        <div className={'row accentboxx ' + props.class}>
            {props.nohead ? '' :
                <div className='row text-center'>
                    <h4>Felhasználói jogosultságok</h4>
                    <div className='mtop10'></div>
                </div>
            }
            <div className='row m20_0'>
                <table className='w100 table_dark'>
                    <tbody>
                        <tr>
                            <th className=''>Megnevezés</th>
                            <th className=''>Kezdete</th>
                            <th className=''>Vége</th>
                        </tr>
                        {
                            roles.map((r, i) => <RoleView key={r.role + i} role={r} />)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </>
}


function RoleView(props) {
    const dt = (msec) => {
        return new Date(msec).toLocaleDateString()
    }

    let { role } = props
    return role.role === 'user' ? null : <tr>
        <td className=''>{Lan["role_" + role.role]?.hu || role.role}</td>
        <td className='text-center'>{role.valid_from ? dt(role.valid_from) : "-"}</td>
        <td className='text-center'>{role.valid_to ? dt(role.valid_to) : "-"}</td>
    </tr>
}