import React from "react"
import Modal from '../../m_common/client_jsx/modal'

export default (props) => {
    const { on } = props

    return !on ? null
        :
        <Modal title={"Kijelentkezés"} button={{ yes: 1, cancel: 1 }} onAction={props.onAction}>
            <div className='row p20_0 text-center'>
                <span className='link_texts'>
                    Elfogadom az
                    <a href='/info/aszf' target="_blank" className='cl_accent'> ASZF </a>
                    -et és az 
                    <a href='/info/adatvedelem' target="_blank" className='cl_accent'> Adatvédelmi </a> 
                    szabályzatot!
                </span>
            </div>
        </Modal>
}