import React, { useState } from 'react'

export function SetText(props) {
    const { value, name, disabled, maxlength, error, onChange, autofocus } = props
    const [svalue, setValue] = useState(value)
    return <div className={'row inputItem ' + props.class}>
        <div className='row'>
            <label >{name}</label>
            <input
                type='text'
                value={svalue}
                onChange={
                    (e) => {
                        setValue(e.target.value)
                        onChange(e.target.value)
                    }
                }
                disabled={disabled}
                maxLength={maxlength || "500"}
                autoFocus={autofocus || false}
            >
            </input>
        </div>
        <div className='row error'>
            {error || null}
        </div>
    </div>
}

export function SetDate(props) {
    const [value, setValue] = useState(props.value)
    return <div className={'row inputItem ' + props.class}>
        <div className='row'>
            <label >{props.name}</label>
            <input
                type='date'
                value={value}
                onChange={
                    (e) => {
                        setValue(e.target.value)
                        props.onChange(e.target.value)
                    }
                }
                disabled={props.disabled}
                maxLength={props.maxlength || "500"}
            >
            </input>
        </div>
        <div className='row error'>
            {props.error || null}
        </div>
    </div>
}

export function SetSwitch(props) {
    const { value, name, onChange, disabled } = props
    const [value2, setValue] = useState(value)
    return <div className={'row inputItem ' + props.class}>
        <label >{name}</label>
        <span className="switch" onClick={() => {
            if (!disabled) {
                setValue(1 - value2);
                onChange(1 - value2);
            }
        }}>
            {
                value2 === 1 ?
                    <span className='fas fa-toggle-on cl_green'></span>
                    :
                    <span className='fas fa-toggle-off cl_red op3x'></span>
            }
        </span>
    </div >
}

export function SetSelect(props) {
    const [value, setValue] = useState(props.value)
    return <div className={'row inputItem ' + props.class}>
        <label >{props.name}</label>
        <select className='simpleselect'
            value={value}
            onChange={
                (e) => {
                    setValue(e.target.value)
                    props.onChange(e.target.value)
                }
            }
            disabled={props.disabled}
        >
            {props.options.map(o => {
                return <option key={o} value={o}>{o.toUpperCase()}</option>
            })}
        </select>
    </div >
}

export function SetSelectVT(props) {
    const [value, setValue] = useState(props.value)
    return <div className={'row inputItem ' + props.class}>
        <label >{props.name}</label>
        <select className='simpleselect'
            value={value}
            onChange={
                (e) => {
                    setValue(e.target.value)
                    props.onChange(e.target.value)
                }
            }
            disabled={props.disabled}
        >
            {props.options.map(o => {
                return <option key={o.v} value={o.v}>{o.t}</option>
            })}
        </select>
    </div >
}

export function SetMulti(props) {
    const [value, setValue] = useState(props.value)
    const add = (x) => { ch([...value, x]) }
    const del = (x) => { ch(value.filter(y => { return y !== x })) }
    const all = () => { ch(props.options) }
    const none = () => { ch([]) }
    const ch = (newval) => { setValue(newval); props.onChange(newval) }

    return <div className='row inputItem'>
        <label >{props.name}</label>
        <div className='multiselect_in'>

            <div className='multi_col3'>
                <select size="10" className="multi_to multiselect">
                    {value.map(o => {
                        return <option key={o} value={o} onClick={(e) => { del(e.target.value) }}>{o}</option>
                    })}
                </select>
            </div>

            <div className='multi_col2'>
                <div className='p5 mtop30'><button className='button bt_grey med' onClick={all}>All</button></div>
                <div className='p5 mtop10x'><button className='button bt_grey med' onClick={none}>None</button></div>
            </div>

            <div className='multi_col1'>
                <select size="10" className="multi_from multiselect">
                    {props.options.sort().filter(x => { return !value.includes(x) }).map(o => {
                        return <option key={o} value={o} onClick={(e) => { add(e.target.value) }}>{o}</option>
                    })}
                </select>
            </div>

        </div>

    </div >
}