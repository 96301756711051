import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom'
import { Provider } from "react-redux";

import store from "./c_app/redux_store";
import * as serviceWorker from './c_app/serviceWorker';
import App from './c_app/app';


ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
  , document.getElementById('app-root')
);

serviceWorker.unregister();