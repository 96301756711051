import React from 'react'
import { Link } from 'react-router-dom'
import { demos } from '../../config/client.config'
import { CourseOverviewIn } from '../../m_item/client_course_list/h_course_overview'

export default function DemoTaks(props) {
    return <>
        <div className='pagerow bg_white'>

            <div className='row p50_0'>

                <div className='row text-center p0_10 '>
                    <h3>Bemutató feladatok</h3>
                </div>
                <div className='row text-center op5 p0_10'>
                    <h5>az oktatott technológiák demostrálására</h5>
                </div>
                <div className='row mtop20'>
                    <div className='mw1000_500_250'>
                        {
                            demos.map(demo =>
                                <Box key={demo.id} demo={demo} />
                            )
                        }
                    </div>
                </div>

            </div>
        </div>
    </>
}

function Box(props) {
    const { demo } = props
    const { id, name, desc, image, color } = demo
    return <>
        <div className={'site_course_overview demo1 mode_available'}>
            <Link to={'/demo/' + id}>
                <div className={'row c_in'}>
                    <CourseOverviewIn name={name} desc={desc} image={image} color={color} button_text={'Kipróbálom'} />
                </div>
            </Link>

        </div>
    </>
}