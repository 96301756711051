import React from "react"
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import HeaderInfo from '../c_pages/info/_header'
import UserHeader from '../m_user/client/user-header_mobile'
import { MenuToggleButton } from '../m_common/client_jsx/menu_toogle_button'
import { img_urls } from '../config/ui.config'
import { common_menu_open_selector } from "../m_common/client_redux/common.selectors"

export default (props) => {
    const menu_open = useSelector(common_menu_open_selector)
    return <>
        <header className='App-header noselect onmobile'>
            <div className='row placeholder onmobile'></div>
            <div className={'row header_in' + (menu_open ? ' open' : '')}>
                <ul className='p0_10x'>

                    <li className='nohover bg_code' style={{ padding: "0px" }}>
                        <Link id='linkHome' to='/'>
                            <div className='left p5 mright20' style={{ height: "100%" }}>
                                <img className='App-logo2' src={img_urls.brand_logo1} alt='CodeMan' />
                            </div>
                        </Link>
                        <div className='right' >
                            <MenuToggleButton />
                        </div>
                    </li>

                    {!menu_open ? null :
                        <Header page={props.page} />
                    }

                </ul>
            </div>
        </header>

    </>
}




const Header = (props) => {
    return <>
        <Link to='/kurzusok'>
            <li className={props.page === 'courses' ? 'active' : ''}>
                <span>Kurzusok</span>
            </li>
        </Link>

        {/*<Link to='/gyakorlas'>
            <li className={props.page === 'challenges' ? 'active' : ''}>
                <span>Gyakorlás</span>
            </li>
        </Link>*/}

        <Link to='/elofizetes'>
            <li className={props.page === 'shop' ? 'active' : ''}>
                <span>Előfizetés</span>
            </li>
        </Link>


        <Link to='/info'>
            <li>
                <span>Info</span>
            </li>
        </Link>
        <section className='header_level2'>
            <HeaderInfo />
        </section>

        {/*<Link to='/kapcsolat'>
            <li className={props.page === 'contact' ? 'active' : ''}>
                <span>Kapcsolat</span>
            </li>
        </Link>
        */}


        <UserHeader />

    </>
}


