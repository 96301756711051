import React from "react"
import Modal from '../../m_common/client_jsx/modal'

export default (props) => {
    const { on } = props

    return !on ? null
        :
        <Modal title={"Kijelentkezés"} button={{ yes: 1, cancel: 1 }} onAction={props.onAction}>
            <div className='row p20_0 text-center'>
                Valóban szeretnél kijelentkezni?
            </div>
        </Modal>
}