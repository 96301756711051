export default {


    role_user: { hu: "regisztrált felhasználó", en: "" },
    role_study1: { hu: "előfizetés", en: "" },
    role_study2: { hu: "előfizetés 2", en: "" },
    role_study3: { hu: "előfizetés 3", en: "" },

    attr_cost: { hu: 'Költség', en: '' },
    attr_lan: { hu: 'Nyelv', en: '' },
    attr_length: { hu: 'Hossz', en: '' },
    attr_level: { hu: 'Szint', en: '' },
    attr_time: { hu: 'Időszükséglet', en: '' },
    attr_topic: { hu: 'Téma', en: '' },

    mode_developing0: { hu: 'fejlesztés alatt', en: '', icon: 'fas fa-wrench', color: "#ea803b" },
    mode_developing: { hu: 'hamarosan', en: '', icon: 'far fa-hourglass', color: "#eab13b" }, // #FFA500"  #ea803b 
    mode_planned: { hu: 'tervezett', en: '', icon: 'fas fa-ruler-combined', color: "#C0C0C0" },//  #C0C0C0 "#eab13b"
    mode_locked: { hu: 'nem elérhető', en: '', icon: 'fas fa-lock', color: "#000000" },

    ribbon_free: { hu: 'ingyenes', en: '', icon: '', color: '#4caf50' },
    
    x2: { hu: "", en: "" },


}