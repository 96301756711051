import React from 'react'

export function InfoBody({ info }) {
    return <div className="iframe2_info_body">
        <div id="__console" className={'bodyitem ' + (["console1", "console2"].includes(info) ? 'active' : 'inactive')}></div>
        <div id="__test" className={'bodyitem ' + (["test1", "test2"].includes(info) ? 'active' : 'inactive')}></div>
    </div>
}

export function InfoBodyConsole() {
    return <div className="iframe2_info_body">
        <div id="__console" className={'bodyitem active'}></div>
    </div>
}

export function InfoBodyTest() {
    return <div className="iframe2_info_body">
        <div id="__test" className={'bodyitem active'}></div>
    </div>
}