import React from 'react'
import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom'
import DemoTask from "../../m_item/client_solve/a_demo_task"
import NewPage from '../../m_common/client_jsx/new_page'

import { demos_course } from '../../config/client.config'

export default (props) => {
    const match = useRouteMatch();
    return <>
        <Switch>

            <Route path={match.path + "/:task_id"}>
                <NewPage />
                <DemoTask course_code={demos_course} />
            </Route>

            <Route path={match.path + "/"}>
                <Redirect to={"/"} />
            </Route>
        </Switch>
    </>
}
