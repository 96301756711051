import { toStringFx, htmlEntities } from '../../../m_common/common/text'

export const write = (arr, place) => {
    _writeArrayTo(arr, place)
}

export const addPrefix = (str, prefix = "") => {
    return str.substr(0, prefix.length) === prefix ? str : prefix + str
}

const _writeline = (l, i = 0) => {
    let cursor = "__cursor"


    let cl = "row";
    if (l.ok === 0) cl += " __cl_red";
    if (l.ok === 1) cl += " __cl_warning";
    if (l.ok === 2) cl += " __cl_code";
    if (l.ok === 3) cl += " __cl_green";


    let text = "";
    if (l.text) text = toStringFx(l.text);
    else if (l.texts) text = l.texts.map(t => toStringFx(t)).join(' ')
    if (text.substr(0, 4) === '....') {
        text = htmlEntities(text.substr(4, 999))
        cursor += ' indent15'
    } else {
        text = htmlEntities(addPrefix(text, "> "))
    }
    text = text.replace(/\s\s/g, '<span style="opacity:0;">__</span>')



    let starter = "<div class='__console_row " + cl + "'>"

    let b = ""
        + starter
        + (i ? '<span class="__linenumber">' + i + '</span>' : '')
        + "<span class='" + cursor + "'>"
        + text
        + "</span>"
        + "</div>"
    return b;
};

const _writeArray = (_towrite = [], targetHtmlElement) => {
    if (!targetHtmlElement) return
    let lines = [];
    let html = "";
    _towrite.forEach((l, i) => {
        let line = _writeline(l, i + 1)
        lines.push(line);
        html += line;
    });
    targetHtmlElement.innerHTML = html;
};

const _writeArrayTo = (array, id) => {
    let element = document.getElementById(id)
    _writeArray(array, element)
};

