import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { messages_selector, message_refresh_selector } from './store.messages.selectors'
import { message_del_action, message_clear_action } from './store.messages.actions'
import "./messages.style.scss"

//
export default function () {
    return <div id="m-messages">
        <MessagesComp />
    </div>
}


function MessagesComp(props) {
    const dispatch = useDispatch()
    const messages = useSelector(messages_selector)
    const refresh = useSelector(message_refresh_selector)
    let mids = Object.keys(messages)

    const delOne = (id) => { dispatch(message_del_action(id)) }

    if (mids.length) dispatch(message_clear_action())
    return !mids || !refresh ? "" :
        mids.map((id, i) => <MessageComp key={id} id={id} index={i} onDel={delOne} message={messages[id]} />)
}

function MessageComp(props) {
    const { message } = props

    return <div
        className={"m-message " + message.type}>
        {MessageIcon(message.type)}
        <span className='m-text'>{message.text}</span>
        <span
            className='fas fa-times m-close'
            onClick={() => { props.onDel(props.id) }}
        >
        </span>
    </div>
}

function MessageIcon(type) {
    switch (type) {
        case "errorx": return <span className='m-icon fas fa-times-circle'></span>
        case "error": return <span className='m-icon fas fa-exclamation'></span>
        case "warning": return <span className='m-icon fas fa-exclamation-triangle'></span>
        case "info": return <span className='m-icon fas fa-info'></span>
        case "success": return <span className='m-icon fas fa-check'></span>
        default: return null
    }
}