import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { privileges_selector, roles_selector } from '../../m_auth/client/store.auth.selectors'
import { content_solution } from '../client_redux/content.selectors'
import { mode as mode_meta } from '../meta/sets'
import { show_message } from '../../m_common/client_messages/pure.messages.actions'

import can_open_item from '../meta/can_open'

export default function TaskRow(props) {
    const dispatch = useDispatch()
    const privileges = useSelector(privileges_selector)
    const roles = useSelector(roles_selector)

    let { id, course_item, course_temp } = props
    let item = course_temp?.cache?.item[id]

    const solution = useSelector(store => content_solution(store, id))

    let can_open = can_open_item(item, roles, privileges, course_item)
    let done = !!(solution?.solved)

    const onClick = () => {
        if (!can_open) show_message(mode_meta[item.availability.mode].cant_message, dispatch)
    }

    const Inner = () => {
        return <>
            {can_open ?
                <span className={'element_play_icon ' + (done ? 'cl_green fas fa-check' : 'cl_redx op2 far fa-circle')}></span>
                :
                <span className={'element_play_icon ' + mode_meta[item.availability.mode].icon + " " + mode_meta[item.availability.mode].u_color}></span>
            }

            <span className='element_icon fas fa-code' style={{ color: course_item.overview.color }}></span>
            <span className='element_name'>{item.overview.name}</span>
        </>
    }

    return !item ? null : <>
        {can_open ?
            <Link to={'/kurzus/' + course_item.code + "/" + id}>
                <div className={'row course_element chapter_item can_open' + (done ? ' done' : '')}>
                    <Inner />
                </div>
            </Link>
            :
            <div className={'row course_element chapter_item cant_open' + (done ? ' done' : '')} onClick={onClick}>
                <Inner />
            </div>
        }
    </>
}