export default {
    now: function (plusday = 0) { return Date.now() + plusday * 24 * 60 * 60 * 1000; },

    dbDate: function (date = new Date().getTime(), plusdays = 0, floor = false) { // = new Date()
        let day = 24 * 60 * 60 * 1000
        let timestamp = date + plusdays * day
        if (floor) timestamp = Math.floor(timestamp / day) * day
        return new Date(timestamp)
    },

    dbDate_s: function (date = new Date().getTime(), plusdays = 0, floor = false) { // = new Date()
        return this.dbDate(...arguments)
            .toLocaleDateString()
            .replace(/ /g, '')
            .replace(/\./g, '-')
            .substr(0, 10)
    },

    datestr: function (plusdays = 0, floor = false) { // = new Date()
        let day = 24 * 60 * 60 * 1000
        let timestamp = new Date().getTime() + plusdays * day
        if (floor) timestamp = Math.floor(timestamp / day) * day
        return new Date(timestamp).toLocaleDateString()
    },

    timestr(sec_) {
        let sec = parseInt(sec_)
        if (sec === null) return "-"
        let h = Math.floor(sec / 3600);
        let m = Math.floor((sec - h * 3600) / 60);
        let s = sec - h * 3600 - m * 60;
        let hh = "";
        if (h === 0) { hh = ""; } else { hh = ("0" + h).substr(-2) + ":"; }
        let mm = ("0" + m).substr(-2) + ":";
        let ss = ("0" + s).substr(-2);
        return (hh + mm + ss);
    },

    datatime_str(timelen = 6, dt = new Date()) {
        const td = (x) => ('00' + x).substr(-2)
        const atd = (a) => { return a.map(td) }

        let d = dt.toLocaleDateString().replace(/ /g, '').replace(/\./g, '-').substr(0, 10)
        let t = atd(dt.toLocaleTimeString().split(':')).join('').substr(0, timelen)
        return d + "--" + t
    }
}