
var default_shop_state = {
    history: null,
    pendin: false,
    error: false,
}

export function shop_reducer(state = default_shop_state, action) {
    let newstate = { ...state }

    switch (action.type) {
        case 'SHOP_SENT': newstate.pendin = true; newstate.error = false; break;
        case 'SHOP_ERROR': newstate.pendin = false; newstate.error = true; break;
        case 'SHOP_BACK': newstate.pendin = false; newstate.error = false; newstate.history = action.payload.shop_history; break;
        case 'LOGOUT': newstate.history = null; break;
        default: break;
    }

    return newstate
}