export default {
    info_logout: { hu: "Kijelentkeztél az alkalmazásból.", en: "" },
    info_email_sent: { hu: "Az email kiküldésre került.", en: "" },


    info_auth_logintoken: { hu: "Automatikus bejeletkezés történt.", en: "" },
    info_auth_login: { hu: "Bejelentkeztél az alkalmazásba.", en: "" },
    info_auth_forget: { hu: "A jelszó változtatáshoz az emailt kiküldtük.", en: "" },

    warning_auth_logintoken: { hu: "Az automatikus bejelentkezés sikertelen!", en: "" },

    error_auth_nouser: { hu: "Nincs ilyen felhasználó!", en: "" },
    error_auth_useralready: { hu: "Már létezik ilyen felhasználó!", en: "" },
    error_auth_wrongpass: { hu: "Hibás jelszó!", en: "" },
    error_auth_login: { hu: "A bejelentkezés sikertelen!", en: "" },
    error_auth_register: { hu: "A regisztráció sikertelen!", en: "" },
    error_auth_register_toomany: { hu: "Ez a regisztráció már sikertelen!", en: "" },
    error_auth_changepass: { hu: "A jelszó megváltoztatása sikertelen!", en: "" },
    error_auth_newpass: { hu: "Az új jelszó megadása sikertelen!", en: "" },
    error_auth_verif: { hu: "A regisztráció megerősítése sikertelen!", en: "" },
    error_auth_forget: { hu: "Az új jelszó igénylése sikertelen!", en: "" },

    error_privileges: { hu: 'Nincs jogosultságod ehhez a művelethez!', en: '' },
    error_priv_loggedin: { hu: "Ehhez a művelethez be kell jelentkezned!", en: "" },
    error_priv_admin: { hu: "Nincs admin jogosultságod!", en: "" },

    warning_ui_form_complete: { hu: 'Az űrlapot hibátlanul kell kitölteni!', en: "" },
    warning_ui_register_accept_terms: { hu: 'El kell fogadnod a szabályzatot!', en: "" },
    warning_ui_course_no_subscription: { hu: 'Nincs érvényes előfizetésed!', en: "" },
    info_ui_course_developing: { hu: 'Az anyag fejlesztés alatt.', en: "" },
    info_ui_course_planned: { hu: 'Tervezett', en: "" },
    info_ui_course_locked: { hu: 'A anyag nem elérhető.', en: "" },

    success_auth_login: { hu: "Sikeres bejelentkezés!", en: "" },
    success_auth_register: { hu: "A regisztráció sikeres!", en: "" },
    success_auth_changepass: { hu: "Sikeresen módosítottad a jelszavad!", en: "" },
    success_auth_newpass: { hu: "Sikeresen módosítottad a jelszavad!", en: "" },
    success_auth_verif: { hu: "A regisztráció megerősítése sikeres!", en: "" },

    success_data_save: { hu: 'Az adatokat sikeresen mentettük!', en: '' },

    warning_vote_voted_once: { hu: 'Korábban már szavaztál erre!', en: '' },

    error_jwt: { hu: "Az azonosítód lejárt. Jelentkezz be újra!", en: "" },

    x1: { hu: "", en: "" },
    x2: { hu: "", en: "" },


}