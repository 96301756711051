import React from 'react'
import { Timer } from './u-timer'
import { goal } from '../../meta/sets'

export function TaskBar({ solution, item, status, buttons }) {

    return <>
        <ul className='left' id='bar1_sec1'>
            <Title
                item={item}
                solution={solution}
            />
        </ul>

        <ul className='left' id='bar1_sec2'>
            {buttons}
        </ul >
        <ul className='right' id='bar1_sec3'>
            <Task
                item={item}
                solution={solution}
            />

            <Status
                item={item}
                status={status}
            />
        </ul >
    </>
}

function Title({ item }) {
    return <>
        <li className='disabled  text-center'>
            <div className='bar_name'><span className=''>{item.overview.name}</span></div>
        </li>
    </>
}

function Task({ item, solution }) {
    const { set } = item.task.settings

    return <>
        <li className='disabled  text-center'>
            <div ><span className=''>{goal[set.goal].bar_text}</span></div>
        </li>

        {solution?.solved
            ? <li className='disabled  text-center'>
                <div ><span className='cl_yellow'>A feladatot már megoldottad!</span></div>
            </li>
            : null
        }
    </>
}

function Status(props) {
    const { item, status } = props
    const { proc } = item.task.settings

    return <>
        <li className={'disabled ' + (!proc.timer_show ? ' nodisplay' : '')}>
            <span className='bar_timer text-center'>
                <Timer finished={status.finished} timer={status.timer} />
            </span>
        </li>

        {!status.finished || status.showsolution ? '' :
            <li className='disabled '>
                <span className='bar_rate cl_yellow bold text-center'>
                    {Math.round(status.stat.rate * 100)}%
                    </span>
            </li>
        }
        {!status.showsolution ? '' :
            <li className='disabled '>
                <span className='bar_timer cl_yellow bold text-center'>Megoldás</span>
            </li>
        }
    </>
}

