import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { address_meta } from '../meta/meta.address'
import { CreateForm } from '../../m_common/client_jsx/io_form'
import { show_message } from '../../m_common/client_messages/pure.messages.actions'
import { user_address_save, user_address_get } from './pure.user.actions'
import { user_address_selector } from './store.user.selectors'
import Loading from '../../m_common/client_jsx/loading'
import "./w_address_billing.scss"

//{InnerPart({ onCancel, onSubmit, button_text, cancel_button, cancel_button_text, data })}

export function UserBillingAddress(props) {
    const { onCancel, onSubmit, button_text, cancel_button, cancel_button_text } = props
    let dispatch = useDispatch()
    let address = useSelector(user_address_selector)

    if (!address) {
        dispatch(user_address_get())
    }

    return !address ? <Loading /> : <>
        <InnerPart
            onCancel={onCancel}
            onSubmit={onSubmit}
            button_text={button_text}
            cancel_button={cancel_button}
            cancel_button_text={cancel_button_text}
            data={address}
        />
    </>
}


function InnerPart(props) {
    const { onCancel, onSubmit, button_text, cancel_button, cancel_button_text, data } = props
    let dispatch = useDispatch()
    let [address_sent, setAs] = useState(null)

    const okSave = (_address) => {
        try { onSubmit(_address) } catch (e) { }
        setAs(_address)
    }

    const onFormSubmit = (data2) => {
        let allok = true;
        for (let i in data2) {
            if (!([0, "0", null, ""].includes(data2.company.data) && i === 'tax'))
                if (data2[i].error) allok = false
        }
        if (!allok) show_message('warning_ui_form_complete', dispatch)
        else {
            let same = true
            let address = { ...data }
            let address_o = address_sent || { ...data }
            for (let i in data2) {
                address[i] = data2[i].data
                if (address[i] !== address_o[i]) same = false
            }

            if (same) {
                okSave(address)
            } else {
                user_address_save(address, dispatch)
                    .then(x => { okSave(address) })
                    .catch(e => { })
            }
        }
    }

    return !data ? null : <>
        <div className='mw450_300 user_address_form'>
            <CreateForm
                data={data}
                meta={address_meta}
                css={'medium'}
                exclude={['scope', 'tax_eu']}
                def={{ scope: 'billing' }}
                disabled={[]}
                button={true}
                button_text={button_text || 'Mentés'}
                cancel_button={cancel_button || false}
                cancel_button_text={cancel_button_text || 'Mégse'}
                onSubmit={onFormSubmit}
                onCancel={onCancel}
            />
        </div>
    </>
}


