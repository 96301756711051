import React from "react"
import { Switch, Route, useRouteMatch, useParams, Redirect } from 'react-router-dom'
import TermsPage from './info_terms'
import DataPage from './info_data'
import RolunkPage from './info_aboutus'
import Header2 from './_header'


export default (props) => {
    const match = useRouteMatch()
    return <>
        <Switch>
            <Route path={match.path + '/:page'}><Page match={match} /></Route>
            <Route path={match.path}><Home match={match} /></Route>
        </Switch>
    </>
}

const Home = (props) => {
    return <Redirect to={props.match.path + '/rolunk'}></Redirect>
}

const Page = (props) => {
    const { match } = props
    return <>
        <div className='row nomobile App-header level2'>
            <div className='header_in'>
                <ul>
                    <Header2 />
                </ul>
            </div>
        </div>
        <Innerpage match={match} />
    </>
}

const Innerpage = (props) => {
    const { match } = props
    const params = useParams();
    const { page } = params
    switch (page) {
        case "rolunk": return <RolunkPage />
        case "adatvedelem": return <DataPage />
        case "aszf": return <TermsPage />
        default: return <Home match={match} />
    }

}

