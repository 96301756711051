import { iframe_itself, iframe_contentWindow, iframe_document } from './a_iframe_common'
import { iframe_back } from './a_iframe_back'

import behave from '../meta/h_behaviour'
import { h_handle_code, h_merge_code, isJs, isPy } from './handle'
import { ON_TEST } from '../events'
import { iframe_del_objects } from './a_iframe_clear'

export function iframe_send(what, input = {}, test = false) {
    let fr = iframe_itself.bind(this)()
    let iw = iframe_contentWindow.bind(this)();
    let id = iframe_document.bind(this)();
    if (!id || !iw) return;

    const toins = (c, v) => {
        try {
            if (c === 'document') {
                id.open()
                id.write(v)
                id.close()
            } else {
                id.querySelectorAll('.__insert_' + c).forEach(x => { x.innerHTML = v })
            }
        } catch (e) { }
    }

    const initing = () => {
        if (iw.__$loaded === undefined) iw.__$loaded = false
        let basejs = { e: 'js', v: this.iframe.embedjs, c: "embedjs-from-server" }

        let owncode = Object.keys(this.cw)
            .map(x => { return { ...this.cw[x], c: x, run: behave.run(this.cw[x], this.props.solution) } })

        iframe_del_objects.bind(this)(iw)
        this.iw_objects = [...Object.keys(iw)]
        
        if (this.iw_py_objects) iw.__$py_objects = this.iw_py_objects

        iframe_send.bind(this)('code', basejs, false);
        iframe_send.bind(this)('codes', owncode, true);
    }

    const ending = () => {
        if (test) {
            iframe_back.bind(this)(iw, (ok) => {
                ON_TEST.bind(this)(ok)
                iframe_del_objects.bind(this)(iw, "__$", "")
            })
        }
    }

    if (test) {
        iw.__$cw = { ...this.cw }; for (let c in iw.__$cw) if (!behave.edit(iw.__$cw[c], false)) delete iw.__$cw[c]
    }

    switch (what) {
        case 'init_onload': fr.onload = () => { 
            iw.__$loaded = false; 
            initing(); 
        }; break;
        case 'init': initing(); break;
        case 'codes':

            try { iw.__$frame.clear(); } catch (e) { }
            try { iw.__$test.clear(); } catch (e) { }

            (input || []).forEach(cc => {
                h_handle_code.bind(this)(cc.c, iw, cc.run)
            });
            let torun = h_merge_code.bind(this)(input || [])
            torun.forEach(c => iframe_send.bind(this)('code', c, false))
            break;
        case 'code':
            let v_2 = input.v
            if (isJs(input.e)) {
                // javascript
                if (iw.__$frame) iw.__$frame.eval(v_2, iw)
                else iw.eval(v_2)
            } else if (isPy(input.e)) {
                // python
                iw.__$py = input.v
            } else {
                if (input.b !== 'r') toins(input.c, v_2)
            }
            break;
        default: break;
    }

    ending()
}
