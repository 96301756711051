import React from 'react'

import NewPage from '../../m_common/client_jsx/new_page'
//import { img_urls } from '../../config/ui.config'


export default (props) => {
    return <>
        <NewPage title='A Codeman.hu-ról - Codeman.hu'/>
        <div className='row fullheight page_light font-main noselect'>
            <div className='row site_course_group m50_0 toload2'>
                <div className='row text-center'>
                    <h4 className='ptitle'>A codeman.hu-ról</h4>
                </div>

                <div className='row row desc m50_0'>
                    <div className='mw1000 justify'>
                        <Szoveg />
                    </div>
                </div>
            </div>
        </div>
    </>
}


function Szoveg() {
    return <>
        <div className='desc'>
            <p>Mi azt gondoljuk, hogy mindenkinek meg kell tanulnia programozni! Legalább egy kicsit!</p>
            <p className='mtop30'>A <b>codeman.hu</b> 2020-ban indult útjára. Csapatunkban képviselteti magát az informatika több területe. Célunk, hogy mindenki számára a leggyakorlatiasabb és legegyszerűbb formában elérhetővé tegyük a programozás tanulását, legyen az illető a programozás iránt érdeklődő pályakezdő, hobbit kereső gimnazista, vagy tudását bővíteni szándékozó tapasztaltabb szakember.</p>
            <p>A weboldal központi elemét egy beépített fejlesztőeszköz adja, melynek segítségével helyben kell programozási feladatokat megoldani. A kurzusok menüpont alatt az egyes témakörök tartalmát leckékre bontottuk, amelyeken belül az interaktív kódolási példák mellett a magyarázatot tartalmazó írásos tananyag is megtalálható.</p>
            <p>Távoli célunk, hogy egyszer a platform a magyar középiskolai oktatás részét képezze.</p>
        </div>

        <h3 className='title mtop50'>Pályakezdőknek</h3>
        <div className='desc'>
            <p>A programozók, fejlesztők már most az egyik legkeresettebb szakmát tudhatják magukénak, és a jövőben az igény és a kereslet az informatikai tudás és a géppel szót értő személyek iránt csak nőni fog. Mind a várható magasabb jövedelem, mind pedig a szélesebb lehetőségek miatt egyre többen érdeklődnek a fejlesztői szakma iránt, mely mellett további előnyként szól például a home office és a kötetlen munkarend gyakori lehetősége.</p>
            <p>Oktatási platformunk segítségével interaktív feladatokkal segítünk elindulni ezen a pályán. Mert egy példa többet ér ezer szónál!</p>
        </div>

        <h3 className='title mtop50'>Fiataloknak és hobbi informatikusoknak</h3>
        <div className='desc'>
            <p>A szoftverfejlesztői pálya mellett az életnek már ma is rengeteg olyan területe van, ahol a kód írásának képessége előnyt jelent. A kisebb-nagyobb programozási feladatok megoldásának képessége ma már szinte minden munkaterületen hasznosítható. A jövőre nézve ez gyakorlatilag alap kompetenciának is tekinthetjük. Éppen ezért a más területen dolgozóknak is megéri ismerkedni ezzel a témakörrel, és a fiatalokat is érdemes minél előbb arra biztatni, hogy ássák bele magukat kicsit ebbe a világba.</p>
            <p>Az általános és középiskolai tananyagnak jelenleg nem része a programozás, és a felsőoktatásban is csak a kifejezetten erről szóló szakokon oktatják. Ez viszont nem jelenti azt, hogy ne tanulhatnának a diákok, vagy más területek képviselői akár webfejlesztést vagy adatelemzést. A platformunk segítségével mindenféle külső segítség, szülői hozzáértés, telepítések és plusz beruházások nélkül bármikor lehetőség van a programozás útján történő elindulásra.</p>
        </div>

        <h3 className='title mtop50'>Tapasztaltaknak</h3>
        <div className='desc'>
            <p>Ma már ugyanakkor az informatikus vagy programozó kifejezés sem egy konkrét szakmát jelöl. Egy gyűjtőfogalom. A sokféle programnyelv  és technológia következtében ebben az ágazatban egy végtelen specializálódás zajlik. Egy webfejlesztő és egy data scientist nem is biztos, hogy találna magának közös témát. A webfejlesztő lehet frontend-es vagy backend-es, azon belül érthet PHP-hoz, .Net-hez, React-hoz, Vue.js-hez.</p>
            <p>Egy fejlesztő a technológiai tudását és gyakorlatát kamatoztatja minden nap. A technológiák száma folyamatosan nő. A meglévők is folyamatosan fejlődnek és cserélődnek. A technológiai tudás gyorsan elavul. Éppen ezért egy fejlesztő élete végtelen tanulás. Várjuk az oldalunkon mindazokat, akik bővíteni szeretnék ismereteiket, és új technológiákkal ismerkednének.</p>
        </div>
    </>
}

