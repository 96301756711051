import React from 'react'
import { useSelector } from 'react-redux'
import { privileges_selector } from './store.auth.selectors'

export function LoggedIn({ children }) {
    const privileges = useSelector(privileges_selector) || {}
    return privileges?.['loggedin']
        ? <>
            {children}
        </>
        : null

}


export function NotLoggedIn({ children }) {
    const privileges = useSelector(privileges_selector) || {}
    return privileges?.['loggedin']
        ? null
        : <>
            {children}
        </>
}