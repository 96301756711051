import { write } from './write'

export function iframe_back(iw, okFx, nr = 10) {
    //console.log("back",!!iw,nr)
    const write_info_nr = (id, value, className = "") => {
        try {

            let eln = document.getElementById(id + "_nr")
            let elt = document.getElementById(id + "_head")
            if (value) {
                eln.style.display = 'inline-block'
                eln.innerHTML = value
                elt.className = className
            } else {
                eln.style.display = "none"
            }
        } catch (e) { }
    }

    if (nr <= 0) {
        loaded.bind(this)()
        okFx(false)
        return
    }

    let _ok = true
    if (!iw) {
        loaded.bind(this)()
        return
    }

    if (iw.__$py_objects) this.iw_py_objects = iw.__$py_objects

    if (iw.__$test)
        try {
            let testReady = iw.__$test.isReady()
            if (!testReady) throw new Error('not ready')

            iw.__$loaded = true

            _ok = iw.__$test.isOk()
            let _test = iw.__$test.getResults() || []
            let _console = iw.__$frame.getConsole() || []
            write(_test, '__test')
            write(_console, '__console')

            try {
                write_info_nr('__test', _test.length, _ok ? "" : "red")
                write_info_nr('__console', _console.length)
            } catch (e) { }

            iw.__$frame.next()
            okFx(_ok)
        } catch (e) {
            setTimeout(() => { iframe_back.bind(this)(iw, okFx, nr - 1) }, 300)
        }
    else {
        iw.__$loaded = true
    }

    if (iw.__$loaded) loaded.bind(this)()

}

function loaded(){
    this.setS({ loaded: true })
}