import React from 'react'
import { Switch, Route, useRouteMatch, useParams, Redirect } from 'react-router-dom'

import BaseData from './user.base'
import Roles from './user.roles'
import Billing from './user.billing'
import NewPass from './user.newpass'
import Verif from './user.verify'
import { IfHasPriv } from '../../m_auth/client/w-haspriv'
import NewPage from '../../m_common/client_jsx/new_page'

import UserHeader from './_header'


export default (props) => {
    const match = useRouteMatch()
    return <>
        <Switch>
            <Route path={match.path + '/:page'}><Page /></Route>
            <Route path={match.path}><Redirect to={match.path + '/alapadatok'}></Redirect></Route>
        </Switch>
    </>
}


const Page = () => {
    const params = useParams();
    const { page } = params

    switch (page) {
        case "megerosites": return <>
            <NewPage title="Felhasználó - Codeman.hu" />
            <Verif />
        </>
        case "ujjelszo": return <>
            <NewPage title="Felhasználó - Codeman.hu" />
            <NewPass />
        </>
        case "alapadatok": return <>
            <NewPage title="Felhasználó - Codeman.hu" />
            <IfHasPriv priv={'loggedin'} login={true}>
                <Head />
                <BaseData />
            </IfHasPriv>
        </>
        case "elofizetes": return <>
            <NewPage title="Felhasználó - Codeman.hu" />
            <IfHasPriv priv={'loggedin'} login={true}>
                <Head />
                <Roles />
            </IfHasPriv>
        </>
        case "cimek": return <>
            <NewPage title="Felhasználó - Codeman.hu" />
            <IfHasPriv priv={'loggedin'} login={true}>
                <Head />
                <Billing />
            </IfHasPriv>
        </>
        default: return null
    }

}

const Head = () => {

    return <>
        <div className='row nomobile App-header level2'>
            <div className='header_in'>
                <ul>
                    <UserHeader />
                </ul>
            </div>
        </div>
    </>

}