import { ApiPost } from '../../m_common/client/api_common'

export function item_get_bycode(id, dispatch) { commonFx('item_get_bycode', { id }, dispatch) }
export function item_get_bygroup(group_id, dispatch) { commonFx('item_get_bygroup', { group_id }, dispatch) }
export function item_get_courses(dispatch) { commonFx('item_get_courses', {}, dispatch) }
export function item_get_more(ids, dispatch) { commonFx('item_get_more', { ids }, dispatch) }


export function item_temp_get(item_id, dispatch) { commonFx('item_temp_get', { item_id, fill: true }, dispatch) }
//export function item_group_get(group_id, dispatch) { commonFx('item_group_get', { group_id }, dispatch) }


export function solution_get(item_id, dispatch) { commonFx('item_solution_get', { item_id }, dispatch) }
export function solution_get_more(input, dispatch) { commonFx('item_solution_get_more', input, dispatch) }
export function solution_save(input, dispatch) { return callbackFx('item_solution_save', input, dispatch) }

export function solution_course_get(item_id, dispatch) { commonFx('item_solution_course_get', { item_id }, dispatch) }
export function solution_course_get_all(dispatch) { commonFx('item_solution_course_get_all', {}, dispatch) }
export function solution_course_save(input, dispatch) { commonFx('item_solution_course_save', input, dispatch) }

export function solution_user_get(dispatch) { commonFx('item_solution_user_get', {}, dispatch) }
//export function solution_user_del(dispatch) { dispatch({ type: 'CONTENT_RES', payload: { solution_user: null } }) }


export function votes_get(type, dispatch) { commonFx("item_votes_get", { type }, dispatch); }
export function votes_add(type, item_id, dispatch) { commonFx("item_votes_add", { type, item_id }, dispatch); }
export function votes_add2(type, item_id, currentnr, dispatch) {
    dispatch({ type: 'CONTENT_RES', payload: { votes: [{ item_id, type, votenr: currentnr + 1 }] } })
    ApiPost("votes_add", { type, item_id }, dispatch);
}

const commonFx = (todo, input, dispatch) => {
    callbackFx(todo, input, dispatch).then(x => { }).catch(e => { console.log(e) })
}

function callbackFx(todo, input, dispatch) {
    return new Promise((resolve, reject) => {
        ApiPost(todo, input, dispatch)
            .then(res => {
                if (res.ok) {
                    dispatch({ type: 'CONTENT_RES', payload: { ...res.data } })
                    resolve(res.data)
                } else {
                    reject(0)
                }
            })
            .catch(e => {
                reject(0)
            })
    })
}
