import React, { useState } from 'react'
import NewPassBox from '../../m_auth/client/auth.newpass.box'
import { useParams, Redirect, useRouteMatch, Switch, Route } from 'react-router-dom'

export default function () {
    const match = useRouteMatch();

    return <>
        <Switch>
            <Route path={match.path + "/:newpasstoken"}><NewPassword /></Route>
        </Switch>
    </>

}


function NewPassword() {
    const [status, setC] = useState(0)
    const { newpasstoken } = useParams();

    const onAction = (x) => {
        if (x.what === 'newpass' && x.val === 1) setC(1)
        if (x.what === 'newpass' && x.val === 0) setC(2)
    }

    return <>
        <div className='fullheight_flex noselect'>
            <div className='toload2'>
                {status === 1 ? <Redirect to={'/felhasznalo'} /> :
                    status === 2 ?
                        <div className='row text-center'>
                            <h4>A token nem létezik vagy már lejárt!</h4>
                        </div>
                        : <>
                            <div className='row text-center'>
                                <h4>Új jelszó megadása</h4>
                            </div>

                            <div className='row m10_0'>
                                <div className='mw500 '>
                                    <NewPassBox newpasstoken={newpasstoken} onAction={onAction} class='code_box' nohead={true} />
                                </div>
                            </div>
                        </>
                }
            </div>
        </div>
    </>
}