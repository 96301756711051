import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { combineReducers } from "redux";

import { auth_reducer } from '../m_auth/client/store.auth.reducer'
import { user_reducer } from '../m_user/client/store.user.reducer'
import { shop_reducer } from '../m_shop/client/store.shop.reducer'
import { messages_reducer } from '../m_common/client_messages/store.messages.reducer'

import { content_reducer } from '../m_item/client_redux/content.reducer'
import { common_reducer } from '../m_common/client_redux/common.reducer'


const middlewares = [
    thunk
];


export default createStore(
    combineReducers({
        common: common_reducer,
        auth: auth_reducer,
        user: user_reducer,
        shop: shop_reducer,
        content: content_reducer,
        messages: messages_reducer,
    }),
    applyMiddleware(...middlewares)
    //,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
