export let test_functions = {
	date1: (val) => {
		try {
			if (isNaN(new Date(val).getTime())) return false
			let [y, m, d] = val.toString().split('-')
			if (y * 1 < 1990) return false
			if (y * 1 > 2050) return false
			if (m * 1 < 1) return false
			if (m * 1 > 12) return false
			if (d * 1 < 1) return false
			if (d * 1 > 31) return false

		} catch (e) { return false }
		return true
	}
}

