import { iframe_init } from '../tech_iframe/a_iframe_init'
import { iframe_send } from '../tech_iframe/a_iframe_send'

export function TECH_ONCHANGE(code = '.') {
    delete this.cv[code]
    switch (this.technology) {
        case 'iframe': case 'iframe2':
            iframe_send.bind(this)('init')
            break;
        default: break;
    }
}

export function TECH_INIT() {
    switch (this.technology) {
        case 'iframe': case 'iframe2':
            iframe_init.bind(this)();
            break;
        default: break;
    }
}

