import React, { useState } from 'react'
import ChangePassBox from '../../m_auth/client/auth.changepass.box'
import AuthDataBox from '../../m_auth/client/rep.auth.data'

export default function () {

    return <>
        <div className='noselect'>
            <div className='toload2 row m50_0'>
                <AuthData />
                <div className='row mtop50'></div>
                <ChangePass />
            </div>
        </div>
    </>
}


function AuthData(props) {
    return <>
        <div className='row text-center'>
            <h4>Felhasználói alapadatok</h4>
        </div>
        <div className='row m10_0'>
            <div className='mw500 '>
                < AuthDataBox class='code_box' nohead={true} />
            </div>
        </div>
    </>
}

function ChangePass(props) {
    const [changed, setC] = useState(false)

    const onAction = (x) => {
        if (x.what === 'changepass' && x.val === 1) setC(true)
    }

    return changed
        ? <div>
            <div className='row text-center'>
                <h4>Jelszó megváltozott!</h4>
            </div>
        </div>
        : <>
            <div className='row text-center'>
                <h4>Jelszó megváltoztatása</h4>
            </div>

            <div className='row m10_0'>
                <div className='mw500 '>
                    <ChangePassBox onAction={onAction} class='code_boxX' nohead={true} />
                </div>
            </div>
        </>
}