import behave from '../meta/h_behaviour'
import { renderCodeMirror } from './render-cw'

const iterate = (cw, callback) => {
    return Object.keys(cw).map(x => { return { c: x, ...cw[x] } }).map((e, i) => { return callback(e, i) })
}

export function renderCodeMirrors() {
    iterate(this.cw, (e, i) => renderCodeMirror.bind(this)(e, {
        ...this.cwconfig,
        locked: (!behave.edit(e, this.props.solution) && !this.admin)
    }))
}
