import React, { useState } from "react"
import { SetText, SetDate, SetSelect, SetSelectVT, SetSwitch } from './io_helpers'
import { valGen } from "../client/h_validate"

export function CreateForm(props) {
    let _state = {}
    let _autofocued = false
    const [state, setState] = useState(null)
    let { data, meta, def, autofocus, exclude, exclude_disabled, disabled, disabled_all, css, button, button_text, cancel_button, cancel_button_text, rowtype } = props

    let fields = Object.keys(meta).filter(f => {
        return !(exclude || []).includes(f)
            && !meta[f].exclude
    })

    const valF = (f, _data, _meta) => {
        let _error = ""
        const errFx = (_err) => { _error = _err }
        let ok = valGen(_data, _meta.validations, _meta.errorhint, errFx)
        return { data: _data, error: _error, ok }
    }

    const submit = (what) => {
        if (props.onSubmit) props.onSubmit(what)
    }

    const onSubmit = (e) => {
        e.preventDefault();
        submit(state)
    }

    const onChange = (f, data) => {
        let valJ = valF(f, data, state[f].meta)
        let newstate = { ...state, [f]: { ...state[f], ...valJ } }
        if (!button) submit(newstate)
        setState(newstate)
    }

    if (!state) {
        fields.forEach(f => {
            let _data = data[f] !== undefined ? data[f] : (meta[f] ? meta[f].value : undefined)
            if (_data === undefined) _data = (def ? def[f] : undefined) || ""

            let _meta = {
                type: 'text',
                validations: [],
                errorhint: "",
                ...meta[f],
                disabled: meta[f].disabled || (disabled || []).includes(f) || disabled_all,
            }
            let _doautofocus = (!_autofocued && (autofocus || false)) && _meta.type === 'text' && !_meta.disabled
            _meta.autofucus = _doautofocus
            _autofocued = _autofocued || _doautofocus
            _state[f] = {
                meta: _meta,
                ...valF(f, _data, _meta)
            }
        })
        if (exclude_disabled && !disabled_all) fields = fields.filter(f => !_state[f].meta.disabled)
        setState(_state)
    }


    return !state ? null : <>
        <form className={'admin_setForm ' + css} onSubmit={e => onSubmit(e)}>
            <div className='row'>
                {
                    fields.map(f => <CreateFormElement
                        rowtype={rowtype}
                        key={f}
                        data={state[f].data}
                        meta={state[f].meta}
                        error={state[f].error}
                        css={css || ''}
                        name={f}
                        onChange={val => onChange(f, val)}
                    />)
                }
            </div>
            {button || cancel_button ?
                <div className='row text-center mtop30'>
                    {button ? <button type='submit' className='button bt_med bt_accent med medium m5'>{button_text}</button> : null}
                    {cancel_button ? <button type='button' className='button bt_med bt_grey med medium m5' onClick={props.onCancel}>{cancel_button_text}</button> : null}
                </div>
                : null
            }
        </form>
    </>
}

export function metaToVals(json) {
    let newvals = {}
    for (let i in json) newvals[i] = json[i].data
    return newvals
}

function CreateFormElement(props) {
    let { rowtype, data, error, meta, name, css, onChange } = props

    switch (rowtype) {

        default: return <CreateFormRow data={data} error={error} meta={meta} name={name} css={css} onChange={onChange} />
    }

}


function CreateFormRow(props) {
    let { data, error, meta, name, css, onChange } = props

    return <div className={'row inputrow name_' + name + (meta.valToClass ? ' val_' + data : '') + (meta.mt ? ' mtop20' : '')}>
        <div className='inputdata'>
            <FormRowData
                data={data}
                error={error}
                meta={meta}
                name={meta.text || name}
                css={css}
                onChange={onChange}
            />
        </div>
    </div>
}

function FormRowData(props) {
    let { data, error, meta, name, css, onChange } = props

    switch (meta.type) {
        case 'text': return <SetText
            value={data}
            error={error}
            class={css}
            name={name}
            onChange={onChange}
            maxlength={meta.maxlength}
            disabled={meta.disabled}
            autofocus={meta.autofucus}
        />

        case 'date': return <SetDate
            value={data}
            error={error}
            class={css}
            name={name}
            onChange={onChange}
            disabled={meta.disabled}
        />

        case 'switch': return <SetSwitch
            value={data}
            error={error}
            class={css}
            name={name}
            onChange={onChange}
            maxlength={meta.maxlength}
            disabled={meta.disabled}
        />

        case 'select': return <SetSelect
            value={data}
            class={css}
            name={name}
            onChange={onChange}
            options={meta.options}
            disabled={meta.disabled}
        />

        case 'select2': return <SetSelectVT
            value={data}
            class={css}
            name={name}
            onChange={onChange}
            options={meta.options}
            disabled={meta.disabled}
        />

        default: return null
    }


}