import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { content_solutions } from '../m_item/client_redux/content.selectors'
import { solution_get_more } from '../m_item/client_redux/content.actions'
import { privileges_selector } from '../m_auth/client/store.auth.selectors'

export default (props) => {
    const privileges = useSelector(privileges_selector)
    const isUser = privileges ? !!privileges.loggedin : false

    return !isUser ? null : <IfUser {...props} />
}

const IfUser = (props) => {
    const { item_ids } = props
    const solutions = useSelector(content_solutions)
    return !solutions ? null : <HasSolutions item_ids={item_ids} solutions={solutions} />
}


const HasSolutions = (props) => {
    const { item_ids, solutions } = props
    let missing = []
    item_ids.forEach(id => { if (!solutions[id]) missing.push(id) })

    const dispatch = useDispatch()
    if (missing.length) solution_get_more({ item_ids: missing }, dispatch)
    return null
}