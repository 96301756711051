import React from "react"
import { Link } from 'react-router-dom'
import { isloggedin, auth_selector } from './store.auth.selectors'
import { useSelector } from 'react-redux'
import { LogoutFrame } from "./w_logout"

export function UserIcon(props) {
    const loggedin = useSelector(isloggedin)
    const authdata = useSelector(auth_selector)

    return loggedin
        ? <>
            <Link to='/felhasznalo'>
                <li>
                    <div className='op7x hoverop1x hcl_accent'>
                        <span className='fas fa-user'></span>
                        <span className='mleft5'>{authdata.email.split("@")[0].split(".")[0].toUpperCase()}</span>
                    </div>
                </li>
            </Link>

            {props.children}

            <li>
                <LogoutFrame>
                    <div className='op7x hoverop1x hcl_accent'>
                        <span className='fas fa-sign-out-alt'></span>
                        <span className='mleft5'>Kijelentkezés</span>
                    </div>
                </LogoutFrame>
            </li>
        </>

        : <>
            <Link to='/felhasznalo'>
                <li>
                    <div className='op7x hoverop1x hcl_accent'>
                        <span className='fas fa-sign-in-alt'></span>
                        <span className='mleft5'>Bejelentkezés</span>
                    </div>
                </li>
            </Link>
        </>

}