const default_common_state = () => {
    return {
        menu_open: false,

        count: 1,
        pending: false,
        error: false
    }
}


export function common_reducer(state = default_common_state(), action) {
    let newstate = { ...state }
    const ch = (new_val_json) => { newstate = { ...newstate, ...new_val_json } }

    switch (action.type) {
        case 'LOGOUT': newstate = default_common_state(); break;
        case 'NEW_PAGE': ch({ menu_open: false }); break;

        case 'COMMON_MENU_TOGGLE': ch({ menu_open: !state.menu_open }); break;
        case 'COMMON_MENU_CLOSE': ch({ menu_open: false }); break;
        case 'COMMON_MENU_OPEN': ch({ menu_open: true }); break;


        default: break;
    }

    return newstate;
}

// function do_all(action, state) { return newstate }



