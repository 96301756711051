import React from 'react'
import NewPage from '../../m_common/client_jsx/new_page'
import { FacebookChecker } from '../../m_common/client/facebookCheck'


export default (props) => {
    return <>
        <NewPage title='Kapcsolat - Codeman.hu' />
        <div className='row fullheight page_light font-main noselect'>
            <div className='row site_course_group m50_0 toload2'>
                <div className='row text-center'>
                    <h4 className='ptitle'>Kapcsolat</h4>
                </div>

                <div className='row row desc m50_0'>
                    <div className='mw1000 justify'>
                        <Szoveg />
                    </div>
                </div>
            </div>
        </div>
    </>
}


function Szoveg() {
    FacebookChecker(".fb-page iframe")

    return <>

        <div className='desc'>
            <p>Ha üzenni szeretnél számunkra valamit, azt megteheted facebook messenger-en keresztül, vagy küldj egy üzenetet az <a href='mailto:info@codeman.hu' className='cl_accent'>info@codeman.hu</a> -ra.</p>
            <p>Ha követni szeretnéd a fejlesztéseket, illetve folyamatos tájékoztatást kapni az újdonságokról, akkor keresd fel és kövesd a
            <a className='cl_accent' href="https://www.facebook.com/codeman.hu" target="_blank" rel="noopener noreferrer"> Codeman.hu </a>
            facebook oldalát!</p>
        </div>

        <div className='mcenter' style={{ width: "500px" }}>
            <div className="fb-page" data-href="https://www.facebook.com/codeman.hu" data-tabs="" data-width="500" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                <blockquote cite="https://www.facebook.com/codeman.hu" className="fb-xfbml-parse-ignore">
                    <a href="https://www.facebook.com/codeman.hu">
                        Codeman.hu - interaktív programozó kurzusok
                        </a>
                </blockquote>
            </div>
        </div>



        <div className='desc'>
            Az általunk működtetett facebook csoportban az informálódáson túl te is felteheted a kérdéseidet. Problémákat, ötleteket oszthatsz meg velünk és másokkal, illetve segítséget kérhetsz tapasztaltabb fejlesztőktől.
        </div>
        <div className='mcenter'>
            <div className='fb-group' data-href="https://www.facebook.com/groups/867532137338587" data-width="500" data-show-social-context="true" data-show-metadata="false"></div>
        </div>



    </>
}


