export const replace0 = (str, s0, s1) => {
    if (startWith(s1, s0)) return str
    let st1 = 'x' + str
    let st2 = '' + str

    while (st2 !== st1) {
        st1 = '' + st2
        st2 = st1.replace(s0, s1)
    }
    return st2
}

export const startWith = (str, s0) => {
    return str.substr(0, s0.length) === s0
}

export function toStringFx(_text) {
    let text = ""
    switch (typeof _text) {
        case "string": text = '' + _text + ''; break;
        case "undefined": text = "undefined"; break;
        case "function": text = _text.toString(); break;
        case "object":
            if (_text === null) text = "null"
            //else if (Array.isArray(_text)) text = JSON.stringify(_text)
            else if (Array.isArray(_text)) text = "[" + _text.map(t => toStringFx(t)).join(",") + "]"

            else if (Set.prototype.isPrototypeOf(_text)) text = "{" + [..._text].join(", ") + "}"
            else if (_text.constructor === Set) text = "{" + [..._text].join(", ") + "}"
            else if (_text.toString() === "[object Set]") text = "{" + [..._text].join(", ") + "}"
            else text = JSON.stringify(_text)
            break;
        default: text = _text.toString();
    }
    return text
}

export const htmlEntities = (str) => {
    return String(str)
        .replace(/&/g, '&amp;')
        .replace(/&amp;#/g, '&#')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        ;
}

export const toUrl=(t)=>{
    return t
        .replace(/\s/g,'_')
        .toLowerCase()
        .replace(/á/g,'a')
        .replace(/í/g,'i')
        .replace(/é/g,'e')
        .replace(/ű/g,'u')
        .replace(/ü/g,'u')
        .replace(/ú/g,'u')
        .replace(/ó/g,'o')
        .replace(/ö/g,'o')
        .replace(/ő/g,'o')
        
}

export const countText = (txt, str) => { return (txt.match(new RegExp(str, "g")) || []).length }