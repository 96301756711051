import Place from '../../m_common/client/cookie'

var tokenMEM = {}

export default {
    get: (name = 'jwt') => {
        let getCookie = Place.get(name, null)
        if (!getCookie) return tokenMEM[name]
        else return getCookie
    },

    set: (name = 'jwt', value, days = 365) => {
        let setCookie = Place.set(name, value, days)
        tokenMEM[name] = value
        return setCookie
    },

    del: (name = 'jwt') => {
        return Place.del(name)
    }
}