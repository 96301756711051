import React from 'react'
import Courses from "../../m_item/client_course_list/a_course_list"
import NewPage from '../../m_common/client_jsx/new_page'
import { site_messages } from '../../config/client.config'

export default (props) => {
    return <>
        <NewPage title="Kurzusok - Codeman.hu" />

        <div className='row fullheight page_light font-main'>
            <div className='mw1000_500_250 site_course_group toload2'>
                <div className='row m50_0'>

                    <div className='text-center'>
                        <h4 className='ptitle'>Kurzusok</h4>
                    </div>
                    <div className='row desc text-center op6'>
                        <div className="mw800">
                            <p className=''>
                                A kurzusokban az alapvető tematika mellett kódrészletek segítik a mélyebb megértést, valamint minden egyes leckéhez tartozik egy kódolási feladat, mely a tanultak begyakorlását segíti.
                            </p>
                            <p className=''>
                                Egyetlen előfizetés áráért minden kurzust elérhetsz.
                            </p>
                        </div>
                    </div>

                    <CoursesMessages/>

                    <div className='row m50_0'>
                        <Courses />
                    </div>

                </div>
            </div>
        </div>
    </>
}

const CoursesMessages = () => {
    const { courses } = site_messages

    return !courses ? null : <>
        <div className='row mtop20'>
            <p className='text-center' style={courses.style}>
                {courses.text}
            </p>
        </div>
    </>
}