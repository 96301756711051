import React, { useState } from 'react'
import VerifyBox from '../../m_auth/client/auth.verify.box'
import { useParams, Redirect, useRouteMatch, Switch, Route } from 'react-router-dom'

export default function () {
    const match = useRouteMatch();
    return <>
        <Switch>
            <Route path={match.path + "/:veriftoken"}><NewPassword /></Route>
        </Switch>
    </>

}

function NewPassword() {
    const [status, setC] = useState(0)
    const { veriftoken } = useParams();

    const onAction = (x) => {
        if (x.what === 'verif') setC(1)
    }

    return <>
        <div className='fullheight_flex noselect'>
            <div className='toload2'>
                {status === 1 ?
                    <Redirect to={'/felhasznalo'} />
                    :
                    <>
                        <div className='row text-center'>
                            <h4>Regisztráció megerősítése</h4>
                        </div>
                        <div className='row text-center mtop30'>
                            <i className='fas fa-spinner fa-spin fa-3x'></i>
                        </div>
                        <VerifyBox veriftoken={veriftoken} onAction={onAction} />
                    </>
                }
            </div>
        </div>
    </>
}