import React from 'react'
import { CodeWindows } from "./frame-cws";
import { NewCodeWindow } from './frame-new'

export function Iframe1Window() {
    return <>
        <div className={"row toload2x codeboxes"}>
            {CodeWindows.bind(this)()}
            {NewCodeWindow.bind(this)()}
        </div>
    </>
}