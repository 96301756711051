import React from 'react'
import EFrameContent from '../item.task/ph_content'

export default function TaskArticle(props) {
    const { item, bg } = props
    return <>
        <div className={'row'}>
            <div className='editor_content_in'>

                <div className='row'>
                    {!item ? null :
                        <EFrameContent title={'xx'} item={item} field={'article'} nohead={true} bg={bg} />
                    }
                </div>

            </div>
        </div>
    </>
}

