import { ApiPost } from '../../m_common/client/api_common'

export function user_address_save(address, dispatch) {
    return commonFx2('user_address_save', { address }, dispatch, 'USER_ADDRESS', 'USER_ERROR', 'USER_SENT')
}

export function user_address_get() {
    return commonFx('user_address_get', {}, 'USER_ADDRESS', 'USER_ERRORxxx', 'USER_SENTxxx')
}

export function user_newsletter_save(email) {
    return commonFx('user_newsletter_save', { email }, 'USER_xxx', 'USER_ERRORxxx', 'USER_SENTxxx')
}

const commonFx = (todo, input, type_ok = '', type_err = '', type_sent = '') => {
    return async dispatch => {
        dispatch({ type: type_sent });
        ApiPost(todo, input, dispatch)
            .then(x => {
                dispatch({ type: type_ok, payload: { ...x.data } })
            })
            .catch(e => { dispatch({ type: type_err, e }) })
    }
}

const commonFx2 = (todo, input, dispatch, type_ok = '', type_err = '', type_sent = '') => {
    return new Promise((resolve, reject) => {
        dispatch({ type: type_sent });
        ApiPost(todo, input, dispatch)
            .then(x => {
                dispatch({ type: type_ok, payload: x.data })
                if (x.ok) resolve(x)
                else reject(false)
            })
            .catch(e => {
                dispatch({ type: type_err, e })
                reject(false)
            })
    })
}