import React from 'react'
import parse from 'html-react-parser';

export default function ContentRep(props) {
    renderCodes(props.content, props.refresh)
    return <>
        <div className='row content_rep p5'>
            {ShowContents(props.content, props.refresh)}
        </div>
    </>
}

const ShowContents = (content, refresh) => {
    return content.map(c => { return ShowContent(c, refresh) })
}

const ShowContent = (content, refresh) => {
    return <div key={content.c} className={'row m5_0x content_item itemtype_' + content.type}>
        <div id={idprefix + refresh + content.c}>
            {
                content.type === 'text' ? ShowText(content) :
                    content.type === 'dom' ? ShowText(content) :
                        <span className='cl_accent bold op7x p0_5'>{content.type}</span>
            }
        </div>
    </div>
}

const ShowText = (content) => {
    return parse(content.v)
}


const renderCodes = (content, refresh) => {
    setTimeout(() => {
        content.forEach(c => { if (c.type !== 'text' && c.type !== 'dom') renderCode(c, refresh) })
    }, 300)

    setTimeout(() => {
        document.querySelectorAll('pre code').forEach((block) => {
            try {
                window.hljs.highlightBlock(block);
            } catch (e) { }
        });

        document.querySelectorAll('.content_rep a').forEach((link) => {
            try { link.setAttribute('target', "_blank"); } catch (e) { }
        });

        document.querySelectorAll('.content_rep form').forEach((form) => {
            try { form.onsubmit=(event)=>{
                event.preventDefault(); 
                alert('Az adatok elküldésre kerültek!'); 
                return false; 
            } } catch (e) { }
        });

    }, 300)
}


const renderCode = (content, refresh) => {
    let el = document.getElementById('' + idprefix + refresh + content.c)
    if (!el) return
    if ([...el.classList].includes('rendered')) return
    let cnf = confing[content.type]
    el.classList.add('rendered')
    window.CodeMirror(el, { ...cnf, value: content.v || '' })
}

//const isT = (c) => { return c.type === 'text' }

const idprefix = 'aaa'

const confing = {
    text: {},
    html: { mode: 'xml', theme: "ayu-mirage", lineWrapping: true, lineNumbers: true, readOnly: 'nocursor' },
    css: { mode: 'text/css', theme: "ayu-mirage", lineWrapping: true, lineNumbers: true, readOnly: 'nocursor' },
    js: { mode: 'javascript', theme: "ayu-mirage", lineWrapping: true, lineNumbers: true, readOnly: 'nocursor' },
}