import React, { useState } from "react"
import PriceTable from './w_pricetable'
import { Redirect } from "react-router-dom"

export default function () {
    const [redirect, setRed] = useState("")

    return redirect ?
        <>
            <Redirect to={redirect}></Redirect>
        </>
        :
        <>
            <div className='row mtop30'>
                <PriceTable
                    onClickItem={(p) => { setRed("/elofizetes/" + p) }}
                    buttontext="Előfizetek"
                />
            </div>

        </>
}