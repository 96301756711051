import React from 'react'
import { Link } from 'react-router-dom'


let imgstyle = {
    minWidth: "100%",
    maxWidth: "120%",
    minHeight: "100%",
    maxHeight: "120%",
    opacity: 0.5
}

let imgsrc = '/public/background/bg004.jpg'

let bgcolor = "rgb(220 134 16)"; // "#2baad9"






export default function RowCourses(props) {
    return <>
        <div className=''>
            <div className='row bg_white'>

                <Graphics />

                <div className='col2_m1 left'>
                    <Text />
                </div>

            </div>
        </div>
    </>
}


function Graphics() {
    return <>
        <div className='col2_m1 absolute tr0 h100 nomobilex nooverflow' style={{ backgroundColor: bgcolor }}>
            <img src={imgsrc} alt='' style={imgstyle} />
        </div>
    </>
}

function Text() {
    return <>
        <div className='pagerow p30_0' style={{backgroundColor:"rgba(255,255,255,0.8)"}}>
            <div>

                <div className='row text-center p10 '>
                    <h3>Kurzusok</h3>
                </div>

                <div className='row text-center' style={{ fontSize: '16px', lineHeight: '1.5em', padding: "10px 30px" }}>
                    <p className=''>
                        Induló kurzusaink a webfejlesztés témakörében készültek. A kurzusokban az alapvető bevezető tematika mellett kódrészletek segítik a mélyebb megértést, valamint minden egyes leckéhez tartozik egy kódolási feladat, mely a tanultak begyakorlását segíti.
                </p>
                    <p className=''>
                        Egyetlen előfizetés áráért minden kurzust elérhetsz.
                </p>
                </div>
                <div className='row text-center p10 '>
                    <Link to='/kurzusok'>
                        <button className='button bt_yellow med'>Tovább a kurzusokra</button>
                    </Link>
                </div>

            </div>
        </div>
    </>
}