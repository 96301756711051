import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { valEmail } from '../../m_common/client/h_validate'
import { forget_action } from './pure.auth.actions'

export default () => {
    const [email, setemail] = useState('')
    const [sentok, setSO] = useState(false)
    const [err1, seterr1] = useState('')


    const dispatch = useDispatch()

    const validateAll = (email) => {
        let emailOk = valEmail.bind(this)(email, seterr1)
        return emailOk
    }
    let pending = false;
    const submit = (e, email) => {
        e.preventDefault();
        if (!pending) if (validateAll(email)) {
            let input = { email: email.toLowerCase() }
            forget_action(input, dispatch)
                .then(x => {
                    pending = false;
                    setSO(true)
                })
                .catch(e => { 
                    if (e==='err_nouser') seterr1("* nincs ilyen felhasználó")
                    pending = false; 
                })
        }
    }


    return sentok ?
        <h4 style={{ margin: "70px 0px" }}>Az email elküldésre került!</h4>
        :
        <>
            <div className='mw500'>
                <div className='row accentbox'>
                    <div className='row'>
                        <h4>Elfelejtett jelszó</h4>
                    </div>
                    <div className='row mtop10'>
                        <form onSubmit={e => submit(e, email)}>
                            <div className='row mtop10'>
                                <div className='inputrow mw200'>
                                    <input className='bt_trans_bo_white mw200 text-center text-ls1' type='email' placeholder='- email -' onChange={e => setemail(e.target.value)} autoFocus></input>
                                    <div className='inputerror'>{err1}</div>
                                </div>
                            </div>
                            <div className='row mtop10'>
                                <div className='inputrow mw200'>
                                    <button className='button big bt_trans_bo_white'>Elküld</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
}

