import React from 'react'
import ContentRep from '../../../m_common/client_text/content.rep'

export default function (props) {
    const { bg, nohead, title, item, field } = props
    return <>
        <div className={'row codeboxes'}>
            <div className={'row codebox sany ' + (bg)}>
                <EFRame title={title} nohead={nohead || false}>
                    <ContentRep content={item.task[field]} refresh={field} />
                </EFRame>
            </div>
        </div>
    </>
}

function EFRame(props) {
    const { nohead, title } = props
    return <>
        <div className='row'>

            {nohead ? '' :
                <div className='row codebox-header'>
                    <span>{title}</span>
                </div>
            }

            <div className={'row p15 content_light'}>
                <div className='mw1000 leftx'>
                    {props.children}
                </div>
            </div>
        </div>
    </>
}