import React, { useState } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { content_item_bycode, content_item_temp } from '../client_redux/content.selectors'
import { item_get_bycode, item_temp_get, solution_course_save } from '../client_redux/content.actions'

import Loading from '../../m_common/client_jsx/loading_page'
import can_open from '../meta/can_open'
import { IfHasPriv } from '../../m_auth/client/w-haspriv'
import TaskSolve from '../client_task/item.solve'


export default (props) => {
    const { privileg } = props
    const priv = privileg === undefined ? "loggedin" : privileg
    return <IfHasPriv priv={priv} login={true}>
        <Innerpage />
    </IfHasPriv>
}


function Innerpage(props) {
    const { course_code, task_id } = useParams()
    const dispatch = useDispatch()
    const course_item = useSelector(store => content_item_bycode(store, course_code))

    if (!course_item) item_get_bycode(course_code, dispatch);

    return !course_item
        ? <Loading />
        : <Innerpage2
            course_item={course_item}
            task_id={task_id}
        />
}


function Innerpage2(props) {
    const { course_item, task_id } = props
    const [state, setState] = useState({ redirect: '', last_task: task_id })
    const dispatch = useDispatch()
    const setS = (json) => { setState({ ...state, ...json }) }
    const setR = (address) => { if (!state.redirect) setS({ redirect: address }) }
    if (task_id !== state.last_task) if (state.redirect) setState({ redirect: '', last_task: task_id })

    const course_temp = useSelector(store => content_item_temp(store, course_item?.id))

    const courses_page = '/kurzusok'
    const course_page = '/kurzus/' + course_item?.code
    const course_task_page = '/kurzus/' + course_item?.code + "/"

    let task = course_temp?.cache?.item[task_id]
    let course_items = course_temp?.cache?.items || []

    if (!can_open(course_item)) setR(courses_page)

    else if (!course_temp) item_temp_get(course_item?.id, dispatch)


    const onAction = (act) => {

        switch (act.type) {
            case "exit":
                setR(course_page)
                break;
            case "finish":
                if (act.data.solved)
                    solution_course_save({ item_id: course_item.id, item_ids: course_items }, dispatch)
                break;
            case "next":
                let { item_id } = act.data
                let next_id = null
                try { next_id = course_items[course_items.indexOf(item_id) + 1] } catch (e) { }
                if (next_id) setR(course_task_page + next_id)
                else setR(course_page)
                break;
            case "error":
                setR(course_page)
                break;
            default: break;
        }
    }


    return state.redirect
        ? <Redirect to={state.redirect} />
        : !course_temp
            ? null
            : <TaskSolve
                task_code={task?.code}
                parent_item={course_item}
                next_button={true}
                to_save={true}
                onAction={onAction}
            />

}