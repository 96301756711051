const default_content_state = () => {
    return {
        item: {},
        item_overview: {},
        item_temp: {},

        course_list: null,
        task_list: null,

        solution: {},
        solution_lists: {},

        solution_course: {},
        solution_course_list: null,
        solution_user: null,

        group_item: {},
        group: {},

        votes: {},

        pending: false,
        error: false
    }
}


export function content_reducer(state = default_content_state(), action) {
    switch (action.type) {
        case 'CONTENT_PENDING': return { ...state, pending: true, error: false };
        case 'CONTENT_ERR': return { ...state, peding: false, error: true };
        case 'CONTENT_RES': return do_all(action.payload, state)
        case 'LOGOUT': return default_content_state()
        default: return state;
    }
}

function do_all(payload, state) {

    const { item, item_overview, item_temp,
        f_courses, f_tasks, f_group_item,
        solution, solution_course, f_solution_course_all, solution_user,
        item_id, votes, votes_type
    } = payload
    let newstate = { ...state, pending: false, error: false }

    if (f_courses) newstate.course_list = newstate.course_list || []
    if (f_tasks) newstate.task_list = newstate.task_list || []

    if (item) item.forEach(ii => {
        newstate.item[ii.code] = ii
        newstate.item_overview[ii.code] = ii
        if (f_courses) newstate.course_list.push(ii.code)
        if (f_tasks) newstate.task_list.push(ii.code)
    })

    if (item_overview) item_overview.forEach(ii => {
        newstate.item_overview[ii.code] = ii
        if (f_courses) newstate.course_list.push(ii.code)
        if (f_tasks) newstate.task_list.push(ii.code)
    })

    if (item_temp) newstate.item_temp[item_temp.item_id] = { ...item_temp }

    if (f_group_item) {
        newstate.group_item[f_group_item] = newstate.group_item[f_group_item] || {};
        [...(item || []), ...(item_overview || [])].forEach(ii => {
            newstate.group_item[ii.group_id] = newstate.group_item[ii.group_id] || {}
            newstate.group_item[ii.group_id][ii.id] = { code: ii.code, item_id: ii.id, group_id: ii.group_id }
        })
    }

    if (f_courses) newstate.course_list = newstate.course_list.filter((v, i, a) => a.indexOf(v) === i)
    if (f_tasks) newstate.task_list = newstate.task_list.filter((v, i, a) => a.indexOf(v) === i)


    if (solution) {
        if (item_id) newstate.solution_lists[item_id] = { item_ids: [] }
        solution.forEach(sol => {
            newstate.solution[sol.item_id] = { ...sol }
            if (item_id) newstate.solution_lists[item_id].item_ids.push(sol.item_id)
        })
    }

    if (solution_course) {
        if (f_solution_course_all) newstate.solution_course_list = 1
        solution_course.forEach(sol => {
            newstate.solution_course[sol.item_id] = { ...sol }
        })
    }
    if (solution_user !== undefined) {
        newstate.solution_user = !solution_user ? null : solution_user
    }

    if (votes_type) newstate.votes = newstate.votes || {}
    if (votes) votes.forEach(v => {
        if (newstate.votes[v.type] === undefined) newstate.votes[v.type] = {}
        newstate.votes[v.type][v.item_id] = v.votenr
    })

    return newstate
}



