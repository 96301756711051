export async function PostData(url, json, auth = null) {
    return new Promise((resolve) => {
        var xhr = new XMLHttpRequest()
        xhr.addEventListener('load', () => { resolve(xhr.response) })
        xhr.open('POST', url)
        if (auth) xhr.setRequestHeader('Authorization', "Bearer " + auth) 
        xhr.send(JSON.stringify(json))
    })
}

export async function GetData(url, auth = null) {
    return new Promise((resolve) => {
        var xhr = new XMLHttpRequest()
        xhr.addEventListener('load', () => { resolve(xhr.response) })
        xhr.open('GET', url)
        if (auth) xhr.setRequestHeader('Authorization', "Bearer " + auth)
        xhr.send()
    })
}